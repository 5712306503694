import request from '../utils/request.js'
// import store from '../store/index.js'

export default {
  readNotice(data) { // 获取notice数组
    return request.get("/readNotice", { params: data })
  },
  sysNoticeInfo(data) { // 获取notice数组
    return request.get("/sysNoticeInfo", { params: data })
  },
  getNoticeList() { // 获取notice数组
    return request.get("/sysNoticeList?pageNum=0&pageSize=1000")
  },
  getCaptcha() { // 获取验证码
    return request.adornUrl("/captcha.jpg")
  },
  sendVerificationCode(data) { //获取手机验证码
    return request.post("/sendVerificationCode", data)
  },
  register(data) { //注册
    return request.post("/register", data)
  },
  login(data) { //登录
    return request.post("/login", data)
  },
  forgetPassword(data) { //修改密码
    return request.post("/forgetPassword", data)
  },
  getApplyStatus(data){ //获取申请状态
    return request.post("/getApplyStatus", data)
  },
  getProcessStatus(data){ //获取申请填写状态
    return request.get("/getProcessStatus", { params: data })
  },
  getInfoStatus(data){ //获取点击步骤
    return request.post("/getInfoStatus",  data)
  },
  saveTmpApply(data) { //暂存申请信息
    return request.post("/saveTmpApply", data)
  },
  getExistApply(data) { //获取暂存信息
    return request.post("/getExistApply", data)
  },
  saveTmpScholScore(data) { //暂存成绩信息
    return request.post("/saveTmpScholScore", data)
  },
  getExistSchoolScores(data) { //获取成绩信息
    return request.post("/getExistSchoolScores", data)
  },
  saveIntentionMajor(data) { //保存意向专业
    return request.post("/saveIntentionMajor", data)
  },
  commitApply(data) { //提交申请
    return request.post("/commitApply", data)
  },
  contentConfigList(data) { //获取配置信息
    return request.get("/contentConfigList", { params: data })
  },
  uploadStuImg(data) { //上传照片
    return request.post("/uploadStuImg", data)
  },
  articleList(data) { //公告
    return request.get("/articleList", { params: data })
  },
  articleInfo(data) { //公告详情
    return request.get("/articleInfo", { params: data })
  },
  addViewer(data) { //公告浏览
    return request.get("/addViewer", { params: data })
  },
  noticeList(data) { //通知
    return request.get("/noticeList", { params: data })
  },
  noticeInfo(data) { //通知详情
    return request.get("/noticeInfo", { params: data })
  },
  printCxWord(data) { //上传照片
    return request.get("/printCxWord", { params: data })
  },
  printApplyPdf(data) { // 打印申请表
    return request.get("/printApplyPdf", { params: data })
  },
  printExamWord(data) { // 打印准考证
    return request.get("/printExamWord", { params: data })
  },
  applyBack(data) { //申请退回
    return request.post("/applyBack", data)
  },
  uploadMaterials(data) { //上传材料
    return request.post("/uploadMaterials", data)
  },
  uploadReWards(data) { //上传证书
    return request.post("/uploadReWards", data)
  },
  delMaterials(data) { //删除文件
    return request.post("/delMaterials", data)
  },
  getMaterialStatus(data) { //获取上传状态
    return request.get("/getMaterialStatus", { params: data })
  },
  applyMaterialBack(data) { //材料申请退回
    return request.post("/applyMaterialBack", data)
  },
  getMaterialedList(data) { //获取上传材料
    return request.get("/getMaterialedList", { params: data })
  },
  getFirstAuditInfo(data) { //初审结果
    return request.post("/getFirstAuditInfo", data)
  },
  getPayInfo(data) { //缴费信息
    return request.post("/getPayInfo", data)
  },
  unifiedOrder(data) { //缴费通道
    return request.post("/unifiedOrder", data)
  },
  unifiedAppOrder(data) { //移动端缴费通道
    return request.post("/unifiedAppOrder", data)
  },
  wxpayOrderQuery(data) { //查询缴费状态
    return request.post("/wxpay/orderQuery", data)
  },
  getExamNoStatus(data) { //是否可以点击准考证
    return request.get("/getExamNoStatus", { params: data })
  },
  getExamCardInfo(data) { //准考证信息
    return request.post("/getExamCardDetailInfo", data)
  },
  getHandleScoreStatus(data) { //是否可以点击查询笔试成绩
    return request.get("/getHandleScoreStatus", { params: data })
  },
  getHandleScore(data) { //笔试成绩
    return request.post("/getHandleScore", data)
  },
  getMultipleScore(data) { //综合成绩
    return request.post("/getMultipleScore", data)
  },
  getProgressList(data) { //进程控制
    return request.get("/getProgressList", { params: data })
  },
  getProgressMap(data) { //进程控制所有
    return request.get("/getProgressMap", { params: data })
  }
}
