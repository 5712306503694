<template>
  <div style="height: calc(100% - 100px)">
    <div class="content">
      <!-- <div class="title">{{ title }}</div> -->
      <div v-html="content"></div>
    </div>
    <div class="btn-box">
<!--      <div class="print-tips">*请打印考生诚信承诺书，签字后拍照或扫描，在“材料上传”处上传</div>-->
<!--      <div class="save-btn" @click="printCxWord">打印</div>-->
      <a-checkbox class="check" :checked="check" @change="checkFun">我已仔细阅读并同意<a>《宁波诺丁汉大学“三位一体”综合评价招生考生诚信承诺书》</a></a-checkbox>
      <div class="next-btn" @click="nextStep">
        {{ applyStatus == 1 ? "下一步" : "下一页" }}
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
      imgUrl: '',
      applyStatus: 1,
      title: "",
      loading: false,
      check: false,
    };
  },
  created() {
    this.articleList();
    this.getApplyStatus();
  },
  methods: {
    articleList() {
      this.loading = true;
      this.$api
        .articleList({
          categoryName: "考生承诺书",
          pageNum: 0,
          pageSize: 10,
        })
        .then((res) => {
          this.loading = false;
          // console.log(res);
          if (res.data.code == 0) {
            this.content = res.data.page.list[0].content;
            this.imgUrl = res.data.page.list[0].imgUrl;
            this.title = res.data.page.list[0].title;
            // this.name = res.data.exixtApply.realName;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    printCxWord() {
      window.open(this.imgUrl, "_blank");
    },
    checkFun() {
      this.check = !this.check
    },
    nextStep() {
      if (!this.check) {
        this.$message.warning("请阅读诚信承诺书并点击勾选框");
        return;
      }
      this.$router.push("/aplyform");
    },
    getApplyStatus() {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.applyStatus = res.data.applyStatus;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.content {
  margin-top: 50px;
  border: 1px #ccc solid;
  padding: 30px;
  max-height: calc(100% - 90px);
  overflow-y: scroll;
  .title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }
  div {
    font-size: 16px;
    line-height: 26px;
    // text-indent: 2em;
    margin-bottom: 10px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.btn-box {
  .print-tips{
    color: #999;
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 14px;
  }
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.check {
  line-height: 40px;
  a{
    color: #333;
    //text-decoration: underline;
    //&:hover {
    //  font-weight: bold;
    //}
  }
}
</style>