<template>
  <div class="content-div">
    <div class="content" v-if="status == 2">
      <i class="iconfont icon-weixinzhifu2"></i>
      <!-- <div class="qr-code"></div> -->
      <vue-qr :text="url" :size="200"></vue-qr>
      <div class="text">
        <div v-html="content"></div>
      </div>
    </div>
    <div class="content-succ" v-if="status == 1">
      <div class="box">
        <div v-html="content"></div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  data() {
    return {
      status:0,
      content:'',
      url:'Generating payment QR code, please scan later',
      timer:"",
      payValue:"",
      loading: false
    };
  },
  created(){
    this.getPayInfo();
    this.getPayMoneyValue();
    // this.unifiedOrder();
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods: {
    getPayInfo(){
      this.loading = true;
      this.$api
          .getPayInfo()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              if(res.data.payStatus == 2){
                this.status = 2;
                this.getContentConfigList('未缴费');
                this.unifiedOrder();
              }
              if(res.data.payStatus == 1){
                this.status = 1;
                this.getContentConfigList('已缴费');
              }
            }else{
              // this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getContentConfigList(name){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:name
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                this.content = res.data.configList[item].content;
                if(name == '未缴费'){
                  this.content = this.content.replace('##',this.payValue);
                }
                if(name == '已缴费'){
                  this.getTestTime();
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTestTime(){
        this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'准考证打印时间'
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(5,6) == '0'){
                  timeStr = res.data.configList[item].content.substring(6,8);
                }else{
                  timeStr = res.data.configList[item].content.substring(5,8);
                }
                if(res.data.configList[item].content.substring(8,9) == '0'){
                  timeStr += res.data.configList[item].content.substring(9);
                }else{
                  timeStr += res.data.configList[item].content.substring(8);
                }
                this.content = this.content.replace('{time1}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "综合测试时间",
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              var timeStr = '';
              if(res.data.configList[0].content.substring(5,6) == '0'){
                timeStr = res.data.configList[0].content.substring(6,8);
              }else{
                timeStr = res.data.configList[0].content.substring(5,8);
              }
              if(res.data.configList[0].content.substring(8,9) == '0'){
                timeStr += res.data.configList[0].content.substring(9,12);
              }else{
                timeStr += res.data.configList[0].content.substring(8,12);
              }
              if(res.data.configList[0].content.substring(12,13) == '0'){
                timeStr += res.data.configList[0].content.substring(13);
              }else{
                timeStr += res.data.configList[0].content.substring(12);
              }
              this.content = this.content.replace('{time2}',timeStr);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getPayMoneyValue(){
      this.$api
          .contentConfigList({
            categoryName:'费用配置',
            name:'报名费用'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                this.payValue = res.data.configList[item].content;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    unifiedOrder(){
      this.$api
          .unifiedOrder()
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              this.url = res.data.codeUrl;
              this.timer = setInterval(() => {
                this.wxpayOrderQuery();
              }, 1000);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    wxpayOrderQuery(){
      this.$api
          .wxpayOrderQuery()
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 && res.data.payStatus == 1) {
              clearInterval(this.timer);
              this.status = 1;
              this.getContentConfigList('已缴费');
            } 
          })
          .catch((err) => {
            console.log(err);
          });
    },
    
  },
  components: {
    vueQr
  }
};
</script>

<style lang='scss' scoped>
.content-div{
  height: 100%;
  overflow-y:scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  font-size: 24px;
  text-align: center;
  line-height: 2;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    font-size: 60px;
    color: #04be02;
  }
  .qr-code {
    border: 1px black solid;
    width: 200px;
    height: 200px;
  }
  .text {
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
  }
}
.content-succ {
  font-size: 24px;
  // font-weight: bold;
  // color: #009bbd;
  text-align: center;
  line-height: 2;
  margin-top: 50px;
  .box {
    height: 260px;
    // border: 1px #009bbd solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px #009bbd solid;
    color: #009bbd;
    font-weight: bold;
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>