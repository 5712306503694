<template>
  <div>
    <div class="header">
      <img src="../../assets/whitetext.png" style="height: 100%" />
    </div>
    <div class="content" ref="getheight" :style="'height:calc(100vh - 170px - '+hh+'px)'">
      <div class="content-box" :style="'transform: scale('+scale+')'">
        <div class="title">
          <div>宁波诺丁汉大学三位一体报名系统</div>
          <div>Tri-Unity Scheme Test Applicant Portal</div>
        </div>
        <div style="text-align: center;font-size: 18px;">系统已关闭！请等待开启。。。。。。</div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div>招生热线：0574-88180182</div>
        <div>学校地址：浙江省宁波市泰康东路199号</div>
        <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
        </div>
        <div>招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
            >Gaokao@nottingham.edu.cn</a
          >
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../../assets/qr.png" style="height: 100%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="reset-pwd-layer" v-if="showResetLayer">
      <div class="reset-pwd-box">
        <div class="title">修改密码</div>
        <div class="input-box">
          <i class="iconfont icon-shenfenzheng"></i>
          <input type="text" placeholder="请输入身份证号" v-model="cardNo" />
        </div>
        <div class="input-box">
          <i class="iconfont icon-shoujihao"></i>
          <input type="text" placeholder="请输入手机号" v-model="mobile" />
        </div>
        <div class="input-box">
          <i class="iconfont icon-yanzhengma"></i>
          <input type="text" placeholder="请输入验证码" v-model="mobileCode" />
          <div
            class="mobile-code"
            @click="sendVerificationCode"
            :class="[sended ? 'ban' : '']"
          >
            {{ sended ? seconds + "s 后重新发送" : "发送验证码" }}
          </div>
        </div>
        <div class="input-box">
          <i class="iconfont icon-mima"></i>
          <input
            type="password"
            placeholder="请输入新密码"
            v-model="password"
          />
        </div>
        <div class="input-box">
          <i class="iconfont icon-mima"></i>
          <input
            type="password"
            placeholder="再次输入新密码"
            v-model="password2"
          />
        </div>
        <div class="btn-box">
          <div class="forget-password" @click="closeReset">
            <span>取消</span>
          </div>
          <div class="login" @click="resetPwd"><span>确定</span></div>
        </div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scale:1,
      codeImg: "",
      uuid: "",
      idNum: "",
      pwd: "",
      code: "",
      cardNo: "",
      mobile: "",
      password: "",
      password2: "",
      mobileCode: "",
      showResetLayer: false,
      sended: false,
      timer: "",
      seconds: 60,
      notice: [],
      articleLayer: false,
      articleInfo: {},
      registerStatus: 0,
      loginStatus: 0,
      height: `${document.documentElement.clientHeight}`,
      hh:0,
      loading: false
    };
  },
  watch: {
    seconds() {
      if (this.seconds <= 0) {
        clearInterval(this.timer);
        this.sended = false;
      }
    },
    mobile(n,o){
      if(n.length>11){
        this.mobile = o;
      }
    }
  },
  created() {
    setTimeout(() => {
      this.hh = this.$refs.getheight.offsetHeight - (this.height - 170)
      // console.log(this.hh);
      var ratio = (this.$refs.getheight.offsetHeight - this.hh) /430;
      // console.log(this.$refs.getheight.offsetHeight);
      this.scale = ratio < 1 ?ratio:1
    }, 50);
    // this.getCaptcha();
    // this.articleList();
    // this.getRegisterStatus();
    // this.getLoginStatus();
  },
  mounted() {
    window.onresize = () => {
      var ratio = this.$refs.getheight.offsetHeight/430;
      this.scale = ratio < 1 ?ratio:1
    }
  },
  methods: {
    getRegisterStatus() {
      this.$api
        .getProgressList({
          code: "code_1",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.registerStatus = 409;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLoginStatus() {
      this.$api
        .getProgressList({
          code: "code_2",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.loginStatus = 409;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    register() {
      if (this.registerStatus == 409) {
        this.$message.warning("注册功能已关闭，请等待开启！");
        return;
      }
      this.$router.push("mregister");
    },
    // 获取验证码
    getCaptcha() {
      this.uuid = this.getUUID();
      this.codeImg = this.$api.getCaptcha() + `?uuid=${this.uuid}`;
    },
    getUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        return (c === "x"
          ? (Math.random() * 16) | 0
          : "r&0x3" | "0x8"
        ).toString(16);
      });
    },
    login() {
      if (this.loginStatus == 409) {
        this.$message.warning("登录功能已关闭，请等待开启！");
        return;
      }
      if (!(this.idNum && this.pwd)) {
        this.$message.warning("输入不能为空");
        return;
      }
      this.$api
        .login({
          cardNo: this.idNum,
          password: this.pwd,
          uuid: this.uuid,
          captcha: this.code,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success(res.data.msg);
            this.$store.commit("setUser", res.data.token);
            this.$store.commit("setName", this.idNum);
            this.$router.push("mindex");
          } else {
            this.getCaptcha();
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendVerificationCode() {
      if (this.sended) {
        return;
      }
      if (this.mobile) {
        if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        this.sended = true;
        this.seconds = 60;
        this.timer = setInterval(() => {
          this.seconds--;
        }, 1000);
        this.$api
          .sendVerificationCode({
            mobile: this.mobile,
            type:2
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message.success("短信验证码已发送，请注意查收");
            } else {
              clearInterval(this.timer);
              this.sended = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("手机号不能为空");
      }
    },
    resetPwd() {
      if (this.mobile && this.mobileCode && this.password && this.cardNo) {
        // 身份证验证
        if (
          !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            this.cardNo
          )
        ) {
          this.$message.warning("请输入正确身份证号");
          return;
        } else if (this.cardNo.indexOf("x") > -1) {
          this.$message.warning("请输入大写的X字母");
          return;
        }
        // 手机验证
        if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        // 密码验证
        if (this.password.length < 8) {
          this.$message.warning("密码至少含8个字符");
          return;
        }
        if (
          !/^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{8,}$/.test(this.password)
        ) {
          this.$message.warning("密码必须含英文字符和数字");
          return;
        }
        if (this.password != this.password2) {
          this.$message.warning("两次密码输入不一致");
          return;
        }
        this.loading = true;
        this.$api
          .forgetPassword({
            cardNo: this.cardNo,
            code: this.mobileCode,
            mobile: this.mobile,
            password: this.password,
          })
          .then((res) => {
            // console.log(res);
            this.loading = false;
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              location.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("输入不能为空");
      }
    },
    closeReset() {
      this.showResetLayer = false;
    },
    openReset() {
      this.showResetLayer = true;
    },
    articleList() {
      this.$api
        .articleList({
          categoryName: "公告栏",
          pageNum: "0",
          pageSize: "5",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.notice = res.data.page.list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDetail(id) {
      this.articleLayer = true;
      this.$api
        .articleInfo({
          id: id,
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.articleInfo = res.data.articleInfo;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api
        .addViewer({
          id: id,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeDetail() {
      this.articleLayer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 70px;
  background-color: #18194f;
  padding-bottom: 10px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 170px);
  .content-box {
    width: 90vw;
    .title{
      text-align: center;
      color: #18194f;
      margin-bottom: 20px;
      div:nth-child(1){
        font-size: 20px;
        font-weight: bold;
      }
      div:nth-child(2){
        font-size: 15px;
      }
    }
    .login-box {
      width: 100%;
      border: 1px #009bbd solid;
      .title {
        text-align: left;
        font-weight: bold;
        color: white;
        background-color: #009bbd;
        height: 45px;
        font-size: 16px;
        line-height: 45px;
        padding: 0 30px;
      }
      .input-box {
        border: 1px #009bbd solid;
        margin: 20px;
        height: 45px;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        position: relative;
        &:hover {
          border: 2px #009bbd solid;
          padding: 0 9px;
        }
        i {
          font-size: 22px;
          color: #999;
        }
        img {
          position: absolute;
          right: 0;
          height: 100%;
          width: 120px;
        }
        input {
          border: 0;
          height: 100%;
          width: 100%;
          margin-left: 5px;
          outline: none;
          &::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #bbb;
          }

          &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #bbb;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #bbb;
          }
        }
      }
      .btn-box {
        display: flex;
        margin: 20px;
        justify-content: space-between;
        div {
          height: 45px;
          border: 1px #009bbd solid;
          text-align: center;
          width: calc(50% - 10px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          transition: all 0.5s;
        }
        span:nth-child(1) {
          font-size: 14px;
          display: block;
          margin-bottom: -5px;
          font-weight: bold;
        }
        span:nth-child(2) {
          font-size: 11px;
        }
        .login {
          color: white;
          background-color: #009bbd;
          border: 2px #009bbd solid;
          &:hover {
            background-color: unset;
            color: #015597;
            border: 2px #015597 solid;
            font-weight: bold;
          }
        }
        .forget-password {
          border: 2px #009bbd solid;
          color: #009bbd;
          &:hover {
            background-color: #de1b5c;
            color: white;
            border: 2px #de1b5c solid;
          }
        }
      }
    }
    .register{
      text-align: right;
      margin-top: 5px;
      color: #18194f;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
.footer {
    height: 100px;
    background-color: #18194f;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    align-items: center;
    padding: 10px;
  .text-div {
    font-size: 10px;
    .school-name {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: white;
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      height: 70%;
    }
    .img-text {
      font-size: 10px;
      text-align: center;
      line-height: 12px;
      margin-top: 3px;
    }
  }
}
.reset-pwd-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  .reset-pwd-box {
    border-radius: 5px;
    width: 90%;
    background-color: white;
    position: relative;
    .title {
      font-size: 18px;
      margin: 20px;
      font-weight: bold;
      color: #18194f;
    }
    .input-box {
      border: 1px #009bbd solid;
      margin: 20px;
      height: 45px;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      &:hover {
        border: 2px #009bbd solid;
        padding: 0 9px;
      }
      i {
        font-size: 22px;
        color: #999;
      }
      input {
        border: 0;
        height: 100%;
        width: 100%;
        margin-left: 10px;
        outline: none;
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #bbb;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #bbb;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #bbb;
        }
      }
    }
    .mobile-code {
      color: white;
      background-color: #009bbd;
      border: 2px #009bbd solid;
      width: 100px;
      position: absolute;
      right: 20px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: unset;
        color: #015597;
        border: 2px #015597 solid;
        font-weight: bold;
      }
      &.ban {
        color: #999;
        background-color: #ddd;
        border: 0;
        border: 1px #999 solid;
        cursor: not-allowed;
      }
    }
    .btn-box {
      display: flex;
      margin: 20px;
      justify-content: space-between;
      div {
        height: 45px;
        border: 1px #009bbd solid;
        text-align: center;
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        transition: all 0.5s;
      }
      span:nth-child(1) {
        font-size: 16px;
        display: block;
      }
      .login {
        color: white;
        background-color: #009bbd;
        border: 2px #009bbd solid;
        &:hover {
          background-color: unset;
          color: #015597;
          border: 2px #015597 solid;
          font-weight: bold;
        }
      }
      .forget-password {
        border: 2px #009bbd solid;
        font-weight: bold;
        color: #009bbd;
        &:hover {
          background-color: #de1b5c;
          color: white;
          border: 2px #de1b5c solid;
        }
      }
    }
  }
} 
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
