<template>
  <div class="content-div">
    <div class="content">
      <div class="left-box">
        <div class="title">
          意向专业选择<span v-if="majorTip">请至少选择一个意向专业</span>
<!--          <a-tooltip placement="right">-->
<!--            <template slot="title">-->
<!--              <span>-->
<!--                仅为意向专业。至少选择一个。最终专业以高考志愿填报时的为准。-->
<!--              </span>-->
<!--            </template>-->
<!--            <i class="iconfont icon-wenhao" style="float: right"></i>-->
<!--          </a-tooltip>-->
        </div>
        <div class="notes">仅为意向专业。至少选择一个。最终专业以高考志愿填报时的为准。</div>
        <div class="item">
          <a-select placeholder="" v-model="intentionMajor1" @change="setMajor1" :disabled="applyStatus != 1">
            <a-select-option :value="item.content" v-for="item in major" :key="item.id"> {{item.content}} </a-select-option>
          </a-select>
        </div>
        <div class="item">
          <a-select placeholder="" v-model="intentionMajor2" @change="setMajor2" :disabled="applyStatus != 1">
            <a-select-option :value="item.content" v-for="item in major" :key="item.id"> {{item.content}} </a-select-option>
          </a-select>
        </div>
        <div class="item">
          <a-select placeholder="" v-model="intentionMajor3" @change="setMajor3" :disabled="applyStatus != 1">
            <a-select-option :value="item.content" v-for="item in major" :key="item.id"> {{item.content}} </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div v-if="applyStatus != 1" class="apply-done-tip">你已提交申请<br>当前页无法编辑仅供查看</div>
      <div class="save-btn" @click="goPrevStep" v-if="applyTime != 0 || applyStatus != 1 ">{{applyStatus == 1?'上一步':'上一页'}}</div>
      <div class="next-btn" @click="saveAndNext" v-if="applyTime != 0 || applyStatus != 1 ">{{ applyStatus == 1?"保存并继续":"下一页" }}</div>
      <div class="save-btn" v-if="applyTime == 0 && applyStatus == 1">报名已结束</div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applyTime:this.$store.state.applyTime,
      major:[],
      intentionMajor1:"",
      intentionMajor2:"",
      intentionMajor3:"",
      majorTip:false,
      applyStatus:1,
      infoStatus:0,
      loading:false
    };
  },
  created(){
    this.getContentConfigList();
    this.getExistApply();
  },
  methods: {
    setMajor1(value) {
      if(this.intentionMajor2 == value || this.intentionMajor3 == value){
        this.intentionMajor1 = '';
        this.$message.warning("同一专业不能多次选择！");
      }
    },
    setMajor2(value) {
      if(this.intentionMajor1 == value || this.intentionMajor3 == value){
        this.intentionMajor2 = '';
        this.$message.warning("同一专业不能多次选择！");
      }
    },
    setMajor3(value) {
      if(this.intentionMajor1 == value || this.intentionMajor2 == value){
        this.intentionMajor3 = '';
        this.$message.warning("同一专业不能多次选择！");
      }
    },
    goPrevStep(){
      this.$router.push("/highschoolscore");
    },
    saveData() {
      if(this.intentionMajor1 == '' &&  this.intentionMajor2 == '' && this.intentionMajor3 == ''){
        this.majorTip = true;
        return;
      }
      // console.log(this.intentionMajor1);
      // console.log(this.intentionMajor2);
      // console.log(this.intentionMajor3);
      this.loading = true;
      this.$api
          .saveIntentionMajor({
            intentionMajor1:this.intentionMajor1,
            intentionMajor2:this.intentionMajor2,
            intentionMajor3:this.intentionMajor3
          }
          )
          .then((res) => {
            this.loading = false;
            console.log(res);
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              this.$router.push("/infoconfirmation");
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    saveAndNext() {
      if(this.applyStatus == 1){
        this.saveData();
        if(this.infoStatus < 7){
          var params = {}
          params['infoStatus'] = 7
          this.infoStatus = 7
          this.$api
            .saveTmpApply(params)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }else{
        this.$router.push("/infoconfirmation");
      }
    },
    getContentConfigList(){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:'专业'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              for(let item in res.data.configList){
                this.major.push({"id":res.data.configList[item].id,"content":res.data.configList[item].content})
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getExistApply(){
      this.loading = true;
      this.$api
          .getExistApply()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.applyStatus = res.data.exixtApply.status;
              this.intentionMajor1 = res.data.exixtApply.intentionMajor1==null?'':res.data.exixtApply.intentionMajor1;
              this.intentionMajor2 = res.data.exixtApply.intentionMajor2==null?'':res.data.exixtApply.intentionMajor2;
              this.intentionMajor3 = res.data.exixtApply.intentionMajor3==null?'':res.data.exixtApply.intentionMajor3;
              this.infoStatus = res.data.exixtApply.infoStatus?res.data.exixtApply.infoStatus:0;
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            // _that.loading = false;
            console.log(err);
          });
    }
  },
};
</script>

<style lang='scss' scoped>
.content-div {
  height: calc(100%);
  overflow-y: scroll;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  margin-top: 100px;
  display: flex;
  .left-box {
    width: 100%;
    max-width: 1200px;
    .title {
      background-color: #e8ecee;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      padding-left: 18px;
      i {
        float: right;
        margin-right: -30px;
        font-size: 22px;
        color: #858585;
        font-weight: normal;
      }
      span{
        font-size: 14px;
        color: #ff5722;
        font-weight: normal;
        margin-left: 10px;
      }
    }
    .notes {
      margin-top: 25px;
      margin-bottom: -25px;
    }
    .item {
      display: flex;
      // min-height: 90px;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 50px;
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
/deep/ .ant-select {
  width: 100%;
  font-size: 16px;
  .ant-select-selection {
    box-shadow: none;
    border-radius: 0px;
    border-color: #ccc;
  }
}
/deep/ .ant-select-dropdown-menu-item {
  font-size: 16px;
}
.apply-done-tip{
  font-size: 14px;
  color: #999;
  margin-right: 20px;
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>