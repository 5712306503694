<template>
  <div>
    <div class="header">
      <img src="../../assets/whitetext.png" style="height: 100%" />
    </div>
    <div class="back-btn" @click="goHome">回到首页</div>
    <div class="content" :style="'height:'+(height-170)+'px'">
      <div class="content-unpay" v-if="status == 2">
        <div v-html="unpayContent"></div>
        <div class="pay-btn" @click="goPay">缴费按钮</div>
        <div style="font-size: 14px;">{{unpayDate}}</div>
      </div>
      <div class="content-succ" v-if="status == 1">
        <div v-html="content"></div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div>招生热线：0574-88180182</div>
        <div>学校地址：浙江省宁波市泰康东路199号</div>
        <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
        </div>
        <div>招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
            >Gaokao@nottingham.edu.cn</a
          >
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../../assets/qr.png" style="height: 100%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="pop-layer" v-if="showActionBox">
      <div class="action-box">
        <div>请确认微信支付是否已完成</div>
        <div @click="payed">已完成支付</div>
        <div @click="unpayed">支付遇到问题，重新支付</div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo:{
      meta:[
          { name: 'referrer',content:'origin-when-cross-origin' }
      ]
  },
  data() {
    return {
      status:0,
      unpayContent:'',
      unpayDate:'',
      content:'',
      timer:"",
      showActionBox:false,
      height: `${document.documentElement.clientHeight}`,
      loading: false
    };
  },
  created(){
    this.getContentConfigList('未缴费_手机端');
    this.status = 2
    // 如果不等于空 说明是微信支付回调
    if(this.getQueryString("isPayBack")) {
      this.showActionBox = true
    } else {
      this.payed()
    }
    // this.payed()
    // this.getPayInfo();
    // this.getContentConfigList('未缴费_手机端');
    // this.status = 2
    // this.getContentConfigList('已缴费');
    // this.status = 1
  },
  methods: {
    goHome(){
      this.$router.push("mindex");
    },
    getQueryString(name){
      var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if(r!=null)return  unescape(r[2]); return null;
    },
    getPayInfo(){
      this.$api
          .getPayInfo()
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              if(res.data.payStatus == 2){
                this.status = 2;
                this.getContentConfigList('未缴费_手机端');
                // this.unifiedOrder();
              }
              if(res.data.payStatus == 1){
                this.status = 1;
                this.getContentConfigList('已缴费');
              }
            }else{
              // this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getContentConfigList(name){
      this.loading = true;
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:name
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                if(name == '未缴费_手机端'){
                  this.unpayContent = res.data.configList[item].content.split('##')[1]
                  this.unpayDate = res.data.configList[item].content.split('##')[2]
                  this.getPayTime()
                }else{
                  this.content = res.data.configList[item].content;
                  if(name == '已缴费'){
                    this.getTestTime()
                  }
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getPayTime(){
      this.loading = true;
      this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'缴费时间'
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                  var timeStr = '';
                  if(res.data.configList[item].content.substring(5,6) == '0'){
                    timeStr = res.data.configList[item].content.substring(6,8);
                  }else{
                    timeStr = res.data.configList[item].content.substring(5,8);
                  }
                  if(res.data.configList[item].content.substring(8,9) == '0'){
                    timeStr += res.data.configList[item].content.substring(9,12);
                  }else{
                    timeStr += res.data.configList[item].content.substring(8,12);
                  }
                  if(res.data.configList[item].content.substring(17,18) == '0'){
                    timeStr += res.data.configList[item].content.substring(18,20);
                  }else{
                    timeStr += res.data.configList[item].content.substring(17,20);
                  }
                  if(res.data.configList[item].content.substring(20,21) == '0'){
                    timeStr += res.data.configList[item].content.substring(21);
                  }else{
                    timeStr += res.data.configList[item].content.substring(20);
                  }
                  this.unpayDate = this.unpayDate.replace('{time}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTestTime(){
        this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'准考证打印时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(5,6) == '0'){
                  timeStr = res.data.configList[item].content.substring(6,8);
                }else{
                  timeStr = res.data.configList[item].content.substring(5,8);
                }
                if(res.data.configList[item].content.substring(8,9) == '0'){
                  timeStr += res.data.configList[item].content.substring(9);
                }else{
                  timeStr += res.data.configList[item].content.substring(8);
                }
                this.content = this.content.replace('{time1}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "综合测试时间",
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              var timeStr = '';
              if(res.data.configList[0].content.substring(5,6) == '0'){
                timeStr = res.data.configList[0].content.substring(6,8);
              }else{
                timeStr = res.data.configList[0].content.substring(5,8);
              }
              if(res.data.configList[0].content.substring(8,9) == '0'){
                timeStr += res.data.configList[0].content.substring(9,12);
              }else{
                timeStr += res.data.configList[0].content.substring(8,12);
              }
              if(res.data.configList[0].content.substring(12,13) == '0'){
                timeStr += res.data.configList[0].content.substring(13);
              }else{
                timeStr += res.data.configList[0].content.substring(12);
              }
              this.content = this.content.replace('{time2}',timeStr);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    goPay(){
      this.$api
          .unifiedAppOrder()
          .then((res) => {
            if (res.data.code == 0) {
              window.location.href = res.data.webUrl
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    payed(){
      // 已完成支付 需要调用后台接口 校验是否成功
      this.$api
          .wxpayOrderQuery()
          .then((res) => {
            if (res.data.code == 0 && res.data.payStatus == 1) {
              this.showActionBox = false
              this.getContentConfigList('已缴费')
              this.status = 1
            } else {
              this.unpayed()
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    unpayed(){
      // 重新支付
      this.showActionBox = false
      this.getContentConfigList('未缴费_手机端');
      this.status = 2
    }
  }
};
</script>

<style lang='scss' scoped>
.header {
  height: 70px;
  background-color: #18194f;
  padding-bottom: 10px;
}
.back-btn{
  width: 85px;
  height: 35px;
  background-color: #009bbd;
  position: absolute;
  left: 0;
  line-height: 35px;
  color: white;
  text-align: center;
  z-index: 1;
  top: 85px;
}
.content {
  position: relative;
  height: calc(100vh - 170px);
  overflow-y: scroll;
  background-color: #fff;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  .content-unpay {
    font-size: 16px;
    text-align: center;
    line-height: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px #009bbd solid;
    padding: 10px 5px;
    height: 200px;
    width: 100%;
    .pay-btn{
      background-color: #009bbd;
      color: white;
      font-size: 14px;
      width: 120px;
      margin: 20px;
      height: 35px;
      line-height: 35px;
    }
  }
  .content-succ {
    font-size: 16px;
    text-align: center;
    line-height: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px #009bbd solid;
    padding: 20px 15px;
    height: 200px;
    width: 100%;
  }
}
.footer {
    height: 100px;
    background-color: #18194f;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    align-items: center;
    padding: 10px;
  .text-div {
    font-size: 10px;
    .school-name {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: white;
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      height: 70%;
    }
    .img-text {
      font-size: 10px;
      text-align: center;
      line-height: 12px;
      margin-top: 3px;
    }
  }
}
.pop-layer{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .action-box{
      width: 250px;
      background-color: white;
      border-radius: 3px;
      text-align: center;
      div:nth-child(1){
        height: 60px;
        line-height: 60px;
        border-bottom: 1px #ddd solid;
      }
      div:nth-child(2){
        height: 50px;
        line-height: 50px;
        border-bottom: 1px #ddd solid;
        color: #F44336;
        font-size: 15px;
      }
      div:nth-child(3){
        height: 50px;
        line-height: 50px;
        color: #999;
      }
    }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
