<template>
  <div class="content-div">
    <div class="content">
      <div class="left-box">
        <div class="input-item">
          <div class="label">所在高中</div>
          <div class="input-box" :class="[verification['highSchool'] ? 'warning' : '']">
            <a-select
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              :filter-option="filterOption"
              @focus="resetVerification('highSchool')"
              @blur="highSchoolBlur"
              @search="highSchoolSearch"
              v-model="highSchool"
              :disabled="applyStatus != 1"
            >
              <a-select-option
                v-for="item in highschoolList"
                :key="item"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
            <div class="verification-tips" v-if="verification['highSchool']">
              请输入所在高中
            </div>
          </div>
          <a-tooltip placement="right">
            <template slot="title">
              <span>
                可自行填写
              </span>
            </template>
            <i class="iconfont icon-wenhao"></i>
          </a-tooltip>
        </div>
        <div class="input-item">
          <div class="label">中学地址</div>
          <div class="input-box addr-input">
            <a-select
              :default-value="provinceData[0]"
              @change="handleProvinceChange"
              v-model="province"
              :disabled="applyStatus != 1"
            >
              <a-select-option v-for="province in provinceData" :key="province">
                {{ province }}
              </a-select-option>
            </a-select>
          </div>
          <div class="input-box addr-input">
            <a-select v-model="secondCity" @change="handleCityChange" :disabled="applyStatus != 1">
              <a-select-option v-for="city in cities" :key="city">
                {{ city }}
              </a-select-option>
            </a-select>
          </div>
          <div class="input-box addr-input">
            <a-select v-model="county" :disabled="applyStatus != 1">
              <a-select-option v-for="c in countyData[secondCity]" :key="c">
                {{ c }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="input-item">
          <div class="label"></div>
          <div class="input-box" :class="[verification['schoolAddress'] ? 'warning' : '']">
            <input type="text" v-model="schoolAddress" @click="resetVerification('schoolAddress')" :disabled="applyStatus != 1"/>
            <div class="verification-tips" v-if="verification['schoolAddress']">
              请输入详细地址
            </div>
          </div>
          <a-tooltip placement="right">
            <template slot="title">
              <span>
                从街道开始填写，例如：<br />
                首南街道泰康东路199号宁波诺丁汉大学行政楼1508
              </span>
            </template>
            <i class="iconfont icon-wenhao"></i>
          </a-tooltip>
        </div>
        <div class="input-item">
          <div class="label">中学邮编</div>
          <div class="input-box" :class="[verification['schoolPostCode'] ? 'warning' : '']">
            <input type="number" v-model="schoolPostCode" @click="resetVerification('schoolPostCode')" :disabled="applyStatus != 1"/>
            <div class="verification-tips" v-if="verification['schoolPostCode']">
              请输入中学邮编
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">中学联系人</div>
          <div class="input-box" :class="[verification['schoolContactName'] ? 'warning' : '']">
            <input type="text" v-model="schoolContactName" @click="resetVerification('schoolContactName')" :disabled="applyStatus != 1"/>
            <div class="verification-tips" v-if="verification['schoolContactName']">
              请输入中学联系人
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">中学电话</div>
          <div class="input-box" :class="[verification['schoolContactPhone'] ? 'warning' : '']">
            <input type="text" v-model="schoolContactPhone" @click="resetVerification('schoolContactPhone')" :disabled="applyStatus != 1"/>
            <div class="verification-tips" v-if="verification['schoolContactPhone']">
              请输入中学电话
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div v-if="applyStatus != 1" class="apply-done-tip">你已提交申请<br>当前页无法编辑仅供查看</div>
      <div class="save-btn" @click="goPrevStep" v-if="applyTime != 0 || applyStatus != 1">{{applyStatus == 1?'上一步':'上一页'}}</div>
      <div class="save-btn" @click="save" v-if="applyTime != 0 && applyStatus == 1">暂存</div>
      <div class="next-btn" @click="saveAndNext" v-if="applyTime != 0 || applyStatus != 1">{{applyStatus == 1?'下一步':'下一页'}}</div>
      <div class="save-btn" v-if="applyTime == 0 && applyStatus == 1">报名已结束</div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
// const provinceData = ["Zhejiang", "Jiangsu"];
// const cityData = {
//   Zhejiang: ["Hangzhou", "Ningbo", "Wenzhou"],
//   Jiangsu: ["Nanjing", "Suzhou", "Zhenjiang"],
// };
import { citys } from "../../utils/city.js";

export default {
  data() {
    return {
      applyTime:this.$store.state.applyTime,
      citys,
      provinceData: [],
      cityData: {},
      countyData: {},
      cities: [],
      province:"北京市",
      secondCity: "",
      county: "",
      verification: {
        highSchool : false,
        schoolAddress: false,
        schoolPostCode:false,
        schoolContactName:false,
        schoolContactPhone:false
      },
      highschoolList:[],
      highSchool:"",
      schoolAddress:"",
      schoolPostCode:"",
      schoolContactName:"",
      schoolContactPhone:"",
      applyStatus:1,
      infoStatus:0,
      loading: false
    };
  },
  watch:{
    schoolPostCode(n,o){
      if(n.length>6){
        this.schoolPostCode = o;
      }
    }
  },
  created() {
    this.getHighSchoolList();
    for (let i in citys) {
      this.provinceData.push(citys[i]["value"]);
      this.cityData[citys[i]["value"]] = [];
      for (let j in citys[i]["children"]) {
        this.cityData[citys[i]["value"]].push(citys[i]["children"][j]["value"]);
        this.countyData[citys[i]["children"][j]["value"]] = [];
        for (let k in citys[i]["children"][j]["children"]) {
          this.countyData[citys[i]["children"][j]["value"]].push(
            citys[i]["children"][j]["children"][k]["value"]
          );
        }
      }
    }
    this.cities = this.cityData[this.provinceData[0]];
    this.secondCity = this.cities[0];
    this.county = this.countyData[this.secondCity][0];
    // console.log(this.provinceData);
    // console.log(this.cityData);
    // console.log(this.countyData);
    this.getExistApply();
  },
  methods: {
    getHighSchoolList(){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:'高中学校'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              for(let item in res.data.configList){
                this.highschoolList.push(res.data.configList[item].content)
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    highSchoolBlur(value) {
      this.highSchool = value;
    },
    highSchoolSearch(value) {
      this.highSchool = value;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleProvinceChange(value) {
      this.province = value;
      this.cities = this.cityData[value];
      this.secondCity = this.cities[0];
      this.county = this.countyData[this.secondCity][0];
    },
    handleCityChange(value) {
      this.secondCity = value;
      this.county = this.countyData[value][0];
    },
    resetVerification(key) {
      // console.log(key);
      this.verification[key] = false;
    },
    goPrevStep(){
      this.$router.push("/parentsinfo");
    },
    saveData(verify = false) {
      let warning = false;
      let params = {};
      params['schoolProvince'] = this.province;
      params['schoolCity'] = this.secondCity;
      params['schoolArea'] = this.county;
      (this.highSchool == "" || this.highSchool == null)
        ? ((this.verification["highSchool"] = verify?true:false), (warning = true))
        : (params['highSchool'] = this.highSchool);
      (this.schoolAddress == "" || this.schoolAddress == null)
        ? ((this.verification["schoolAddress"] = verify?true:false), (warning = true))
        : (params['schoolAddress'] = this.schoolAddress);
      (this.schoolPostCode == "" || this.schoolPostCode == null)
        ? ((this.verification["schoolPostCode"] = verify?true:false), (warning = true))
        : (params['schoolPostCode'] = this.schoolPostCode);
      (this.schoolContactName == "" || this.schoolContactName == null)
        ? ((this.verification["schoolContactName"] = verify?true:false), (warning = true))
        : (params['schoolContactName'] = this.schoolContactName);
      (this.schoolContactPhone == "" || this.schoolContactPhone == null)
        ? ((this.verification["schoolContactPhone"] = verify?true:false), (warning = true))
        : (params['schoolContactPhone'] = this.schoolContactPhone);

      if(verify && this.infoStatus < 5){
        params['infoStatus'] = 5
        this.infoStatus = 5
      }
      if (verify && warning) {
        this.$message.warning("请填写完整");
        return;
      }
      // console.log(params);
      let _that = this;
      _that.loading = true;
      this.$api
          .saveTmpApply(params)
          .then((res) => {
            _that.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              if(verify == true){
                this.$router.push("/highschoolscore");
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            _that.loading = false;
            console.log(err);
          });
    },
    save(){
      this.saveData();
    },
    saveAndNext(){
      if(this.applyStatus == 1){
        this.saveData(true);
      }else{
        this.$router.push("/highschoolscore");
      }
    },
    getExistApply(){
      this.loading = true;
      this.$api
          .getExistApply()
          .then((res) => {
            // console.log(res);
            this.loading = false;
            if (res.data.code == 0) {
              this.applyStatus = res.data.exixtApply.status;
              this.highSchool = res.data.exixtApply.highSchool;
              this.schoolAddress = res.data.exixtApply.schoolAddress;
              this.schoolPostCode = res.data.exixtApply.schoolPostCode;
              this.schoolContactName = res.data.exixtApply.schoolContactName;
              this.schoolContactPhone = res.data.exixtApply.schoolContactPhone;
              res.data.exixtApply.schoolProvince == null?'':this.province = res.data.exixtApply.schoolProvince;
              res.data.exixtApply.schoolCity == null?'':this.secondCity = res.data.exixtApply.schoolCity;
              res.data.exixtApply.schoolArea == null?'':this.county = res.data.exixtApply.schoolArea;
              this.infoStatus = res.data.exixtApply.infoStatus?res.data.exixtApply.infoStatus:0;
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  },
};
</script>

<style lang='scss' scoped>
.content-div {
  height: calc(100%);
  overflow-y: scroll;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  margin-top: 100px;
  display: flex;
  .left-box {
    .input-item {
      display: flex;
      margin: 20px;
      height: 36px;
      font-size: 16px;
      align-items: center;
      position: relative;
      .label {
        font-size: 18px;
        width: 150px;
        text-align: right;
        margin-right: 10px;
      }
      .input-box {
        border: 1px #ccc solid;
        width: 500px;
        height: 100%;
        display: flex;
        align-items: center;
        &.addr-input {
          width: 160px;
          margin-right: 10px;
        }
        input {
          outline: none;
          border: 0;
          height: 100%;
          padding: 0 10px;
          width: 100%;
        }
        .select-placeholder {
          font-size: 16px;
          color: #999;
          padding-left: 10px;
          width: 100%;
          cursor: pointer;
        }
        &.warning {
          border-color: #ff5722;
        }
        .verification-tips {
          position: absolute;
          bottom: -20px;
          font-size: 14px;
          color: #ff5722;
          padding-left: 10px;
        }
      }
      .icon-wenhao {
        font-size: 22px;
        color: #858585;
        margin-left: 10px;
        cursor: help;
      }
      .select-box {
        position: absolute;
        width: calc(100% - 192px);
        left: 160px;
        text-align: center;
        background-color: #ffffff;
        top: 36px;
        z-index: 1;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        cursor: pointer;
        line-height: 36px;
        font-size: 16px;
        .option {
          &:hover {
            background-color: rgba(0, 155, 189, 0.2);
          }
        }
      }
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
/deep/ .ant-select {
  width: 100%;
  font-size: 16px;
  .ant-select-selection {
    border: 0;
    box-shadow: none;
  }
}
/deep/ .ant-select-dropdown-menu-item {
  font-size: 16px;
}
/deep/ .ant-calendar-picker {
  width: 100%;
  .ant-calendar-picker-input.ant-input {
    border: 0;
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply-done-tip{
  font-size: 14px;
  color: #999;
  margin-right: 20px;
}
</style>
