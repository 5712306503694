<template>
  <div class="content-div">
    <div class="content-succ">
      <div v-html="statusOptions">
      </div>
      <div class="box">
        <div class="header">
          <div>笔试成绩</div>
          <div>面试成绩</div>
          <div>综合测试成绩</div>
          <div style="border-right: 0">排名</div>
        </div>
        <div class="score">
          <div>{{handleScore}}</div>
          <div>{{faceScore}}</div>
          <div>{{allScore}}</div>
          <div style="border-right: 0">第{{rank}}名</div>
        </div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allInto:0,
      statusOptions:"",
      allScore:"",
      faceScore:"",
      handleScore:"",
      rank:"",
      loading:false
    };
  },
  created(){
    this.getMultipleScore();
  },
  methods: {
    getMultipleScore(){
      this.loading = true;
      this.$api
          .getMultipleScore()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.allInto = res.data.allInto;
              this.allScore = res.data.allScore+'分';
              this.faceScore = res.data.faceScore+'分';
              this.handleScore = res.data.handleScore+'分';
              this.rank = res.data.rank;
              this.statusOptions = res.data.statusOptions;
              this.isHideScore();
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    isHideScore() {
      this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "是否关闭显示笔试成绩",
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              if (res.data.configList[0].content === "T") {
                this.getHideScoreTips();
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getHideScoreTips() {
      this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "笔试成绩显示说明",
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.handleScore = res.data.configList[0].content;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }
};
</script>

<style lang='scss' scoped>
.content-div{
  height: 100%;
  overflow-y:scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content-succ {
  font-size: 24px;
  text-align: center;
  line-height: 2;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box {
    width: 1000px;
    margin-top: 20px;
    border: 1px #009bbd solid;
    .header {
      background-color: #009bbd;
      color: white;
      display: flex;
      text-align: center;
      div {
        width: 25%;
        border-right: 1px #fff solid;
      }
    }
    .score {
      height: 160px;
      line-height: 160px;
      display: flex;
      text-align: center;
      div {
        width: 25%;
        border-right: 1px #009bbd solid;
      }
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>