<template>
  <div class="body-box">
    <div class="header">
      <div class="step-name" @click="changeTab('/admissiondownload')">准考证</div>
      <div class="tab-box" v-if="tab === '/admissiondownload'">
        <div :class="[path === '/admissiondownload' ? 'active' : '']" @click="changeTab('/admissiondownload')">打印</div>
      </div>
    </div>
    <Admissiondownload v-if="path == '/admissiondownload'"></Admissiondownload>
  </div>
</template>

<script>
import Admissiondownload from './Download.vue';

export default {
  data() {
    return {
      path:"",
      tab:""
    };
  },
  created() {
    this.path = this.$route.path;
    switch(this.path){
      case '/admissiondownload':
        this.tab = '/admissiondownload';
        break;
    }
    console.log(this.path, this.tab);
  },
  methods: {
    changeTab(path) {
      if (this.path !== path) {
        this.path = path;
        this.$router.push(path);
      }
    }
  },
  components: {
    Admissiondownload,
  }
};
</script>

<style lang="scss" scoped>
.body-box {
  min-height: 100%;
  background-color: white;
  padding: 40px 30px;
  height: 100%;
  .header {
    display: flex;
    // border-bottom: 3px #ccc solid;
    border-bottom: 3px #c5cdd2 solid;
    font-weight: bold;
    width: 100%;
    height: 36px;
    position: relative;
    .step-name {
      font-size: 22px;
      padding: 0 10px;
      color: #18194f;
      cursor: pointer;
    }
    .tab-box {
      display: flex;
      font-size: 18px;
      height: 28px;
      margin-top: 5px;
      div {
        padding: 0 10px;
        // background-color: #e8e8e8;
        background-color: #e8ecee;
        margin: 0 10px;
        line-height: 30px;
        cursor: pointer;
        &.active {
          background-color: #de1b5c;
          color: white;
          cursor: default;
        }
      }
    }
    .tips-text {
      position: absolute;
      top: 50px;
      font-size: 16px;
      left: 10px;
    }
  }
  
  // .select-fade-enter-active {
  //   animation: fadein-down-in 0.3s;
  //   transform-origin: top;
  // }
  // .select-fade-leave-active {
  //   animation: fadein-down-in 0.3s reverse;
  //   transform-origin: top;
  // }
  // @keyframes fadein-down-in {
  //   0% {
  //     transform: scaleY(0.9);
  //     opacity: 0;
  //   }
  //   100% {
  //     transform: scaleY(1);
  //     opacity: 1;
  //   }
  // }
}
</style>
