<template>
  <div class="content-div">
    <div class="content" v-if="uploadDone == 'false'">
      <div class="left-box">
        <div class="tips">温馨提示：请确保您的佐证材料上传完整后，点击右下角<span>【确认上传完整】</span>按钮，完成报名。</div>
        <div class="title mt20">身份证上传</div>
        <div class="item">
          <div class="upload-div" @click="uploadZhengPic()" :class="[verification['zhengPic'] ? 'warning' : '']">
            {{zhengPic}}
            <i class="iconfont icon-tianjia2" style="float: right"></i>
            <i class="iconfont icon-jianshao" style="float: right" @click.stop="delZhengPic()" v-if="zhengPic !== '请上传身份证人像面照片'"></i>
            <div class="verification-tips" v-if="verification['zhengPic']">
              请上传身份证人像面照片
            </div>
            <span class="preview" v-if="zhengPic !== '请上传身份证人像面照片'" @click.stop="showMaterial(zhengUrl)">预览</span>
          </div>
          <input
            type="file"
            style="display: none"
            accept="image/*,application/pdf"
            ref="zhengPic"
            @change="getZhengPic"
          />
        </div>
        <div class="item">
          <div class="upload-div" @click="uploadFanPic()" :class="[verification['fanPic'] ? 'warning' : '']">
            {{fanPic}}
            <i class="iconfont icon-tianjia2" style="float: right"></i>
            <i class="iconfont icon-jianshao" style="float: right" @click.stop="delFanPic()" v-if="fanPic !== '请上传身份证国徽面照片'"></i>
            <div class="verification-tips" v-if="verification['fanPic']">
              请上传身份证国徽面照片
            </div>
            <span class="preview" v-if="fanPic !== '请上传身份证国徽面照片'" @click.stop="showMaterial(fanUrl)">预览</span>
          </div>
          <input
            type="file"
            style="display: none"
            accept="image/*,application/pdf"
            ref="fanPic"
            @change="getFanPic"
          />
        </div>
        <div class="title">申请表上传</div>
        <div class="item">
          <div class="upload-div" @click="uploadApplyFile()" :class="[verification['applyFile'] ? 'warning' : '']">
            {{applyFile}}
            <i class="iconfont icon-tianjia2" style="float: right"></i>
            <i class="iconfont icon-jianshao" style="float: right" @click.stop="delApplyFile()" v-if="applyFile !== ''"></i>
            <div class="verification-tips" v-if="verification['applyFile']">
              请上传申请表
            </div>
            <span class="preview" v-if="applyFile !== ''" @click.stop="showMaterial(applyUrl)">预览</span>
          </div>
          <input
            type="file"
            style="display: none"
            accept="image/*,application/pdf"
            ref="applyFile"
            @change="getApplyFile"
          />
        </div>
<!--        <div class="title">考生诚信承诺书上传</div>-->
<!--        <div class="item">-->
<!--          <div class="upload-div" @click="uploadChengxinFile()" :class="[verification['chengxinFile'] ? 'warning' : '']">-->
<!--            {{chengxinFile}}-->
<!--            <i class="iconfont icon-tianjia2" style="float: right"></i>-->
<!--            <i class="iconfont icon-jianshao" style="float: right" @click.stop="delChengxinFile()" v-if="chengxinFile !== ''"></i>-->
<!--            <div class="verification-tips" v-if="verification['chengxinFile']">-->
<!--              请上传考生诚信承诺书-->
<!--            </div>-->
<!--            <i class="iconfont icon-chakan"  v-if="chengxinFile !== ''" @click.stop="showMaterial(chengxinUrl)"></i>-->
<!--          </div>-->
<!--          <input-->
<!--            type="file"-->
<!--            style="display: none"-->
<!--            accept="image/*,application/pdf"-->
<!--            ref="chengxinFile"-->
<!--            @change="getChengxinFile"-->
<!--          />-->
<!--        </div>-->
        <div class="title" v-if="specialStatus == 1">专项条件获奖证书上传
          <a-tooltip placement="right" style="margin-right:12px">
            <template slot="title">
              <span>
                如无此专项条件，可不上传
              </span>
            </template>
            <i class="iconfont icon-wenhao"></i>
          </a-tooltip>
        </div>
        <div class="item" v-if="specialStatus == 1">
          <span style="margin-top: -20px;margin-bottom: 10px;color: #18194f;">请上传符合报名条件中所列举的获奖证书或证明材料。</span>
          <div class="special-file-box">
            <a-select
                placeholder="专项奖类型"
                v-model="rewardType1"
                class="type"
            >
              <a-select-option value="学科竞赛类"> 学科竞赛类 </a-select-option>
              <a-select-option value="科技创新类"> 科技创新类 </a-select-option>
              <a-select-option value="文学、语言特长类"> 文学、语言特长类 </a-select-option>
              <a-select-option value="艺术特长类"> 艺术特长类 </a-select-option>
              <a-select-option value="体育特长类"> 体育特长类 </a-select-option>
            </a-select>
            <div class="upload-div" @click="uploadSpecialFile1()">
              {{specialFile1}}
              <i class="iconfont icon-tianjia2" style="float: right"></i>
              <i class="iconfont icon-jianshao" style="float: right" v-if="specialFile1 !== ''" @click.stop="deleteFile(specialFile1Id, specialFile1 = '', rewardType1 = undefined, $refs.specialFile1.value = '')"></i>
              <span class="preview" v-if="specialFile1 !== ''" @click.stop="showMaterial(specialFile1Url)">预览</span>
            </div>
            <input
                type="file"
                style="display: none"
                accept="image/*,application/pdf"
                ref="specialFile1"
                @change="getSpecialFile1"
            />
          </div>
        </div>
        <div class="item" v-if="specialStatus == 1">
          <div class="special-file-box">
            <a-select
                placeholder="专项奖类型"
                v-model="rewardType2"
                class="type"
            >
              <a-select-option value="学科竞赛类"> 学科竞赛类 </a-select-option>
              <a-select-option value="科技创新类"> 科技创新类 </a-select-option>
              <a-select-option value="文学、语言特长类"> 文学、语言特长类 </a-select-option>
              <a-select-option value="艺术特长类"> 艺术特长类 </a-select-option>
              <a-select-option value="体育特长类"> 体育特长类 </a-select-option>
            </a-select>
            <div class="upload-div" @click="uploadSpecialFile2()">
              {{specialFile2}}
              <i class="iconfont icon-tianjia2" style="float: right"></i>
              <i class="iconfont icon-jianshao" style="float: right" v-if="specialFile2 !== ''" @click.stop="deleteFile(specialFile2Id, specialFile2 = '', rewardType2 = undefined, $refs.specialFile2.value = '')"></i>
              <span class="preview" v-if="specialFile2 !== ''" @click.stop="showMaterial(specialFile2Url)">预览</span>
            </div>
            <input
              type="file"
              style="display: none"
              accept="image/*,application/pdf"
              ref="specialFile2"
              @change="getSpecialFile2"
            />
          </div>
        </div>
        <div class="item" v-if="specialStatus == 1">
          <div class="special-file-box">
            <a-select
                placeholder="专项奖类型"
                v-model="rewardType3"
                class="type"
            >
              <a-select-option value="学科竞赛类"> 学科竞赛类 </a-select-option>
              <a-select-option value="科技创新类"> 科技创新类 </a-select-option>
              <a-select-option value="文学、语言特长类"> 文学、语言特长类 </a-select-option>
              <a-select-option value="艺术特长类"> 艺术特长类 </a-select-option>
              <a-select-option value="体育特长类"> 体育特长类 </a-select-option>
            </a-select>
            <div class="upload-div" @click="uploadSpecialFile3()">
              {{specialFile3}}
              <i class="iconfont icon-tianjia2" style="float: right"></i>
              <i class="iconfont icon-jianshao" style="float: right" v-if="specialFile3 !== ''" @click.stop="deleteFile(specialFile3Id, specialFile3 = '', rewardType3 = undefined, $refs.specialFile3.value = '')"></i>
              <span class="preview" v-if="specialFile3 !== ''" @click.stop="showMaterial(specialFile3Url)">预览</span>
            </div>
            <input
              type="file"
              style="display: none"
              accept="image/*,application/pdf"
              ref="specialFile3"
              @change="getSpecialFile3"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box" v-if="uploadDone == 'false'">
      <div class="save-btn" @click="goPrevStep" v-if="applyTime != 0">上一步</div>
      <div class="save-btn" @click="save" v-if="applyTime != 0">暂存</div>
      <div class="next-btn" @click="upload" v-if="applyTime != 0">确认上传完整</div>
      <div class="save-btn" v-if="applyTime == 0">报名已结束</div>
    </div>
  <!-- </div> -->
    <div class="content-succ" v-if="uploadDone == 'true'">
      <div class="preview-box">
        <div @click="preview(zhengUrl)">查看身份证正面照</div>
        <div @click="preview(fanUrl)">查看身份证背面照</div>
        <div @click="preview(applyUrl)">查看申请表</div>
<!--        <div @click="preview(chengxinUrl)">查看考生诚信承诺书</div>-->
        <div @click="preview(specialFile1Url)" v-if="specialFile1Url!== ''">证书1</div>
        <div @click="preview(specialFile2Url)" v-if="specialFile2Url!== ''">证书2</div>
        <div @click="preview(specialFile3Url)" v-if="specialFile3Url!== ''">证书3</div>
      </div>
      <div class="box">
        <div v-html="uploadDoneText"></div>
      </div>
      <div class="btn-box" v-if="applyStatus == 2 || applyStatus == 4">
        <div class="back-btn" @click="applyMaterialBack" v-if="applyTime != 0">上传材料有误<br />重新上传</div>
        <a-tooltip placement="top" v-if="applyTime != 0">
          <template slot="title">
            <span>
              如材料上传无误，<br />
              请忽略此按钮
            </span>
          </template>
          <i class="iconfont icon-wenhao"></i>
        </a-tooltip>
      </div>
<!--      <div class="back-tips" v-if="(applyStatus == 2 || applyStatus == 4) && applyTime != 0">请注意重新确认上传完整。</div>-->
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
    <div class="filePre" v-if="filePreShow" @click.stop="hideMaterial()">
      <img :src="filePreImg" style="max-width:100%" v-if="filePreImg !== ''">
      <div class="cl-pdf" v-if="filePrePdf !== ''">
        <pdf
            ref="pdf"
            v-for="i in filePrePdfPages"
            :key="i"
            :src="filePrePdf"
            :page="i"
        ></pdf>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  data() {
    return {
      applyTime:this.$store.state.applyTime,
      zhengPic:"请上传身份证人像面照片",
      fanPic:"请上传身份证国徽面照片",
      applyFile:"",
      chengxinFile:"",
      specialFile1:"",
      specialFile2:"",
      specialFile3:"",
      verification:{
        zhengPic:false,
        fanPic:false,
        applyFile:false,
        chengxinFile:false
      },
      uploadDone:'',
      loading:false,
      uploadDoneText:"",
      backLimitsStatus:0,
      zhengUrl:"",
      fanUrl:"",
      applyUrl:"",
      chengxinUrl:"",
      specialFile1Url:"",
      specialFile2Url:"",
      specialFile3Url:"",
      specialStatus:0,
      applyStatus:1,
      rewardType1:undefined,
      rewardType2:undefined,
      rewardType3:undefined,
      specialFile1Id:'',
      specialFile2Id:'',
      specialFile3Id:'',
      specialFileDeleteId: [],
      zhengPicId:0,
      fanPicId:0,
      applyFileId:0,
      chengxinFileId:0,
      zhengPicDeleteId:0,
      fanPicDeleteId:0,
      applyFileDeleteId:0,
      chengxinFileDeleteId:0,
      filePreShow: false,
      filePreImg: '',
      filePrePdf: '',
      filePrePdfPages: '',
    };
  },
  created(){
    this.getMaterialStatus();
    this.getBackLimits();
    this.getSpecialStatus();
    this.getApplyStatus();
  },
  methods: {
    getApplyStatus() {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.applyStatus = res.data.applyStatus;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goPrevStep(){
      this.$router.push("/aplyform");
    },
    uploadZhengPic() {
        this.$refs.zhengPic.click()
      // this.$refs.zhengPic.dispatchEvent(new MouseEvent("click"));
    },
    getZhengPic() {
      var picName=this.$refs.zhengPic.files[0].name;
      var suffix = picName.split('.');
      if(suffix[suffix.length-1].toLowerCase() != 'jpg' && suffix[suffix.length-1].toLowerCase() != 'png' && suffix[suffix.length-1].toLowerCase() != 'jpeg' && suffix[suffix.length-1].toLowerCase() != 'pdf'){
          this.$message.error('上传文件格式有误，请上传jpg png jpeg 格式的图片文件或pdf文件！');
          this.$refs.zhengPic.value = ''
          this.zhengPic = '请上传身份证人像面照片'
          return;
      }
      var patrn=/[`~!@#$%^&*()+<>?:"{},\\/;、《》【】！？—（），。；‘“’”\s'[\]]/im;
      if(patrn.test(picName)){
          this.$message.error('您上传的文件名含有非法字符，请重新上传！');
          this.$refs.zhengPic.value = ''
          this.zhengPic = '请上传身份证人像面照片'
          return;
      }
      if(this.$refs.zhengPic.files[0].size <= 0){
          this.$message.error('上传文件不能为空，请重新上传！');
          this.$refs.zhengPic.value = ''
          this.zhengPic = '请上传身份证人像面照片'
          return;
      }
      if(this.$refs.zhengPic.files[0].size/1024 > 5000){
          this.$message.error('上传文件不能大于5M，请重新上传！');
          this.$refs.zhengPic.value = ''
          this.zhengPic = '请上传身份证人像面照片'
          return;
      }
      this.zhengPicDeleteId = 0;
      this.zhengPic = this.$refs.zhengPic.files[0].name;
      // console.log(this.$refs.zhengPic.files[0]);
      this.verification['zhengPic'] = false;
      let reader = new FileReader();
      let _that = this;
      reader.readAsDataURL(this.$refs.zhengPic.files[0]);
      reader.onload = function () {
        _that.zhengUrl = this.result;
      };
    },
    delZhengPic() {
      this.$refs.zhengPic.value = ''
      this.zhengPic = '请上传身份证人像面照片'
      this.zhengPicDeleteId = this.zhengPicId
    },
    delFanPic() {
      this.$refs.fanPic.value = ''
      this.fanPic = '请上传身份证国徽面照片'
      this.fanPicDeleteId = this.fanPicId
    },
    delApplyFile() {
      this.$refs.applyFile.value = ''
      this.applyFile = ''
      this.applyFileDeleteId = this.applyFileId
    },
    delChengxinFile() {
      this.$refs.chengxinFile.value = ''
      this.chengxinFile = ''
      this.chengxinFileDeleteId = this.chengxinFileId
    },
    uploadFanPic() {
        this.$refs.fanPic.click()
      // this.$refs.fanPic.dispatchEvent(new MouseEvent("click"));
    },
    getFanPic() {
      var picName=this.$refs.fanPic.files[0].name;
      var suffix = picName.split('.');
      if(suffix[suffix.length-1].toLowerCase() != 'jpg' && suffix[suffix.length-1].toLowerCase() != 'png' && suffix[suffix.length-1].toLowerCase() != 'jpeg' && suffix[suffix.length-1].toLowerCase() != 'pdf'){
          this.$message.error('上传文件格式有误，请上传jpg png jpeg 格式的图片文件或pdf文件！');
          this.$refs.fanPic.value = ''
          this.fanPic = '请上传身份证国徽面照片'
          return;
      }
      var patrn=/[`~!@#$%^&*()+<>?:"{},\\/;、《》【】！？—（），。；‘“’”\s'[\]]/im;
      if(patrn.test(picName)){
          this.$message.error('您上传的文件名含有非法字符，请重新上传！');
          this.$refs.fanPic.value = ''
          this.fanPic = '请上传身份证国徽面照片'
          return;
      }
      if(this.$refs.fanPic.files[0].size <= 0){
          this.$message.error('上传文件不能为空，请重新上传！');
          this.$refs.fanPic.value = ''
          this.fanPic = '请上传身份证人像面照片'
          return;
      }
      if(this.$refs.fanPic.files[0].size/1024 > 5000){
          this.$message.error('上传文件不能大于5M，请重新上传！');
          this.$refs.fanPic.value = ''
          this.fanPic = '请上传身份证国徽面照片'
          return;
      }
      this.fanPicDeleteId = 0;
      this.fanPic = this.$refs.fanPic.files[0].name;
      // console.log(this.$refs.fanPic.files[0]);
      this.verification['fanPic'] = false;
      let reader = new FileReader();
      let _that = this;
      reader.readAsDataURL(this.$refs.fanPic.files[0]);
      reader.onload = function () {
        _that.fanUrl = this.result;
      };
    },
    uploadApplyFile() {
        this.$refs.applyFile.click()
      // this.$refs.applyFile.dispatchEvent(new MouseEvent("click"));
    },
    getApplyFile() {
      var picName=this.$refs.applyFile.files[0].name;
      var suffix = picName.split('.');
      if(suffix[suffix.length-1].toLowerCase() != 'jpg' && suffix[suffix.length-1].toLowerCase() != 'png' && suffix[suffix.length-1].toLowerCase() != 'jpeg' && suffix[suffix.length-1].toLowerCase() != 'pdf'){
          this.$message.error('上传文件格式有误，请上传jpg png jpeg 格式的图片文件或pdf文件！');
          this.$refs.applyFile.value = ''
          this.applyFile = ''
          return;
      }
      var patrn=/[`~!@#$%^&*()+<>?:"{},\\/;、《》【】！？—（），。；‘“’”\s'[\]]/im;
      if(patrn.test(picName)){
          this.$message.error('您上传的文件名含有非法字符，请重新上传！');
          this.$refs.applyFile.value = ''
          this.applyFile = ''
          return;
      }
      if(this.$refs.applyFile.files[0].size <= 0){
          this.$message.error('上传文件不能为空，请重新上传！');
          this.$refs.applyFile.value = ''
          this.applyFile = ''
          return;
      }
      if(this.$refs.applyFile.files[0].size/1024 > 5000){
          this.$message.error('上传文件不能大于5M，请重新上传！');
          this.$refs.applyFile.value = ''
          this.applyFile = ''
          return;
      }
      this.applyFileDeleteId = 0;
      this.applyFile = this.$refs.applyFile.files[0].name;
      // console.log(this.$refs.applyFile.files[0]);
      this.verification['applyFile'] = false;
      let reader = new FileReader();
      let _that = this;
      reader.readAsDataURL(this.$refs.applyFile.files[0]);
      reader.onload = function () {
        _that.applyUrl = this.result;
      };
    },
    uploadChengxinFile() {
        this.$refs.chengxinFile.click()
      // this.$refs.chengxinFile.dispatchEvent(new MouseEvent("click"));
    },
    getChengxinFile() {
      var picName=this.$refs.chengxinFile.files[0].name;
      var suffix = picName.split('.');
      if(suffix[suffix.length-1].toLowerCase() != 'jpg' && suffix[suffix.length-1].toLowerCase() != 'png' && suffix[suffix.length-1].toLowerCase() != 'jpeg' && suffix[suffix.length-1].toLowerCase() != 'pdf'){
          this.$message.error('上传文件格式有误，请上传jpg png jpeg 格式的图片文件或pdf文件！');
          this.$refs.chengxinFile.value = ''
          this.chengxinFile = ''
          return;
      }
      var patrn=/[`~!@#$%^&*()+<>?:"{},\\/;、《》【】！？—（），。；‘“’”\s'[\]]/im;
      if(patrn.test(picName)){
          this.$message.error('您上传的文件名含有非法字符，请重新上传！');
          this.$refs.chengxinFile.value = ''
          this.chengxinFile = ''
          return;
      }
      if(this.$refs.chengxinFile.files[0].size <= 0){
          this.$message.error('上传文件不能为空，请重新上传！');
          this.$refs.chengxinFile.value = ''
          this.chengxinFile = ''
          return;
      }
      if(this.$refs.chengxinFile.files[0].size/1024 > 5000){
          this.$message.error('上传文件不能大于5M，请重新上传！');
          this.$refs.chengxinFile.value = ''
          this.chengxinFile = ''
          return;
      }
      this.chengxinFileDeleteId = 0;
      this.chengxinFile = this.$refs.chengxinFile.files[0].name;
      // console.log(this.$refs.chengxinFile.files[0]);
      this.verification['chengxinFile'] = false;
      let reader = new FileReader();
      let _that = this;
      reader.readAsDataURL(this.$refs.chengxinFile.files[0]);
      reader.onload = function () {
        _that.chengxinUrl = this.result;
      };
    },
    uploadSpecialFile1() {
        this.$refs.specialFile1.click()
      // this.$refs.specialFile1.dispatchEvent(new MouseEvent("click"));
    },
    getSpecialFile1() {
      var picName=this.$refs.specialFile1.files[0].name;
      var suffix = picName.split('.');
      if(suffix[suffix.length-1].toLowerCase() != 'jpg' && suffix[suffix.length-1].toLowerCase() != 'png' && suffix[suffix.length-1].toLowerCase() != 'jpeg' && suffix[suffix.length-1].toLowerCase() != 'pdf'){
          this.$message.error('上传文件格式有误，请上传jpg png jpeg 格式的图片文件或pdf文件！');
          this.$refs.specialFile1.value = ''
          this.specialFile1 = ''
          return;
      }
      var patrn=/[`~!@#$%^&*()+<>?:"{},\\/;、《》【】！？—（），。；‘“’”\s'[\]]/im;
      if(patrn.test(picName)){
          this.$message.error('您上传的文件名含有非法字符，请重新上传！');
          this.$refs.specialFile1.value = ''
          this.specialFile1 = ''
          return;
      }
      if(this.$refs.specialFile1.files[0].size <= 0){
          this.$message.error('上传文件不能为空，请重新上传！');
          this.$refs.specialFile1.value = ''
          this.specialFile1 = ''
          return;
      }
      if(this.$refs.specialFile1.files[0].size/1024 > 5000){
          this.$message.error('上传文件不能大于5M，请重新上传！');
          this.$refs.specialFile1.value = ''
          this.specialFile1 = ''
          return;
      }
      if (this.specialFileDeleteId.indexOf(this.specialFile1Id) !== -1) {
        var number = this.specialFileDeleteId.indexOf(this.specialFile1Id);
        this.specialFileDeleteId.splice(number, 1);
      }
      this.specialFile1 = this.$refs.specialFile1.files[0].name;
      let reader = new FileReader();
      let _that = this;
      reader.readAsDataURL(this.$refs.specialFile1.files[0]);
      reader.onload = function () {
        _that.specialFile1Url = this.result;
      };
    },
    uploadSpecialFile2() {
        this.$refs.specialFile2.click()
      // this.$refs.specialFile2.dispatchEvent(new MouseEvent("click"));
    },
    getSpecialFile2() {
      var picName=this.$refs.specialFile2.files[0].name;
      var suffix = picName.split('.');
      if(suffix[suffix.length-1].toLowerCase() != 'jpg' && suffix[suffix.length-1].toLowerCase() != 'png' && suffix[suffix.length-1].toLowerCase() != 'jpeg' && suffix[suffix.length-1].toLowerCase() != 'pdf'){
          this.$message.error('上传文件格式有误，请上传jpg png jpeg 格式的图片文件或pdf文件！');
          this.$refs.specialFile2.value = ''
          this.specialFile2 = ''
          return;
      }
      var patrn=/[`~!@#$%^&*()+<>?:"{},\\/;、《》【】！？—（），。；‘“’”\s'[\]]/im;
      if(patrn.test(picName)){
          this.$message.error('您上传的文件名含有非法字符，请重新上传！');
          this.$refs.specialFile2.value = ''
          this.specialFile2 = ''
          return;
      }
      if(this.$refs.specialFile2.files[0].size <= 0){
          this.$message.error('上传文件不能为空，请重新上传！');
          this.$refs.specialFile2.value = ''
          this.specialFile2 = ''
          return;
      }
      if(this.$refs.specialFile2.files[0].size/1024 > 5000){
          this.$message.error('上传文件不能大于5M，请重新上传！');
          this.$refs.specialFile2.value = ''
          this.specialFile2 = ''
          return;
      }
      if (this.specialFileDeleteId.indexOf(this.specialFile2Id) !== -1) {
        var number = this.specialFileDeleteId.indexOf(this.specialFile2Id);
        this.specialFileDeleteId.splice(number, 1);
      }
      this.specialFile2 = this.$refs.specialFile2.files[0].name;
      let reader = new FileReader();
      let _that = this;
      reader.readAsDataURL(this.$refs.specialFile2.files[0]);
      reader.onload = function () {
        _that.specialFile2Url = this.result;
      };
    },
    uploadSpecialFile3() {
        this.$refs.specialFile3.click()
      // this.$refs.specialFile3.dispatchEvent(new MouseEvent("click"));
    },
    getSpecialFile3() {
      var picName=this.$refs.specialFile3.files[0].name;
      var suffix = picName.split('.');
      if(suffix[suffix.length-1].toLowerCase() != 'jpg' && suffix[suffix.length-1].toLowerCase() != 'png' && suffix[suffix.length-1].toLowerCase() != 'jpeg' && suffix[suffix.length-1].toLowerCase() != 'pdf'){
          this.$message.error('上传文件格式有误，请上传jpg png jpeg 格式的图片文件或pdf文件！');
          this.$refs.specialFile3.value = ''
          this.specialFile3 = ''
          return;
      }
      var patrn=/[`~!@#$%^&*()+<>?:"{},\\/;、《》【】！？—（），。；‘“’”\s'[\]]/im;
      if(patrn.test(picName)){
          this.$message.error('您上传的文件名含有非法字符，请重新上传！');
          this.$refs.specialFile3.value = ''
          this.specialFile3 = ''
          return;
      }
      if(this.$refs.specialFile3.files[0].size <= 0){
          this.$message.error('上传文件不能为空，请重新上传！');
          this.$refs.specialFile3.value = ''
          this.specialFile3 = ''
          return;
      }
      if(this.$refs.specialFile3.files[0].size/1024 > 5000){
          this.$message.error('上传文件不能大于5M，请重新上传！');
          this.$refs.specialFile3.value = ''
          this.specialFile3 = ''
          return;
      }
      if (this.specialFileDeleteId.indexOf(this.specialFile3Id) !== -1) {
        var number = this.specialFileDeleteId.indexOf(this.specialFile3Id);
        this.specialFileDeleteId.splice(number, 1);
      }
      this.specialFile3 = this.$refs.specialFile3.files[0].name;
      let reader = new FileReader();
      let _that = this;
      reader.readAsDataURL(this.$refs.specialFile3.files[0]);
      reader.onload = function () {
        _that.specialFile3Url = this.result;
      };
    },
    save(){
      let _that = this;
      // 必要的文件
      var isNeed = true;
      if(!this.$refs.zhengPic.files[0] && !this.$refs.fanPic.files[0] && !this.$refs.applyFile.files[0]){
        isNeed = false;
      }
      // 有无证书
      var isSpecial = true;
      if(this.specialStatus == 0 ||(!this.$refs.specialFile1.files[0] && !this.$refs.specialFile2.files[0] && !this.$refs.specialFile3.files[0] && this.specialFileDeleteId.length === 0)){
        isSpecial = false;
      }
      var isDel = false;
      // 有删除必传文件
      if (!(this.zhengPicDeleteId === 0 && this.fanPicDeleteId === 0 && this.applyFileDeleteId === 0)) {
        isDel = true;
        var formData1 = new FormData();
        formData1.append('delIds', [this.zhengPicDeleteId, this.fanPicDeleteId, this.applyFileDeleteId]);
        this.$api
            .delMaterials(formData1)
            .then((res) => {
              // console.log(res);
              if(!isNeed && !isSpecial){
                // 没有文件上传，直接关了loading
                _that.loading = false;
              }
              if(res.data.code == 0){
                this.zhengPicDeleteId = 0;
                this.fanPicDeleteId = 0;
                this.applyFileDeleteId = 0;
                // this.chengxinFileDeleteId = 0;
                if(!isNeed && !isSpecial){
                  // 没有文件上传，直接提示
                  this.$message.success(res.data.msg);
                }
              }else{
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              _that.loading = false;
              console.log(err);
            });
      }

      if(isSpecial){
        // 有证书则上传
        var formData0 = new FormData();
        if ((this.$refs.specialFile1.files[0] && (this.rewardType1 === undefined || this.rewardType1 === null))
            || (this.$refs.specialFile2.files[0] && (this.rewardType2 === undefined || this.rewardType2 === null))
            || (this.$refs.specialFile3.files[0] && (this.rewardType3 === undefined || this.rewardType3 === null))) {
          this.$message.warning("专项奖类型不能为空！");
          return;
        }
        formData0.append('reward1',this.$refs.specialFile1.files[0]);
        formData0.append('reward2',this.$refs.specialFile2.files[0]);
        formData0.append('reward3',this.$refs.specialFile3.files[0]);
        formData0.append('type',0);
        formData0.append('rewardType1',this.rewardType1);
        formData0.append('rewardType2',this.rewardType2);
        formData0.append('rewardType3',this.rewardType3);
        formData0.append('deleteIds',this.specialFileDeleteId);
        _that.loading = true;
        this.$api
          .uploadReWards(formData0)
          .then((res) => {
            // console.log(res);
            if(!isNeed){
              // 没有必要文件，直接关了loading
              _that.loading = false;
            }
            if(res.data.code == 0){
              if(!isNeed){
                // 没有必要文件了，直接提示
                this.$message.success(res.data.msg);
                this.$refs.specialFile1.value = ''
                this.$refs.specialFile2.value = ''
                this.$refs.specialFile3.value = ''
              }
              this.specialFileDeleteId = [];
              this.getMaterialedList();
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            _that.loading = false;
            console.log(err);
          });
      }

      if(!isNeed){
        if(!isSpecial && !isDel){
          // 也没有证书
          if(this.zhengPic == '' || this.fanPic == ''){
            this.$message.warning("请上传身份证照片！");
            return;
          }
          if(this.applyFile == ''){
            this.$message.warning("请上传申请表！");
            return;
          }
          // if(this.chengxinFile == ''){
          //   this.$message.warning("请上传考生诚信承诺书！");
          //   return;
          // }
          if (this.specialFileDeleteId.length === 0) {
            // this.$message.warning("文件未修改，请至少修改一项后再暂存！");
            this.$message.success("已暂存");
          }
        }
        return;
      }
      var formData = new FormData();
      formData.append('cardPic1',this.$refs.zhengPic.files[0]);
      formData.append('cardPic2',this.$refs.fanPic.files[0]);
      formData.append('applyFile',this.$refs.applyFile.files[0]);
      // formData.append('chengxinFile',this.$refs.chengxinFile.files[0]);
      formData.append('type',0);
      // console.log(formData)
      _that.loading = true;
      this.$api
        .uploadMaterials(formData)
        .then((res) => {
          // console.log(res);
          _that.loading = false;
          if(res.data.code == 0){
            this.$refs.zhengPic.value = ''
            this.$refs.fanPic.value = ''
            this.$refs.applyFile.value = ''
            // this.$refs.chengxinFile.value = ''
            this.$message.success(res.data.msg);
            this.getMaterialedList();
          }else{
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          _that.loading = false;
          console.log(err);
        });
    },
    upload(){
      let _that = this;
      // 必要的文件
      var isNeed = true;
      if(!this.$refs.zhengPic.files[0] && !this.$refs.fanPic.files[0] && !this.$refs.applyFile.files[0]){
         isNeed = false;
      }
      // 有无证书
      var isSpecial = true;
      if(this.specialStatus == 0||(!this.$refs.specialFile1.files[0] && !this.$refs.specialFile2.files[0] && !this.$refs.specialFile3.files[0])){
        isSpecial = false;
      }

      if(isSpecial || this.specialFileDeleteId.length !== 0){
        // 有证书则上传
        var formData0 = new FormData();
        if ((this.$refs.specialFile1.files[0] && (this.rewardType1 === undefined || this.rewardType1 === null))
            || (this.$refs.specialFile2.files[0] && (this.rewardType2 === undefined || this.rewardType2 === null))
            || (this.$refs.specialFile3.files[0] && (this.rewardType3 === undefined || this.rewardType3 === null))) {
          this.$message.warning("专项奖类型不能为空！");
          return;
        }
        formData0.append('reward1',this.$refs.specialFile1.files[0]);
        formData0.append('reward2',this.$refs.specialFile2.files[0]);
        formData0.append('reward3',this.$refs.specialFile3.files[0]);
        formData0.append('type',1);
        formData0.append('rewardType1',this.rewardType1);
        formData0.append('rewardType2',this.rewardType2);
        formData0.append('rewardType3',this.rewardType3);
        formData0.append('deleteIds',this.specialFileDeleteId);
        _that.loading = true;
        this.$api
          .uploadReWards(formData0)
          .then((res) => {
            // console.log(res);
            if(!isNeed){
              // 没有必要文件，直接关了loading
              _that.loading = false;
            }
            if(res.data.code == 0){
              if(!isNeed){
                this.$refs.specialFile1.value = ''
                this.$refs.specialFile2.value = ''
                this.$refs.specialFile3.value = ''
                // 如果没有上传必要文件，则直接检查是否上传完全；否则留给后面接口检查
                // this.$message.success(res.data.msg);
                _that.getMaterialStatus('saveCheck');
              }
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            _that.loading = false;
            console.log(err);
          });
      }

      // // 如果必要都有了，就检测
      // if(!isNeed && this.zhengPic != '请上传身份证人像面照片' && this.fanPic != '请上传身份证国徽面照片' && this.applyFile != '' && this.chengxinFile != ''){
      //   _that.getMaterialStatus('saveCheck');
      //   return;
      // }

      if(!isNeed){
        if(!isSpecial){
          // 也没有证书
          if(this.zhengPic == '请上传身份证人像面照片' || this.fanPic == '请上传身份证国徽面照片'){
            this.$message.warning("请上传身份证照片！");
            return;
          }
          if(this.applyFile == ''){
            this.$message.warning("请上传申请表！");
            return;
          }
          // if(this.chengxinFile == ''){
          //   this.$message.warning("请上传考生诚信承诺书！");
          //   return;
          // }
          // this.$message.warning("文件未修改，请至少修改一项后再保存！");
        }
        // return;
      }
      _that.loading = true;
      var formData = new FormData();
      formData.append('cardPic1',this.$refs.zhengPic.files[0]);
      formData.append('cardPic2',this.$refs.fanPic.files[0]);
      formData.append('applyFile',this.$refs.applyFile.files[0]);
      // formData.append('chengxinFile',this.$refs.chengxinFile.files[0]);
      formData.append('type',1);
      // console.log(formData)
      this.$api
        .uploadMaterials(formData)
        .then((res) => {
          // console.log(res);
          _that.loading = false;
          if(res.data.code == 0){
            this.$refs.zhengPic.value = ''
            this.$refs.fanPic.value = ''
            this.$refs.applyFile.value = ''
            // this.$refs.chengxinFile.value = ''
            // this.$message.success(res.data.msg);
            _that.getMaterialStatus('saveCheck');
          }else{
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          _that.loading = false;
          console.log(err);
        });

    },
    // eslint-disable-next-line no-unused-vars
    deleteFile(id, file, type, f) {
      if (id !== '' && this.specialFileDeleteId.indexOf(id) === -1) {
        this.specialFileDeleteId.push(id);
      }
    },
    getBackLimits(){
      this.$api
        .getProgressList({
          code:'code_4'
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.backLimitsStatus = 409;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMaterialStatus(type){
      this.loading = true;
      this.$api
        .getMaterialStatus()
        .then((res) => {
          this.loading = false;
          // console.log(res);
          if(res.data.code == 0){
            if(res.data.materialStatus == 1){
              this.uploadDone = 'true';
              this.getFirstAuditInfo();
              this.getMaterialedList();
            }else{
              this.uploadDone = 'false';
              this.getMaterialedList();
              if(type == 'saveCheck'){
                this.$message.success('上传成功，请继续上传其他材料！');
              }
            }
          }else{
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFirstAuditInfo(){
      this.$api
          .getFirstAuditInfo()
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              if(res.data.status <= 4){
                this.getContentConfigList('佐证材料上传成功');
              }else if(res.data.status == 6){
                this.getContentConfigList('初审未通过');
              }else if(res.data.status == 5 || res.data.status == 8){
                this.getContentConfigList('初审通过');
              }else{
                this.getContentConfigList('已缴费');
              }
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getContentConfigList(name){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:name
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                this.uploadDoneText = res.data.configList[item].content;
                if(name == '佐证材料上传成功'){
                  this.getAuditTime();
                }
                if(name == '初审通过'){
                  this.getPayTime();
                }
                if(name == '已缴费'){
                  this.getTestTime();
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getAuditTime(){
        this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'初审时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(0,1) == '0'){
                  timeStr = res.data.configList[item].content.substring(1,3);
                }else{
                  timeStr = res.data.configList[item].content.substring(0,3);
                }
                if(res.data.configList[item].content.substring(3,4) == '0'){
                  timeStr += res.data.configList[item].content.substring(4);
                }else{
                  timeStr += res.data.configList[item].content.substring(3);
                }
                this.uploadDoneText = this.uploadDoneText.replace('{time}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getPayTime(){
        this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'缴费时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(5,6) == '0'){
                    timeStr = res.data.configList[item].content.substring(6,8);
                  }else{
                    timeStr = res.data.configList[item].content.substring(5,8);
                  }
                  if(res.data.configList[item].content.substring(8,9) == '0'){
                    timeStr += res.data.configList[item].content.substring(9,12);
                  }else{
                    timeStr += res.data.configList[item].content.substring(8,12);
                  }
                  if(res.data.configList[item].content.substring(17,18) == '0'){
                    timeStr += res.data.configList[item].content.substring(18,20);
                  }else{
                    timeStr += res.data.configList[item].content.substring(17,20);
                  }
                  if(res.data.configList[item].content.substring(20,21) == '0'){
                    timeStr += res.data.configList[item].content.substring(21);
                  }else{
                    timeStr += res.data.configList[item].content.substring(20);
                  }
                this.uploadDoneText = this.uploadDoneText.replace('{time}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTestTime(){
        this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'准考证打印时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(5,6) == '0'){
                  timeStr = res.data.configList[item].content.substring(6,8);
                }else{
                  timeStr = res.data.configList[item].content.substring(5,8);
                }
                if(res.data.configList[item].content.substring(8,9) == '0'){
                  timeStr += res.data.configList[item].content.substring(9);
                }else{
                  timeStr += res.data.configList[item].content.substring(8);
                }
                this.uploadDoneText = this.uploadDoneText.replace('{time1}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "综合测试时间",
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              var timeStr = '';
              if(res.data.configList[0].content.substring(5,6) == '0'){
                timeStr = res.data.configList[0].content.substring(6,8);
              }else{
                timeStr = res.data.configList[0].content.substring(5,8);
              }
              if(res.data.configList[0].content.substring(8,9) == '0'){
                timeStr += res.data.configList[0].content.substring(9,12);
              }else{
                timeStr += res.data.configList[0].content.substring(8,12);
              }
              if(res.data.configList[0].content.substring(12,13) == '0'){
                timeStr += res.data.configList[0].content.substring(13);
              }else{
                timeStr += res.data.configList[0].content.substring(12);
              }
              this.uploadDoneText = this.uploadDoneText.replace('{time2}',timeStr);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    applyMaterialBack(){
      if(this.backLimitsStatus == 409){
        this.$message.warning('现已暂停撤回申请，请等待开启！');
        return;
      }
      var _that = this;
      this.$confirm({
        title: '您是否确定撤回您的报名申请？',
        okText: '确定',
        cancelText: '我再想想',
        onOk() {
          _that.$api
              .applyMaterialBack()
              .then((res) => {
                // console.log(res);
                if (res.data.code == 0) {
                  _that.getMaterialStatus();
                } else {
                  _that.$message.error(res.data.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
        },
      });
    },
    getMaterialedList(){
      this.$api
          .getMaterialedList()
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.specialFile1Url = '';
              this.specialFile2Url = '';
              this.specialFile3Url = '';
              res.data.materialedList.forEach(element => {
                if(Number(element.useType) == 0){
                  this.zhengPic = element.fileName;
                  this.zhengUrl = element.fileUrl;
                  this.zhengPicId = element.id;
                }
                if(Number(element.useType) == 1){
                  this.fanPic = element.fileName;
                  this.fanUrl = element.fileUrl;
                  this.fanPicId = element.id;
                }
                if(Number(element.useType) == 2){
                  this.applyFile = element.fileName;
                  this.applyUrl = element.fileUrl;
                  this.applyFileId = element.id;
                }
                if(Number(element.useType) == 3){
                  this.chengxinFile = element.fileName;
                  this.chengxinUrl = element.fileUrl;
                  this.chengxinFileId = element.id;
                }
                if(Number(element.useType) == 4){
                  this.specialFile1 = element.fileName;
                  this.specialFile1Url = element.fileUrl;
                  this.rewardType1 = element.rewardType;
                  this.specialFile1Id = element.id;
                }
                if(Number(element.useType) == 5){
                  this.specialFile2 = element.fileName;
                  this.specialFile2Url = element.fileUrl;
                  this.rewardType2= element.rewardType;
                  this.specialFile2Id = element.id;
                }
                if(Number(element.useType) == 6){
                  this.specialFile3 = element.fileName;
                  this.specialFile3Url = element.fileUrl;
                  this.rewardType3 = element.rewardType;
                  this.specialFile3Id = element.id;
                }
              });
            } else {
              // this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    preview(path){
      window.open(path, "_blank");
    },
    getSpecialStatus() {
      this.$api
        .getProgressList({
          code: "code_9",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.specialStatus = 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showMaterial(url) {
      this.filePreShow = true;
      if(url.indexOf('application/pdf') > -1 || url.indexOf('.pdf') > -1){
        this.filePrePdf = url;
        let loadingTask = pdf.createLoadingTask(url)
        loadingTask.promise.then(pdf => {
          this.filePrePdfPages = pdf.numPages
        }).catch(err => {
          console.error('pdf 加载失败', err)
        })
      } else {
        this.filePreImg = url;
      }
    },
    hideMaterial() {
      this.filePreShow = false;
      this.filePreImg = '';
      this.filePrePdf = '';
    }
  },
  components: {
    pdf
  }
};
</script>

<style lang='scss' scoped>
.content-div{
  height: 100%;
  overflow-y:scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  display: flex;
  .left-box {
    width: calc(100% - 30px);
    max-width: 1200px;
    .title {
      background-color: #e8ecee;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      padding-left: 18px;
      margin-bottom: 30px;
      margin-top: 50px;
      i {
        float: right;
        margin-right: -30px;
        font-size: 22px;
        color: #858585;
        font-weight: normal;
      }
    }
    .item {
      display: flex;
      // min-height: 90px;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
      .special-file-box {
        width: 100%;
        .type {
          width: 160px;
          float: left;
          height: 36px;
          padding-top: 2px;
        }
        .upload-div {
          width: calc(100% - 170px);
          float: right;
        }
      }
      .upload-div {
        height: 36px;
        border: 1px #ccc solid;
        line-height: 36px;
        width: 100%;
        padding-left: 10px;
        position: relative;
        cursor: pointer;
        i {
          float: right;
          font-size: 22px;
          margin-right: 10px;
          color: #c8cfd4;
        }
        &.warning {
          border-color: #ff5722;
        }
        .verification-tips {
          position: absolute;
          bottom: -3px;
          font-size: 14px;
          color: #ff5722;
          padding-left: 10px;
        }
      }
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
.content-succ {
  font-size: 22px;
  font-weight: bold;
  color: #009bbd;
  text-align: center;
  line-height: 2;
  margin-top: 50px;
  .preview-box{
        display: flex;
    color: #666;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
    div{
      margin-right: 30px;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
  .box {
    height: 260px;
    border: 1px #009bbd solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .btn-box {
    display: flex;
    align-items: center;
    .back-btn {
      color: white;
      border: 2px #009bbd solid;
      background-color: #009bbd;
      width: 150px;
      cursor: pointer;
      height: auto;
      line-height: 22px;
      padding: 5px;
      &:hover {
        background-color: transparent;
        color: #015597;
        border: 2px #015597 solid;
        font-weight: bold;
      }
    }
    i {
      font-size: 22px;
      color: #858585;
      margin-left: 10px;
      cursor: help;
      font-weight: normal;
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tips {
  margin-top: 20px;
  font-size: 16px;
  span {
    color: #de1a5b;
  }
}
.mt20 {
  margin-top: 20px !important;
}
.icon-chakan {
  right: -40px;
  position: absolute;
  font-size: 24px;
}
.preview {
  color: #489bbd;
  right: -40px;
  position: absolute;
  text-decoration: underline;
}
.filePre {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000004d;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 90%;
    max-height: 90%;
  }
  .cl-pdf {
    width: 100%;
    max-width: 800px;
    max-height: 100%;
    overflow: scroll;
    span{
      width: 100%;
    }
  }
}
.back-tips {
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 35px;
  padding-right: 16px;
}
/* fix 样式无效，不知如何修改弹框按钮颜色 */
/deep/ .ant-btn-primary {
  background-color: #009bbd;
  border-color: #009bbd;
  &:hover {
    background-color: #009bbd;
    border-color: #009bbd;
  }
}
</style>