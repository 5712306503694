import axios from 'axios'
import router from '@/router'
import store from '../store/index.js'

let instance = axios.create({
    baseURL: '/app',
    timeout: 30000
});

axios.withCredentials = true;
axios.defaults.withCredentials = true;

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
instance.adornUrl = (actionName) => {
    // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
    // return 'http://192.168.3.3:8080/app/' + actionName
    return 'https://www.kernespring.xyz/app/' + actionName
    // return 'http://localhost:8080/app/' + actionName
    // return 'https://tust.nottingham.edu.cn/app/' + actionName
}

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // config => {
        // 判断是否存在token，如果存在的话，则每个http header都加上token
        let token = store.state.token;
        // console.log(token)
        // console.log(config.url)
        if (token) {
            config.headers.token = token;
        }
        if (config.method == 'get') {
            config.params = {
                t: Date.parse(new Date()) / 1000,
                ...config.params
            }
        }
    // }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    // console.log(router.history.current.fullPath)
    // console.log(response.data.code)
    // console.log(response.config.url)
    if((router.history.current.fullPath !== '/login' && router.history.current.fullPath !== '/mlogin') && response.data.code == 401){
        router.push("/login");
        return
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default instance
