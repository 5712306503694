<template>
  <div class="content-div">
    <div class="content">
      <div class="left-box">
        <div class="input-item">
          <div class="label">姓名</div>
          <div
            class="input-box"
            :class="[verification['name'] ? 'warning' : '']"
          >
            <input
              type="text"
              v-model="name"
              @click="resetVerification('name')"
              @input="autoPinyin"
              :disabled="applyStatus != 1"
            />
            <div class="verification-tips" v-if="verification['name']">
              请输入姓名
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">拼音</div>
          <div
            class="input-box"
            :class="[verification['pinyin'] ? 'warning' : '']"
          >
            <input
              type="text"
              v-model="pinyin"
              @click="resetVerification('pinyin')"
              :disabled="applyStatus != 1"
            />
            <div class="verification-tips" v-if="verification['pinyin']">
              请输入姓名拼音
            </div>
          </div>
          <a-tooltip placement="right">
            <template slot="title">
              <span>
                例如：<br />
                吕一 Lyu Yi<br />
                王小二 Wang Xiaoer
              </span>
            </template>
            <i class="iconfont icon-wenhao"></i>
          </a-tooltip>
        </div>
        <div class="input-item">
          <div class="label">性别</div>
          <div
            class="input-box"
            :class="[verification['sex'] ? 'warning' : '']"
          >
            <a-select
              placeholder="请选择"
              @focus="resetVerification('sex')"
              @change="setSex"
              v-model="sex"
              :disabled="applyStatus != 1"
            >
              <a-select-option value="男"> 男 </a-select-option>
              <a-select-option value="女"> 女 </a-select-option>
            </a-select>
            <div class="verification-tips" v-if="verification['sex']">
              请选择性别
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">出生年月日</div>
          <div
            class="input-box"
            :class="[verification['birthday'] ? 'warning' : '']"
          >
            <input
              type="text"
              v-model="birthday"
              disabled
            />
            <!-- <a-date-picker
              placeholder="请选择日期"
              :showToday="false"
              :locale="{ zh_CN }"
              @openChange="resetVerification('birthday')"
              @change="setBirthday"
              v-model="birthday"
              disabled
            /> -->
            <div class="verification-tips" v-if="verification['birthday']">
              请选择出生年月日
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">民族</div>
          <div
            class="input-box"
            :class="[verification['nation'] ? 'warning' : '']"
          >
            <a-select
              show-search
              placeholder="请选择"
              :locale="{ zh_CN }"
              option-filter-prop="children"
              :filter-option="filterOption"
              @focus="resetVerification('nation')"
              @change="setNation"
              @blur="nationBlur"
              @search="nationSearch"
              v-model="nation"
              :disabled="applyStatus != 1"
            >
              <a-select-option
                v-for="item in nations"
                :key="item"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
            <div class="verification-tips" v-if="verification['nation']">
              请选择民族
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">政治面貌</div>
          <div
            class="input-box"
            :class="[verification['politics'] ? 'warning' : '']"
          >
            <a-select
              placeholder="请选择"
              @focus="resetVerification('politics')"
              @change="setPolitics"
              v-model="politics"
              :disabled="applyStatus != 1"
            >
              <a-select-option
                v-for="item in politicsList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.content }}
              </a-select-option>
            </a-select>
            <div class="verification-tips" v-if="verification['politics']">
              请选择政治面貌
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">考生类型</div>
          <div
            class="input-box"
            :class="[verification['candidatesType'] ? 'warning' : '']"
          >
            <a-select
              placeholder="请选择"
              @focus="resetVerification('candidatesType')"
              @change="setCandidatesType"
              v-model="candidatesType"
              :disabled="applyStatus != 1"
            >
              <a-select-option
                v-for="item in candidatesTypeList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.content }}
              </a-select-option>
            </a-select>
            <div
              class="verification-tips"
              v-if="verification['candidatesType']"
            >
              请选择考生类型
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">外语</div>
          <div
            class="input-box"
            :class="[verification['foreignLanguages'] ? 'warning' : '']"
          >
            <a-select
              placeholder="请选择"
              @focus="resetVerification('foreignLanguages')"
              @change="setForeignLanguages"
              v-model="foreignLanguages"
              :disabled="applyStatus != 1"
            >
              <a-select-option
                v-for="item in foreignLanguagesList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.content }}
              </a-select-option>
            </a-select>
            <div
              class="verification-tips"
              v-if="verification['foreignLanguages']"
            >
              请选择外语类型
            </div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <div class="img-box" @click="uploadImg()" :class="[verification['pic'] ? 'warning' : '']">
          <img :src="picData" v-show="picData" />
          <div v-show="!picData" class="pic-text">
            <i class="iconfont icon-tianjia2"></i>
            点击上传考生照片
          </div>
          <div class="verification-tips" v-if="verification['pic']">
            请上传证件照
          </div>
        </div>
        <input
          type="file"
          style="display: none"
          accept="image/*"
          ref="pic"
          @change="getPic"
        />
        <div class="img-tips">
          *建议使用一寸证件照（底色不做要求）<br />
          *文件格式：JPG, PNG, JPEG<br />
          *文件最大：5M
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div class="save-btn" v-if="applyTime == 0 && applyStatus == 1">报名已结束</div>
      <div class="save-btn" @click="save" v-if="applyTime != 0 && applyStatus == 1">暂存</div>
      <div v-if="applyStatus != 1" class="apply-done-tip">你已提交申请<br>当前页无法编辑仅供查看</div>
      <div class="next-btn" @click="saveAndNext" v-if="applyTime != 0 || applyStatus != 1">{{applyStatus == 1?'下一步':'下一页'}}</div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import pinyin4js from "pinyin4js";
moment.locale("zh-cn");
let nations = [
  "汉族",
  "蒙古族",
  "回族",
  "藏族",
  "维吾尔族",
  "苗族",
  "彝族",
  "壮族",
  "布依族",
  "朝鲜族",
  "满族",
  "侗族",
  "瑶族",
  "白族",
  "土家族",
  "哈尼族",
  "哈萨克族",
  "傣族",
  "黎族",
  "傈僳族",
  "佤族",
  "畲族",
  "高山族",
  "拉祜族",
  "水族",
  "东乡族",
  "纳西族",
  "景颇族",
  "柯尔克孜族",
  "土族",
  "达斡尔族",
  "仫佬族",
  "羌族",
  "布朗族",
  "撒拉族",
  "毛南族",
  "仡佬族",
  "锡伯族",
  "阿昌族",
  "普米族",
  "塔吉克族",
  "怒族",
  "乌孜别克族",
  "俄罗斯族",
  "鄂温克族",
  "德昂族",
  "保安族",
  "裕固族",
  "京族",
  "塔塔尔族",
  "独龙族",
  "鄂伦春族",
  "赫哲族",
  "门巴族",
  "珞巴族",
  "基诺族",
];

export default {
  data() {
    return {
      applyTime:this.$store.state.applyTime,
      zh_CN,
      name: "",
      verification: {
        name: false,
        pinyin: false,
        sex: false,
        birthday: false,
        politics: false,
        candidatesType: false,
        foreignLanguages: false,
        nation: false,
        pic: false
      },
      pinyin: "",
      sex: "",
      birthday: "",
      nations,
      nation: "",
      politicsList:[],
      politics: "",
      candidatesTypeList:[],
      candidatesType: "",
      foreignLanguagesList:[],
      foreignLanguages: "",
      picStatus: false,
      picData: "",
      applyStatus:1,
      infoStatus:0,
      loading: false
    };
  },
  created(){
    this.getContentConfigList();
    this.getCandidatesTypeList();
    this.getForeignLanguagesList();
    this.getExistApply();
    this.getApplyStatus();
  },
  methods: {
    saveData(verify = false) {
      let warning = false;
      let params = {};
      (this.name == "" || this.name == null)
        ? ((this.verification["name"] = verify?true:false), (warning = true))
        : (params['realName'] = this.name);
      (this.pinyin == "" || this.pinyin == null)
        ? ((this.verification["pinyin"] = verify?true:false), (warning = true))
        : (params['namePinyin'] = this.pinyin);
      (this.sex == "" || this.sex == null)
        ? ((this.verification["sex"] = verify?true:false), (warning = true))
        : (params['sex'] = this.sex);
      // (this.birthday == "" || this.birthday == null)
      //   ? ((this.verification["birthday"] = verify?true:false), (warning = true))
      //   : (params['birthDate'] = this.birthday+' 00:00:00');
      // if(params['birthDate'].indexOf('00:00:00 00:00:00') > -1){
      //   params['birthDate'] = params['birthDate'].substring(0,19)
      // }
      (this.nation == "" || this.nation == null)
        ? ((this.verification["nation"] = verify?true:false), (warning = true))
        : (params['nation'] = this.nation);
      (this.politics == "" || this.politics == null)
        ? ((this.verification["politics"] = verify?true:false), (warning = true))
        : (params['politicsFace'] = this.politics);
      (this.candidatesType == "" || this.candidatesType == null)
        ? ((this.verification["candidatesType"] = verify?true:false), (warning = true))
        : (params['candidateType'] = this.candidatesType);
      (this.foreignLanguages == "" || this.foreignLanguages == null)
        ? ((this.verification["foreignLanguages"] = verify?true:false), (warning = true))
        : (params['foreigLanguages'] = this.foreignLanguages);
      
      // 是否需要上传头像
      var uploadAvatar = false
      if(this.picData == "" || this.picData == null){
        this.verification["pic"] = verify?true:false;
        warning = true;
      }else if(this.$refs.pic.files[0]){
        uploadAvatar = true;
        // setTimeout(function(){
          // this.uploadStuImg();
        // },100)
      }
      if(verify && this.infoStatus < 2){
        params['infoStatus'] = 2
        this.infoStatus = 2
      }
      if (verify && warning) {
        this.$message.warning("请填写完整");
        return;
      }
      // console.log(params);
      let _that = this;
      _that.loading = true;
      this.$api
          .saveTmpApply(params)
          .then((res) => {
            if (res.data.code == 0) {
              this.$store.commit("setName", this.name);
              // 如果是点的下一步，即需验证的，则跳至下一步
              if(uploadAvatar === true){
                this.uploadStuImg().then(()=>{
                  if(verify == true){
                    this.$router.push("/contactinfo");
                  }
                });
              }else if(verify == true){
                this.$router.push("/contactinfo");
              }
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
            _that.loading = false;
          })
          .catch((err) => {
            _that.loading = false;
            console.log(err);
          });
    },
    save() {
      this.saveData();
    },
    saveAndNext() {
      if(this.applyStatus == 1){
        this.saveData(true);
      }else{
        this.$router.push("/contactinfo");
      }
    },
    resetVerification(key) {
      // console.log(key);
      this.verification[key] = false;
    },
    autoPinyin() {
      // 输入姓名时自动生成拼音
      this.pinyin = ''
      var autoPinyin = pinyin4js.convertToPinyinString(this.name, '_', pinyin4js.WITHOUT_TONE).split("_");
      for (var i in autoPinyin) {
        if (i === '1') {
          this.pinyin += ' '
        }
        if (i < 2) {
          var pinyin = autoPinyin[i].replace(autoPinyin[i][0], autoPinyin[i][0].toUpperCase());
          if (pinyin === 'Lv') {
            pinyin = 'Lyu'
          }
          this.pinyin += pinyin
        } else {
          this.pinyin += autoPinyin[i]
        }
      }
    },
    setSex(value) {
      this.sex = value;
    },
    setBirthday(date,dateString) {
      this.birthday = dateString;
    },
    setNation(value) {
      this.nation = value;
    },
    nationBlur(value) {
      this.nation = value;
    },
    nationSearch(value) {
      this.nation = value;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    setPolitics(value) {
      this.politics = value;
    },
    setCandidatesType(value) {
      this.candidatesType = value;
    },
    setForeignLanguages(value) {
      this.foreignLanguages = value;
    },
    uploadImg() {
      // if(this.picStatus){
      // this.picStatus = false;
      // }else{
      // this.picStatus = true;
      if(this.applyStatus == 1){
        // console.log(111)
        this.$refs.pic.click()
        // console.log(11221)
        this.resetVerification('pic');
        // console.log(3333)
      }
      // }
    },
    getPic() {
      // console.log("成功");
      // console.log(this.$refs.pic.files);
      // console.log(this.$refs.pic.files[0].name)

      var picName=this.$refs.pic.files[0].name;
      var suffix = picName.split('.');
      if(suffix[suffix.length-1].toLowerCase() != 'jpg' && suffix[suffix.length-1].toLowerCase() != 'png' && suffix[suffix.length-1].toLowerCase() != 'jpeg'){
          this.$message.error('上传文件格式有误，请重新上传！');
          this.$refs.pic.value = ''
          this.picData = ''
          return;   
      }
      var patrn=/[`~!@#$%^&*()+<>?:"{},\\/;、《》【】！？—（），。；‘“’”\s'[\]]/im;
      if(patrn.test(picName)){
          this.$message.error('您上传的文件名含有非法字符，请重新上传！');
          this.$refs.pic.value = ''
          this.picData = ''
          return;     
      }
      if(this.$refs.pic.files[0].size <= 0){
          this.$message.error('上传文件不能为空，请重新上传！');
          this.$refs.pic.value = ''
          this.picData = ''
          return;
      }
      if(this.$refs.pic.files[0].size/1024 > 5000){
          this.$message.error('上传文件不能大于5M，请重新上传！');
          this.$refs.pic.value = ''
          this.picData = ''
          return;
      }

      let reader = new FileReader();
      let _that = this;
      reader.readAsDataURL(this.$refs.pic.files[0]);
      reader.onload = function () {
        _that.picData = this.result;
      };
    },
    uploadStuImg(){
      return new Promise(resolve=>{
        if(this.$refs.pic.files[0]){
          var formData = new FormData();
          formData.append('file',this.$refs.pic.files[0]);
          console.log(formData)
          this.$api
              .uploadStuImg(formData)
              .then((res) => {
                // console.log(res);
                if(res.data.code != 0){
                  this.$message.error(res.data.msg);
                }
                resolve(res);
              })
              .catch((err) => {
                console.log(err);
                resolve(err);
              });
        }
      })
    },
    getContentConfigList(){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:'政治面貌'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              for(let item in res.data.configList){
                this.politicsList.push({"id":res.data.configList[item].content,"content":res.data.configList[item].content})
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getCandidatesTypeList(){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:'考生类型'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              for(let item in res.data.configList){
                this.candidatesTypeList.push({"id":res.data.configList[item].content,"content":res.data.configList[item].content})
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getForeignLanguagesList(){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:'外语'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              for(let item in res.data.configList){
                this.foreignLanguagesList.push({"id":res.data.configList[item].content,"content":res.data.configList[item].content})
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getExistApply(){
      this.loading = true;
      this.$api
          .getExistApply()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.applyStatus = res.data.exixtApply.status;
              this.name = res.data.exixtApply.realName;
              if (null !== res.data.exixtApply.namePinyin) {
                this.pinyin = res.data.exixtApply.namePinyin;
              } else {
                this.autoPinyin()
              }
              if (null !== res.data.exixtApply.sex) {
                this.sex = res.data.exixtApply.sex;
              } else if (null !== res.data.exixtApply.cardNo) {
                var cardNo = res.data.exixtApply.cardNo;
                this.sex = cardNo.substring(cardNo.length - 2, cardNo.length - 1) % 2 === 1 ? '男' : '女';
              }
              this.birthday = res.data.exixtApply.birthDate.substring(0, 10);
              this.nation = res.data.exixtApply.nation;
              this.politics = res.data.exixtApply.politicsFace;
              this.candidatesType = res.data.exixtApply.candidateType;
              this.foreignLanguages = res.data.exixtApply.foreigLanguages;
              this.picData = res.data.exixtApply.imgUrl;
              this.infoStatus = res.data.exixtApply.infoStatus?res.data.exixtApply.infoStatus:0;
            }else{
              // this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getApplyStatus(){
      this.$api
          .getApplyStatus()
          .then((res) => {
            console.log(res);
            // if (res.data.code == 0) {
            // }else{
            //   // this.$message.error(res.data.msg);
            // }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  },
};
</script>

<style lang='scss' scoped>
.content-div {
  height: calc(100%);
  overflow-y: scroll;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  margin-top: 100px;
  display: flex;
  .left-box {
    .input-item {
      display: flex;
      margin: 20px;
      height: 36px;
      font-size: 16px;
      align-items: center;
      position: relative;
      .label {
        font-size: 18px;
        width: 150px;
        text-align: right;
        margin-right: 10px;
      }
      .input-box {
        border: 1px #ccc solid;
        width: 300px;
        height: 100%;
        display: flex;
        align-items: center;
        input {
          outline: none;
          border: 0;
          height: 100%;
          padding: 0 10px;
          width: 100%;
        }
        .select-placeholder {
          font-size: 16px;
          color: #999;
          padding-left: 10px;
          width: 100%;
          cursor: pointer;
        }
        &.warning {
          border-color: #ff5722;
        }
        .verification-tips {
          position: absolute;
          bottom: -20px;
          font-size: 14px;
          color: #ff5722;
          padding-left: 10px;
        }
        /deep/ .ant-calendar-picker-input {
          font-size: 16px;
        }
      }
      .icon-wenhao {
        font-size: 22px;
        color: #858585;
        margin-left: 10px;
        cursor: help;
      }
      .select-box {
        position: absolute;
        width: calc(100% - 192px);
        left: 160px;
        text-align: center;
        background-color: #ffffff;
        top: 36px;
        z-index: 1;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        cursor: pointer;
        line-height: 36px;
        font-size: 16px;
        .option {
          &:hover {
            background-color: rgba(0, 155, 189, 0.2);
          }
        }
      }
    }
  }
  .right-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 150px;
    .img-box {
      // background-color: #e8e8e8;
      background-color: #e8ecee;
      height: 280px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      color: #6e6e84;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      &.warning {
        border: 1px #ff5722 solid;
      }
      .pic-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        i {
          font-size: 50px;
          color: #c8cfd4;
        }
      }
      img{
        width: 100%;
        height: 100%;
      }
      .verification-tips{
        position: absolute;
        bottom: 0px;
        color: #ff5722;
      }
    }
    .img-tips {
      font-size: 16px;
      line-height: 25px;
      margin-top: 15px;
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
/deep/ .ant-select {
  width: 100%;
  font-size: 16px;
  .ant-select-selection {
    border: 0;
    box-shadow: none;
  }
}
/deep/ .ant-select-dropdown-menu-item {
  font-size: 16px;
}
/deep/ .ant-calendar-picker {
  width: 100%;
  .ant-calendar-picker-input.ant-input {
    border: 0;
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply-done-tip{
  font-size: 14px;
  color: #999;
  margin-right: 20px;
}
</style>