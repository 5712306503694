<template>
  <div class="body-box">
    <div class="header">
      <div class="step-name" @click="changeTab('/userinfo')">基本信息</div>
      <div class="tab-box" v-if="tab === '/userinfo'">
        <div :class="[path === '/userinfo' ? 'active' : '']" @click="changeTab('/userinfo')">考生情况</div>
        <div :class="[path === '/contactinfo' ? 'active' : '']" @click="changeTab('/contactinfo')">联系方式</div>
        <div :class="[path === '/parentsinfo' ? 'active' : '']" @click="changeTab('/parentsinfo')">父母信息</div>
        <div :class="[path === '/highschoolinfo' ? 'active' : '']" @click="changeTab('/highschoolinfo')">高中信息</div>
        <div :class="[path === '/highschoolscore' ? 'active' : '']" @click="changeTab('/highschoolscore')">高中成绩</div>
      </div>
      <div class="step-name" @click="changeTab('/intentionmajor')">报考意向</div>
      <div class="tab-box" v-if="tab === '/intentionmajor'">
        <div :class="[path === '/intentionmajor' ? 'active' : '']" @click="changeTab('/intentionmajor')">专业选择</div>
      </div>
      <div class="step-name" @click="changeTab('/infoconfirmation')">申请表提交</div>
      <div class="tab-box" v-if="tab === '/infoconfirmation'">
        <div :class="[path === '/infoconfirmation' ? 'active' : '']" @click="changeTab('/infoconfirmation')">信息确认</div>
        <div :class="[path === '/commitment' ? 'active' : '']" @click="changeTab('/commitment')">考生诚信承诺书</div>
        <div :class="[path === '/aplyform' ? 'active' : '']" @click="changeTab('/aplyform')">下载/打印</div>
      </div>
      <div class="step-name" @click="changeTab('/uploadmaterial')">材料上传</div>
      <div class="tab-box" v-if="tab === '/uploadmaterial'">
        <div :class="[path === '/uploadmaterial' ? 'active' : '']" @click="changeTab('/uploadmaterial')">身份证正反面/已盖章签字的申请表</div>
      </div>
      <div class="tips-text" v-if="tips != ''">{{tips}}</div>
    </div>
    <UserInfo v-if="path == '/userinfo'"></UserInfo>
    <ContactInfo v-if="path == '/contactinfo'"></ContactInfo>
    <Parentsinfo v-if="path == '/parentsinfo'"></Parentsinfo>
    <Highschoolinfo v-if="path == '/highschoolinfo'"></Highschoolinfo>
    <Highschoolscore v-if="path == '/highschoolscore'"></Highschoolscore>
    <Intentionmajor v-if="path == '/intentionmajor'"></Intentionmajor>
    <Infoconfirmation v-if="path == '/infoconfirmation'"></Infoconfirmation>
    <Commitment v-if="path == '/commitment'"></Commitment>
    <Applyform v-if="path == '/aplyform'"></Applyform>
    <Uploadmaterial v-if="path == '/uploadmaterial'"></Uploadmaterial>
  </div>
</template>

<script>

import UserInfo from './Userinfo.vue';
import ContactInfo from './Contactinfo.vue';
import Parentsinfo from './Parentsinfo.vue';
import Highschoolinfo from './Highschoolinfo.vue';
import Highschoolscore from './Highschoolscore.vue';
import Intentionmajor from './Intentionmajor.vue';
import Infoconfirmation from './Infoconfirmation.vue';
import Commitment from './Commitment.vue';
import Applyform from './Applyform.vue';
import Uploadmaterial from './Uploadmaterial.vue';

export default {
  data() {
    return {
      path:"",
      tab:"",
      tips:""
    };
  },
  created() {
    if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
      this.$router.push("mindex")
      return
    }
    // if(document.body.clientWidth <= 768){
    //    this.$router.push("mindex");
    //    return
    // }
    this.path = this.$route.path;
    switch(this.path){
      case '/userinfo':
      case '/contactinfo':
      case '/highschoolinfo':
      case '/highschoolscore':
        this.tab = '/userinfo';
        this.tips = '本页内容均为必填项';
        break;
      case '/parentsinfo':
        this.tab = '/userinfo';
        this.tips = '* 内容均为必填项';
        break;
      case '/intentionmajor':
        this.tab = '/intentionmajor';
        break;
      case '/infoconfirmation':
      case '/commitment':
      case '/aplyform':
        this.tab = '/infoconfirmation';
        break;
      case '/uploadmaterial':
        this.tab = '/uploadmaterial';
        break;
    }
    console.log(this.path, this.tab);
  },
  methods: {
    changeTab(path) {
      if (this.path !== path) {
        this.$api
          .getInfoStatus()
          .then((res) => {
            console.log(res.data.infoStatus);
            if(path == '/contactinfo' && res.data.infoStatus < 2){
              this.$message.warning('请先完成考生情况的填写');
              return
            }
            if(path == '/parentsinfo' && res.data.infoStatus < 3){
              this.$message.warning('请先完成前页内容的填写');
              return
            }
            if(path == '/highschoolinfo' && res.data.infoStatus < 4){
              this.$message.warning('请先完成前页内容的填写');
              return
            }
            if(path == '/highschoolscore' && res.data.infoStatus < 5){
              this.$message.warning('请先完成前页内容的填写');
              return
            }
            if(path == '/intentionmajor' && res.data.infoStatus < 6){
              this.$message.warning('请先完成基本信息的填写');
              return
            }
            if(path == '/infoconfirmation' && res.data.infoStatus < 7){
              this.$message.warning('请先完成前页内容的填写');
              return
            }
            if((path == '/commitment' || path == '/aplyform' || path == '/uploadmaterial') && res.data.infoStatus < 8){
              this.$message.warning('请先提交申请表');
              return
            }
            this.path = path;
            this.$router.push(path);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  },
  components: {
    UserInfo,
    ContactInfo,
    Parentsinfo,
    Highschoolinfo,
    Highschoolscore,
    Intentionmajor,
    Infoconfirmation,
    Commitment,
    Applyform,
    Uploadmaterial
  }
};
</script>

<style lang="scss" scoped>
.body-box {
  min-height: 100%;
  background-color: white;
  padding: 40px 30px;
  height: 100%;
  min-width: 1080px;
  .header {
    display: flex;
    // border-bottom: 3px #ccc solid;
    border-bottom: 3px #c5cdd2 solid;
    font-weight: bold;
    width: 100%;
    height: 36px;
    position: relative;
    .step-name {
      font-size: 22px;
      padding: 0 10px;
      color: #18194f;
      cursor: pointer;
    }
    .tab-box {
      display: flex;
      font-size: 18px;
      height: 28px;
      margin-top: 5px;
      div {
        padding: 0 10px;
        // background-color: #e8e8e8;
        background-color: #e8ecee;
        margin: 0 10px;
        line-height: 30px;
        cursor: pointer;
        &.active {
          background-color: #de1b5c;
          color: white;
          cursor: default;
        }
      }
    }
    .tips-text {
      position: absolute;
      top: 36px;
      padding: 15px;
      font-size: 16px;
      left: 0;
      background-color: white;
      z-index: 1;
    }
  }
  
  // .select-fade-enter-active {
  //   animation: fadein-down-in 0.3s;
  //   transform-origin: top;
  // }
  // .select-fade-leave-active {
  //   animation: fadein-down-in 0.3s reverse;
  //   transform-origin: top;
  // }
  // @keyframes fadein-down-in {
  //   0% {
  //     transform: scaleY(0.9);
  //     opacity: 0;
  //   }
  //   100% {
  //     transform: scaleY(1);
  //     opacity: 1;
  //   }
  // }
}
</style>
