<template>
  <div>
    <div class="header">
	    <img src="../../assets/whitetext.png" style="height: 100%;padding-bottom: 10px;" />
	    <div class="noticeclass">
		    <div class="c-ml-30 sf-wrap">
			    <a-popover title="" v-model="popoverVisible">
				    <template #content>
					    <a-tabs centered>
						    <a-tab-pane key="1" :tab="'通知(' + unReadMsgNumb + ')'">
							    <div v-if="noticeList" class="notice-wrap">
								    <div v-for="(item, index) in noticeList" :key="index" class="c-flex c-flex-align-center c-flex-just-between c-mb-10" @click="openDetailForNotice(item.id, item.noticeId)">
									    <a :style="`color: ` + (item.isRead === '0' ? '#000000' : '#a0a0a1')"  class="notice-title primary-color"><span :style="`background-color: ` + (item.isRead === '0' ? '#FF0000' : '#a0a0a1') + ';margin-right:5px'" class="dot"></span> {{ transTitle(item.title) }}</a>
									    <a style="width: 60px" class="notice-action">去查看</a>
								    </div>
							    </div>
						    </a-tab-pane>
					    </a-tabs>
				    </template>
				    <div>
					    <a-badge :count="unReadMsgNumb" dot>
						    <svg role="img" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" aria-labelledby="envelopeAltIconTitle" stroke="#ffffff" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#2329D6"> <title id="envelopeAltIconTitle">Envelope</title> <rect width="20" height="14" x="2" y="5"/> <path stroke-linecap="round" d="M2 5l10 9 10-9"/> 啊啊啊</svg>
					    </a-badge>
				    </div>
			    </a-popover>
		    </div>
	    </div>
      <div @click="logout" style="color: white;line-height: 50px;margin-right: 10px;">退出登录</div>
    </div>
    <div class="content" ref="content" :style="'height:'+(height-170)+'px'">
      <div class="welcome-text"><div>{{name}}，欢迎报考宁波诺丁汉大学</div></div>
      <div class="function-box">
        <div class="dark" @click="goPage('mprogress')">报考进度</div>
        <div class="light" @click="goPage('mapply')">申请表</div>
        <div class="dark" @click="goPage('mresult')">初审结果查询</div>
        <div class="light" @click="goPage('mpay')">缴费状态</div>
        <div class="dark" @click="goPage('madmission')">准考证预览</div>
        <div class="light" @click="goPage('mscore')">成绩查询</div>
      </div>
      <div class="info-box">
        <div class="left-box">
          <div class="item">
            <div class="title">公告栏</div>
            <div style="max-height: 90px;overflow-y: scroll;">
              <div
                class="notice-item"
                v-for="item in article"
                :key="item.id"
                @click="openDetail('article', item.id)"
              >
                <span class="label" v-if="item.isTop == '1'">[置顶]</span>
                <span class="label" v-if="item.isHot == '1'">[热门]</span>
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
          <div class="item" style="margin-top: 10px;">
            <div class="title">系统通知</div>
            <div style="max-height: 68px;overflow-y: scroll;">
              <div
                class="notice-item"
              >
<!--                <span class="label">[最新]</span>-->
                <span>{{ noticeTitle }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="countdown-item" v-if="applyTime != 0">
            <div>距离报名结束<br />还剩</div>
            <div class="day">{{ applyTime }}</div>
            <div>天</div>
          </div>
          <div class="countdown-item" v-if="applyTime == 0">
            <div style="position: relative;top: 40%;">报名已结束</div>
          </div>
          <div class="countdown-item" v-if="testTime != 0">
            <div>距离综合测试<br />还剩</div>
            <div class="day">{{ testTime }}</div>
            <div>天</div>
          </div>
          <div class="countdown-item" v-if="testTime == 0">
            <div style="position: relative;top: 40%;">已过测试时间</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div>招生热线：0574-88180182</div>
        <div>学校地址：浙江省宁波市泰康东路199号</div>
        <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
        </div>
        <div>招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
            >Gaokao@nottingham.edu.cn</a
          >
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../../assets/qr.png" style="height: 100%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="pop-layer" v-if="articleLayer">
      <div class="box">
        <div class="title">
          <div>公告</div>
          <div>
            <i
              data-v-8b6adaf6=""
              class="iconfont icon-guanbi1"
              @click="closeDetail"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="addtion">
            来源：{{ articleInfo.author }}<br>发布日期：{{
              articleInfo.publishDate
            }}<br>
            浏览次数：{{ articleInfo.viewer }}
          </div>
          <div class="content-box">
            <div class="content-title">{{ articleInfo.title }}</div>
            <div v-html="articleInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-layer notice-layer" v-if="noticeLayer" @click="closeDetail">
      <div class="box notice-box">
        <div class="title">
          <div>通知</div>
          <div>
            <i
              data-v-8b6adaf6=""
              class="iconfont icon-guanbi1"
              @click="closeDetail"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="addtion">通知时间：{{ noticeInfo.createDatetime }}</div>
          <div class="content-box">
            <div v-html="noticeInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-layer notice-layer" v-if="showNotice" @click="closeDetailForNotice">
      <div class="box notice-box" :style="scrollY?'overflow-x:scroll':''">
        <div class="title">
          <div>通知</div>
          <div>
            <i
                data-v-8b6adaf6=""
                class="iconfont icon-guanbi1"
                @click="closeDetailForNotice"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="addtion">通知时间：{{ noticeInfo.createDatetime }}</div>
          <div class="content-box">
            <div class="content-title">{{ noticeInfo.title }}</div>
            <div v-html="noticeInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo:{
      meta:[
          { name: 'referrer',content:'no-referrer' }
      ]
  },
  data() {
    return {
      popoverVisible: false,
      unReadMsgNumb: 0,
      scrollY:false,
      showNotice: false,
      noticeList: [],
      name: this.$store.state.name,
      applyTime: "-",
      testTime: "-",
      article: [],
      notice: [],
      articleLayer: false,
      noticeLayer: false,
      articleInfo: {},
      noticeInfo: {},
      height: `${document.documentElement.clientHeight}`,
      loading: false,
      progress:{},
      noticeTitle: ''
    };
  },
  created() {
    this.getNoticeList(true);
    this.getProgressMap();
    this.articleList();
    // this.announcementList();
    this.getContentConfigList();
    this.getExistApply();
    this.getNoticeTitle();
    // setTimeout(() => {
    //   this.hh = this.$refs.content.offsetHeight
    //   // var ratio = this.$refs.getheight.offsetHeight/430;
    //   // this.scale = ratio < 1 ?ratio:1
    // }, 50);
    console.log()
  },
  methods: {
    closeDetailForNotice() {
      this.$api
          .readNotice({
            id: this.noticeInfo.id
          })
      this.showNotice = false
      this.getNoticeList(false);
    },
    transTitle(title) {
      if (title && title.length > 20) {
        return title.substring(0,17) + '...'
      }
      return title
    },
    openDetailForNotice(receiverId) {
      this.$api
          .sysNoticeInfo({
            id: receiverId
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.noticeInfo = res.data.sysNoticeInfo;
              this.showNotice = true
              this.popoverVisible = false
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getNoticeList(needJudgeAlways) {
      var that = this
      this.$api
          .getNoticeList()
          .then((res) => {
            if (res.data.code == 0) {
              that.noticeList = res.data.page.list;
              if (that.noticeList) {
                that.unReadMsgNumb = that.noticeList.filter(t => t.isRead === '0').length
              } else {
                that.unReadMsgNumb = 0
              }
              if (needJudgeAlways) {
                // 判断是否需要弹框
                var alwaysList = that.noticeList.filter(t => t.isAlways === '1')
                if (alwaysList.length > 0) {
                  that.openDetailForNotice(alwaysList[0].id)
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getExistApply() {
      this.$api
        .getExistApply()
        .then((res) => {
          if (res.data.code == 0) {
            this.name = res.data.exixtApply.realName;
            if(this.name && this.name != ''){
              this.$store.commit("setName", this.name);
            }
          }
        })
        .catch((err) => {
          // _that.loading = false;
          console.log(err);
        });
    },
    getContentConfigList() {
      this.$api
        .contentConfigList({
          categoryName: "内容配置",
          name: "报名结束时间",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            var s1 = res.data.configList[0].content;
            s1 = new Date(s1.replace(/-/g, "/"));
            var s2 = new Date();
            var days = s1.getTime() - s2.getTime();
            if (days < 0) {
              this.applyTime = 0;
            } else {
              this.applyTime = parseInt(days / (1000 * 60 * 60 * 24));
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api
        .contentConfigList({
          categoryName: "内容配置",
          name: "综合测试时间",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            var s1 = res.data.configList[0].content.substring(0,10);
            s1 = s1.replace('年','-');
            s1 = s1.replace('月','-');
            s1 = new Date(s1.replace(/-/g, "/"));
            var s2 = new Date();
            var days = s1.getTime() - s2.getTime();
            if (days < 0) {
              this.testTime = 0;
            } else {
              this.testTime = parseInt(days / (1000 * 60 * 60 * 24));
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    articleList() {
      this.$api
        .articleList({
          categoryName: "公告栏",
          pageNum: "0",
          pageSize: "10000",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.article = res.data.page.list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    announcementList() {
      this.$api
        .noticeList({
          pageNum: "0",
          pageSize: "10000",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.notice = res.data.page.list;
          } else {
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDetail(type, id) {
      this.loading = true;
      this.articleInfo = {}
      if (type == "article") {
        this.articleLayer = true;
        this.$api
          .articleInfo({
            id: id,
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.articleInfo = res.data.articleInfo;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.$api
          .addViewer({
            id: id,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.noticeLayer = true;
        this.$api
          .noticeInfo({
            id: id,
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.noticeInfo = res.data.noticeInfo;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    closeDetail() {
      this.articleLayer = false;
      this.noticeLayer = false;
    },
    getProgressMap() {
      this.$api
        .getProgressMap()
        .then((res) => {
          // console.log(res);
          if(res.data.result.code_10 == 2){
            this.$router.push("mSystemClose");
            return;
          }
          this.progress = res.data.result
          // console.log(this.progress);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goPage(page){
      if(page === 'mapply'){
        if(this.progress.code_3 == 2) {
          this.$message.warning("现已暂停申请，请等待开启！");
          return;
        }
        this.getApplyStatus('mapply')
        return
      }
      if(page === 'mresult'){
        this.getQueryresultStatus('mresult')
        return
      }
      if(page === 'mpay'){
        if(this.progress.code_6 == 2) {
          this.$message.warning("现已暂停缴费，请等待开启！");
          return;
        }
        this.getPaymentStatus('mpay')
        return
      }
      if(page === 'madmission'){
        if(this.progress.code_7 == 2) {
          this.$message.warning("现已暂停下载准考证，请等待开启！");
          return;
        }
        this.getAdmissionStatus('madmission')
        return
      }
      if(page === 'mscore'){
        if(this.progress.code_8 == 2) {
          this.$message.warning("现已暂停成绩查询，请等待开启！");
          return;
        }
        this.getWriteStatus('mscore')
        return
      }
      this.$router.push(page);
    },
    getApplyStatus(path) {
      this.$api
        .getApplyStatus()
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.applyStatus < 3 && res.data.applyStatus != 2) {
              this.$message.warning("请先提交申请！");
            } else {
              this.$router.push(path);
            }
          } else {
            this.$message.warning("请先提交申请！");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNoticeTitle() {
      this.$api
          .getApplyStatus()
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              if (res.data.applyStatus == 1 || res.data.applyStatus == 3) {
                this.noticeTitle = '您尚未提交宁波诺丁汉大学三位一体报名申请，请尽快完成报名'
              } else if (res.data.applyStatus == 2) {
                this.noticeTitle = '您尚未上传宁波诺丁汉大学三位一体报名材料，请尽快完成上传'
              } else if (res.data.applyStatus == 4) {
                this.noticeTitle = '您已成功报名宁波诺丁汉大学三位一体，请等待{time}初审结果'
                this.getAuditTime()
              } else if (res.data.applyStatus == 6) {
                this.noticeTitle = '您可点击左侧菜单栏查询您的初审结果'
              } else if (res.data.applyStatus == 5 || res.data.applyStatus == 8) {
                this.noticeTitle = '恭喜您已通过宁波诺丁汉大学三位一体初审，请及时点击左侧菜单栏完成缴费'
              } else if (res.data.applyStatus == 7 || res.data.applyStatus == 9) {
                this.noticeTitle = '您已成功缴费，请及时点击左侧菜单栏打印准考证'
              } else {
                this.noticeTitle = '欢迎报考宁波诺丁汉大学'
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getAuditTime(){
      this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'初审时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(0,1) == '0'){
                  timeStr = res.data.configList[item].content.substring(1,3);
                }else{
                  timeStr = res.data.configList[item].content.substring(0,3);
                }
                if(res.data.configList[item].content.substring(3,4) == '0'){
                  timeStr += res.data.configList[item].content.substring(4);
                }else{
                  timeStr += res.data.configList[item].content.substring(3);
                }
                this.noticeTitle = this.noticeTitle.replace('{time}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getQueryresultStatus(path) {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus < 4 && res.data.applyStatus != 2) {
              this.$message.warning("请先提交申请！");
            } else {
              // 检查材料是否齐全
              this.$api
                .getMaterialStatus()
                .then((res) => {
                  // console.log(res);
                  if (res.data.code == 0) {
                    if (res.data.materialStatus == 1) {
                      this.$router.push(path);
                    } else {
                      this.$message.warning("请先完成佐证材料的上传");
                    }
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            this.$message.warning("请先提交申请！");
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPaymentStatus(path) {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus < 4 && res.data.applyStatus != 2) {
              this.$message.warning("请先提交申请！");
            } else if (res.data.applyStatus < 7 && res.data.applyStatus != 5) {
              this.$message.warning("请先通过初审！");
            } else {
              this.$router.push(path);
            }
          } else {
            this.$message.warning("请先提交申请！");
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAdmissionStatus(path) {
      this.$api
        .getExamNoStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.$router.push(path);
          } else {
            this.$message.warning(res.data.msg);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getWriteStatus(path) {
      this.$api
        .getHandleScoreStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.$router.push(path);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logout() {
      this.$store.commit("delUser");
      this.$router.push("mlogin");
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  height: 8px;
  width: 8px;
  background-color: #ff0000;
  border-radius: 50%;
  margin: auto;
  display: inline-block;
}
.header {
  height: 70px;
  background-color: #18194f;
  padding-bottom: 10px;
  display: flex;
	align-items: center;
  justify-content: space-between;
}
.noticeclass {
  //position: absolute;
  // float: right;
  //right: 0;
  color: white;
  //top: 0;
  //line-height: 104px;
  //margin-left: 54px;
  font-size: 20px;
  //display: flex;
  cursor: pointer;
  i:nth-child(1) {
    font-size: 36px;
    margin-right: 10px;
  }
  i:nth-child(2) {
    font-size: 22px;
    margin-left: 10px;
  }
  @keyframes usermenu-fade-in {
    0% {
      transform: scaleY(0.8) translateY(-5px);
      opacity: 0;
    }
    100% {
      transform: scaleY(1) translateY(0px);
      opacity: 1;
    }
  }
}
.content {
  position: relative;
  height: calc(100vh - 170px);
  overflow-y: scroll;
  background-color: #f1f5f7;
  width: 100%;
  padding: 10px;
  .welcome-text {
    padding: 5px;
    div{
      background-color: white;
      width: 100%;
      font-weight: bold;
      padding: 10px;
    }
  }
  .function-box{
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    div{
      width: calc((100% / 3) - 10px);
      font-weight: bold;
      font-size: 12px;
      display: flex;
      height: 60px;
      align-items: center;
      justify-content: center;
      margin: 5px;
      border: 1px #015597 solid;
      &.dark{
        background-color: #009bbd;
        color: white;
      }
      &.light{
        color: #009bbd;
        background-color: white;
      }
    }
  }
  .info-box{
    margin-top: 5px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    .left-box{
      width: calc(100% - 110px);
      .item{
        background-color: white;
        padding-bottom: 5px;
        .title{
          font-weight: bold;
          border-bottom: 1px #ddd solid;
          height: 35px;
          padding-left: 10px;
          line-height: 35px;
        }
        .notice-item{
          margin: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 12px;
          .label{
            color: #de1b5c;
            font-weight: bold;
          }
        }
      }
    }
    .right-box{
      .countdown-item {
        background-color: #005597;
        width: 100px;
        padding: 10px;
        margin-bottom: 10px;
        height: 119px;
        div {
          font-size: 12px;
          color: white;
          text-align: center;
        }
        .day {
          color: #ffc107;
          font-size: 30px;
          font-weight: bold;
        }
      }
    }
  }
}
.footer {
    height: 100px;
    background-color: #18194f;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    align-items: center;
    padding: 10px;
  .text-div {
    font-size: 10px;
    .school-name {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: white;
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      height: 70%;
    }
    .img-text {
      font-size: 10px;
      text-align: center;
      line-height: 12px;
      margin-top: 3px;
    }
  }
}
.pop-layer {
  position: fixed;
  height: 100%;
  width: 96%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  padding: 15px;
  &.notice-layer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    background-color: white;
    width: 100%;
    max-height: 100%;
    border-radius: 5px;
    &.notice-box {
      padding-bottom: 10px;
      max-height: 80vh;
      overflow: scroll;
    }
    .title {
      display: flex;
      height: 45px;
      border-bottom: 1px #ddd solid;
      line-height: 45px;
      font-size: 16px;
      padding: 0 20px;
      justify-content: space-between;
      i {
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: #f44336;
          font-weight: bold;
        }
      }
    }
    .content {
      max-height: 80vh;
      padding: 10px;
      overflow-y: scroll;
      height: calc(100% - 45px);
      border-radius: 0 0 5px 5px;
      .content-title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .addtion {
        color: #858585;
        text-align: right;
        margin-bottom: 30px;
      }
      .content-box {
        display: block;
        width: auto;
        min-width: auto;
        /deep/ img{
          max-width: 100%;
          height: auto;
        }
        /deep/ iframe{
          max-width: 100%;
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }
      /*竖向滚动条*/
      &::-webkit-scrollbar-thumb:vertical {
        height: 5px;
        background-color: #ddd;
        -webkit-border-radius: 0px;
      }
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notice-wrap {
  padding: 0 12px;
  .notice-title {
    margin-top: 10px;
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.primary-color {
      color: #000000;
    }
  }
  .notice-action {
    margin-left: 20px;
    margin-right: 10px;
    color: #009bbd
  }
}
.c-flex {
  display: flex;
}
.c-flex {
  display: flex;
}

.c-flex-direction-column {
  flex-direction: column;
}

.c-flex-align-baseline {
  align-items: baseline;
}
.c-flex-align-center {
  align-items: center;
}

.c-flex-just-center {
  justify-content: center;
}
.c-flex-just-end {
  justify-content: flex-end;
}
.c-flex-just-between {
  justify-content: space-between;
}
.c-flex-just-around {
  justify-content: space-around;
}
</style>
