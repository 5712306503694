<template>
  <div>
    <div class="header">
      <img src="../assets/whitetext.png" style="height: 100%" />
      <div>
        宁波诺丁汉大学三位一体综合评价招生报名系统<br />
        Tri-Unity Scheme Test Applicant Portal
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="right-box">
          <div style="font-size:36px;text-align: center;margin-top: -145px;margin-bottom: 90px;">系统已关闭！请等待开启。。。。。。</div>
          <div class="notice-box">
            <div class="title">公告栏 Notice Board</div>
            <div class="notice-content">
              <div
                class="item"
                v-for="item in notice"
                :key="item.id"
                @click="openDetail(item.id)"
              >
                <i class="iconfont icon-zheng-sanjiao" style="float:left;color: #005597;"></i>
                <div class="title-box">
                  <span class="top-label" v-if="item.isTop == '1'">[置顶]</span>
                  <span class="top-label" v-if="item.isHot == '1'">[热门]</span>
                  <span class="text">{{ item.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div class="addtional-text">
          <div>招生热线：0574-88180182</div>
          <div>学校地址：浙江省宁波市泰康东路199号</div>
        </div>
        <div class="addtional-text">
          <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
          </div>
          <div>
            招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
              >Gaokao@nottingham.edu.cn</a
            >
          </div>
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../assets/qr.png" style="height: 80%;width: 66%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="pop-layer" v-if="articleLayer">
      <div class="box" :style="scrollY?'overflow-x:scroll':''">
        <div class="title">
          <div>公告</div>
          <div>
            <i
              data-v-8b6adaf6=""
              class="iconfont icon-guanbi1"
              @click="closeDetail"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="addtion">
            来源：{{ articleInfo.author }} 发布日期：{{
              articleInfo.publishDate
            }}
            浏览次数：{{ articleInfo.viewer }}
          </div>
          <div class="content-box">
            <div class="content-title">{{ articleInfo.title }}</div>
            <div v-html="articleInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
      codeImg: "",
      uuid: "",
      idNum: "",
      pwd: "",
      code: "",
      cardNo: "",
      mobile: "",
      password: "",
      password2: "",
      mobileCode: "",
      showResetLayer: false,
      sended: false,
      timer: "",
      seconds: 60,
      notice: [],
      articleLayer: false,
      articleInfo: {},
      registerStatus: 0,
      loginStatus: 0,
      scrollY:false,
      loading: false
    };
  },
  watch: {
    seconds() {
      if (this.seconds <= 0) {
        clearInterval(this.timer);
        this.sended = false;
      }
    },
    mobile(n,o){
      if(n.length>11){
        this.mobile = o;
      }
    }
  },
  created() {
    if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
      this.$router.push("mindex")
      return
    }
    if(document.body.clientWidth <= 650){
      this.scrollY = true
    }else{
      this.scrollY = false
    }
    window.onresize = () => {
      if(document.body.clientWidth <= 650){
        this.scrollY = true
      }else{
        this.scrollY = false
      }
    }
    // this.getCaptcha();
    this.articleList();
    // this.getRegisterStatus();
    // this.getLoginStatus();
    // alert(document.body.clientWidth)
    // if(document.body.clientWidth <= 768){
    //    this.$router.push("mlogin");
    // }
  },
  methods: {
    encrypt (word, keyStr) {
      keyStr = 'wwwkernespringxy'
      var key = CryptoJS.enc.Utf8.parse(keyStr)
      var srcs = CryptoJS.enc.Utf8.parse(word)
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
      return encrypted.toString()
    },
    getRegisterStatus() {
      this.$api
        .getProgressList({
          code: "code_1",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.registerStatus = 409;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLoginStatus() {
      this.$api
        .getProgressList({
          code: "code_2",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.loginStatus = 409;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    register() {
      if (this.registerStatus == 409) {
        this.$message.warning("注册功能已关闭，请等待开启！");
        return;
      }
      this.$router.push("register");
    },
    // 获取验证码
    getCaptcha() {
      this.uuid = this.getUUID();
      this.codeImg = this.$api.getCaptcha() + `?uuid=${this.uuid}`;
    },
    getUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        return (c === "x"
          ? (Math.random() * 16) | 0
          : "r&0x3" | "0x8"
        ).toString(16);
      });
    },
    login() {
      if (this.loginStatus == 409) {
        this.$message.warning("登录功能已关闭，请等待开启！");
        return;
      }
      if (!(this.idNum && this.pwd)) {
        this.$message.warning("输入不能为空");
        return;
      }
      this.$api
        .login({
          cardNo: this.encrypt(this.idNum),
          password: this.encrypt(this.pwd),
          uuid: this.uuid,
          captcha: this.code,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success(res.data.msg);
            this.$store.commit("setUser", res.data.token);
            this.$store.commit("setName", this.idNum);
            this.$router.push("/index");
          } else {
            this.getCaptcha();
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendVerificationCode() {
      if (this.sended) {
        return;
      }
      if (this.mobile) {
        if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        this.sended = true;
        this.seconds = 60;
        this.timer = setInterval(() => {
          this.seconds--;
        }, 1000);
        this.$api
          .sendVerificationCode({
            mobile: this.mobile,
            type:2
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message.success("短信验证码已发送，请注意查收");
            } else {
              clearInterval(this.timer);
              this.sended = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("手机号不能为空");
      }
    },
    resetPwd() {
      if (this.mobile && this.mobileCode && this.password && this.cardNo) {
        // 身份证验证
        if (
          !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            this.cardNo
          )
        ) {
          this.$message.warning("请输入正确身份证号");
          return;
        } else if (this.cardNo.indexOf("x") > -1) {
          this.$message.warning("请输入大写的X字母");
          return;
        }
        // 手机验证
        if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        // 密码验证
        if (this.password.length < 8) {
          this.$message.warning("密码至少含8个字符");
          return;
        }
        if (
          !/^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{8,}$/.test(this.password)
        ) {
          this.$message.warning("密码必须含英文字符和数字");
          return;
        }
        if (this.password != this.password2) {
          this.$message.warning("两次密码输入不一致");
          return;
        }
        this.loading = true;
        this.$api
          .forgetPassword({
            cardNo: this.cardNo,
            code: this.mobileCode,
            mobile: this.mobile,
            password: this.password,
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              location.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("输入不能为空");
      }
    },
    closeReset() {
      this.showResetLayer = false;
    },
    openReset() {
      this.showResetLayer = true;
    },
    articleList() {
      this.$api
        .articleList({
          categoryName: "公告栏",
          pageNum: "0",
          pageSize: "10000",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.notice = res.data.page.list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDetail(id) {
      this.loading = true;
      this.articleInfo = {};
      this.articleLayer = true;
      this.$api
        .articleInfo({
          id: id,
        })
        .then((res) => {
          this.loading = false;
          // console.log(res);
          if (res.data.code == 0) {
            this.articleInfo = res.data.articleInfo;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api
        .addViewer({
          id: id,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeDetail() {
      this.articleLayer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 104px;
  background-color: #18194f;
  padding-bottom: 19px;
  min-width: 1366px;
  div {
    font-size: 24px;
    display: inline-block;
    color: white;
    position: absolute;
    line-height: 30px;
    margin: 22px;
    min-width: 510px;
    margin-left: 70px;
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 201px);
  min-height: 550px;
  min-width: 1366px;
  .content-box {
    display: flex;
    // justify-content: space-between;
    width: 65vw;
    min-width: 1300px;
    justify-content: center;
    margin-top: -100px;
    .title {
      color: white;
      background-color: #009bbd;
      height: 50px;
      font-size: 20px;
      line-height: 50px;
      padding: 0 30px;
    }
    .login-box {
      width: 540px;
      border: 1px #009bbd solid;
      .input-box {
        border: 1px #009bbd solid;
        margin: 30px;
        height: 50px;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        position: relative;
        &:hover {
          border: 2px #009bbd solid;
          padding: 0 9px;
        }
        i {
          font-size: 26px;
          color: #999;
        }
        img {
          position: absolute;
          right: 0;
          height: 100%;
          top: 0;
        }
        input {
          border: 0;
          height: 100%;
          width: 100%;
          margin-left: 10px;
          outline: none;
          &::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #bbb;
          }

          &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #bbb;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #bbb;
          }
        }
      }
      .btn-box {
        display: flex;
        margin: 30px;
        justify-content: space-between;
        div {
          height: 50px;
          border: 1px #009bbd solid;
          text-align: center;
          width: calc(50% - 10px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          transition: all 0.5s;
        }
        span:nth-child(1) {
          font-size: 16px;
          display: block;
          margin-bottom: -5px;
        }
        .login {
          color: white;
          background-color: #009bbd;
          border: 2px #009bbd solid;
          &:hover {
            background-color: transparent;
            color: #015597;
            border: 2px #015597 solid;
            font-weight: bold;
          }
        }
        .forget-password {
          border: 2px #009bbd solid;
          font-weight: bold;
          color: #009bbd;
          &:hover {
            background-color: #de1b5c;
            color: white;
            border: 2px #de1b5c solid;
          }
        }
      }
    }
    .right-box {
      width: calc(100% - 620px);
      .title {
        background-color: #005597;
      }
      .reg-box {
        border: 1px #005597 solid;
        .btn {
          text-align: center;
          height: 50px;
          background-color: #de1b5c;
          margin: 30px;
          line-height: 46px;
          color: white;
          border: 2px #de1b5c solid;
          font-size: 16px;
          transition: all 0.5s;
          cursor: pointer;
          &:hover {
            background-color: transparent;
            color: #005597;
            border: 2px #005597 solid;
            font-weight: bold;
          }
        }
      }
      .notice-box {
        border: 1px #005597 solid;
        margin-top: 20px;
        height: 220px;
        .notice-content {
          font-size: 16px;
          padding: 20px;
          font-weight: bold;
          overflow: scroll;
          height: calc(100% - 50px);
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 6px;
            height: 0px;
          }
          /*竖向滚动条*/
          &::-webkit-scrollbar-thumb:vertical {
            height: 5px;
            background-color: #ddd;
            -webkit-border-radius: 0px;
          }
          .item {
            line-height: 40px;
            cursor: pointer;
            text-overflow: ellipsis;
            // white-space: nowrap;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            .title-box{
              margin-left: 25px;
              line-height: 27px;
              border-bottom: 1px solid #ddd;
              padding-bottom: 5px;
              padding-top: 5px;
            }
            .top-label {
              color: #de1b5c;
              margin-right: 7px;
            }
            .text {
              color: #262626;
              &:hover {
                color: #03a9f4;
              }
            }
          }
        }
      }
    }
  }
}
.footer {
  height: 97px;
  background-color: #18194f;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 18px;
  align-items: center;
  padding: 0 30px;
  min-width: 1366px;
  .text-div {
    display: flex;
    align-items: center;
    height: 100%;
    .school-name {
      font-size: 24px;
      font-weight: bold;
      border-right: 1px white solid;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 30px;
    }
    .addtional-text {
      padding: 0 30px;
      font-size: 16px;
      line-height: 28px;
      a {
        color: white;
      }
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    .img {
      display: flex;
      align-items: center;
    }
    .img-text {
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 16px;
    }
  }
}
.reset-pwd-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 700px;
  .reset-pwd-box {
    border-radius: 5px;
    width: 550px;
    background-color: white;
    position: relative;
    .title {
      font-size: 20px;
      margin: 20px 30px;
    }
    .input-box {
      border: 1px #009bbd solid;
      margin: 30px;
      height: 50px;
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      position: relative;
      &:hover {
        border: 2px #009bbd solid;
        padding: 0 9px;
      }
      i {
        font-size: 26px;
        color: #999;
      }
      input {
        border: 0;
        height: 100%;
        width: 100%;
        margin-left: 10px;
        outline: none;
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #bbb;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #bbb;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #bbb;
        }
      }
    }
    .mobile-code {
      color: white;
      background-color: #009bbd;
      border: 2px #009bbd solid;
      width: 150px;
      position: absolute;
      top: -1px;
      right: -1px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: transparent;
        color: #015597;
        border: 2px #015597 solid;
        font-weight: bold;
      }
      &.ban {
        color: #999;
        background-color: #ddd;
        border: 0;
        border: 1px #999 solid;
        cursor: not-allowed;
      }
    }
    .btn-box {
      display: flex;
      margin: 30px;
      justify-content: space-between;
      div {
        height: 50px;
        border: 1px #009bbd solid;
        text-align: center;
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        transition: all 0.5s;
      }
      span:nth-child(1) {
        font-size: 16px;
        display: block;
        margin-bottom: -5px;
      }
      .login {
        color: white;
        background-color: #009bbd;
        border: 2px #009bbd solid;
        &:hover {
          background-color: transparent;
          color: #015597;
          border: 2px #015597 solid;
          font-weight: bold;
        }
      }
      .forget-password {
        border: 2px #009bbd solid;
        font-weight: bold;
        color: #009bbd;
        &:hover {
          background-color: #de1b5c;
          color: white;
          border: 2px #de1b5c solid;
        }
      }
    }
  }
}
.pop-layer {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  padding: 15px;
  .box {
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    .title {
      display: flex;
      height: 60px;
      border-bottom: 1px #ddd solid;
      line-height: 60px;
      font-size: 18px;
      padding: 0 20px;
      justify-content: space-between;
      min-width: 600px;
      i {
        font-size: 18px;
        cursor: pointer;
        &:hover {
          color: #f44336;
          font-weight: bold;
        }
      }
    }
    .content {
      padding: 50px;
      overflow-y: scroll;
      height: calc(100% - 60px);
      display: block;
      min-width: 600px;
      .content-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .addtion {
        color: #858585;
        text-align: right;
        margin-bottom: 50px;
      }
      .content-box {
        display: block;
        width: auto;
        min-width: auto;
        /deep/ img{
          max-width: 100%;
          height: auto;
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }
      /*竖向滚动条*/
      &::-webkit-scrollbar-thumb:vertical {
        height: 5px;
        background-color: #ddd;
        -webkit-border-radius: 0px;
      }
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
// @media screen and (max-width: 1000px) {
//   .content {
//     display: block;
//     height: unset;
//     padding: 50px 0;
//     .content-box {
//       flex-direction: column;
//       width: unset;
//       align-items: center;
//       .login-box {
//         width: 420px;
//         margin-bottom: 20px;
//       }
//     }
//   }
//   .footer {
//     position: relative;
//   }
// }
// @media screen and (max-width: 740px) {
//   .header div {
//     font-size: 18px;
//   }
// }
// @media screen and (max-width: 490px) {
//   .header {
//     height: unset;
//     padding-bottom: 10px;
//     img {
//       height: 40px !important;
//     }
//     div {
//       font-size: 12px;
//       line-height: 20px;
//       margin: 5px 10px;
//     }
//   }
//   .content {
//     padding: 20px 0;
//     .content-box {
//       .login-box {
//         width: 300px;
//         .title {
//           height: 40px;
//           font-size: 16px;
//           line-height: 40px;
//         }
//         .input-box {
//           height: 40px;
//           font-size: 14px;
//           margin: 20px 30px;
//           img {
//             width: 90px;
//           }
//         }
//         .btn-box {
//           span:nth-child(1) {
//             font-size: 14px;
//           }
//           span:nth-child(2) {
//             font-size: 10px;
//           }
//         }
//       }
//       .right-box {
//         width: 300px;
//         .title {
//           height: 40px;
//           font-size: 16px;
//           line-height: 40px;
//         }
//         .input-box {
//           height: 40px;
//           font-size: 14px;
//           margin: 20px 30px;
//           img {
//             width: 90px;
//           }
//         }
//         .btn-box {
//           span:nth-child(1) {
//             font-size: 14px;
//           }
//           span:nth-child(2) {
//             font-size: 10px;
//           }
//         }
//         .notice-box .notice-content {
//           font-size: 14px;
//           .item {
//             line-height: 30px;
//           }
//         }
//       }
//     }
//   }
//   .footer {
//     .text1 {
//       font-size: 16px;
//     }
//     .text2 {
//       font-size: 14px;
//     }
//   }
// }
</style>
