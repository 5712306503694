import Vue from "vue";
import Vuex from "vuex";
import cookie from "vue-cookies"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token:cookie.get('Authorization') ? cookie.get('Authorization') : '',
    showApplyMenu: false,
    showScoreMenu: false,
    name:cookie.get('name') ? cookie.get('name') : '',
    applyTime:1,
    testTime:0
  },
  mutations: {
    setName (state, val) {
      state.name = val;
      cookie.set("name", val)
    },
    setUser (state, val) {
      state.token = val;
      cookie.set("Authorization", val)
    },
    delUser (state) {
      state.token = '';
      cookie.set("Authorization", '')
    },
    setShowApplyMenu (state, val) {
      state.showApplyMenu = val
    },
    setShowScoreMenu (state, val) {
      state.showScoreMenu = val
    },
    setApplyTime (state, val) {
      state.applyTime = val;
    },
    setTestTime (state, val) {
      state.testTime = val;
    }
  },
  actions: {},
  modules: {}
});
