<template>
  <div class="content-div">
    <div class="content">
      <div class="info-box">
        <div class="title">父亲信息</div>
        <div class="input-item">
          <div class="label">*父亲姓名</div>
          <div class="input-box" :class="[verification['fatherName'] ? 'warning' : '']">
            <input type="text" v-model="fatherName" @click="resetVerification('fatherName')" :disabled="applyStatus != 1"/>
            <div class="verification-tips" v-if="verification['fatherName']">
              请输入父亲姓名
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">*手机号码</div>
          <div class="input-box" :class="[verification['fatherMobilePhone'] ? 'warning' : '']">
            <input type="number" v-model="fatherMobilePhone" @click="resetVerification('fatherMobilePhone')" :disabled="applyStatus != 1"/>
            <div class="verification-tips" v-if="verification['fatherMobilePhone']">
              请输入父亲联系方式
            </div>
          </div>
        </div>
        <!-- <div class="input-item">
          <div class="label">职业</div>
          <div class="input-box">
            <input type="text" v-model="fatherOccupation" :disabled="applyStatus != 1"/>
          </div>
        </div> -->
        <div class="input-item">
          <div class="label">单位</div>
          <div class="input-box">
            <input type="text" v-model="fatherCompany" :disabled="applyStatus != 1"/>
          </div>
        </div>
        <div class="input-item">
          <div class="label">职务</div>
          <div class="input-box">
            <input type="text" v-model="fatherPost" :disabled="applyStatus != 1"/>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="title">母亲信息</div>
        <div class="input-item">
          <div class="label">*母亲姓名</div>
          <div class="input-box" :class="[verification['motherName'] ? 'warning' : '']">
            <input type="text" v-model="motherName" @click="resetVerification('motherName')" :disabled="applyStatus != 1"/>
            <div class="verification-tips" v-if="verification['motherName']">
              请输入母亲姓名
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="label">*手机号码</div>
          <div class="input-box" :class="[verification['motherMobilePhone'] ? 'warning' : '']">
            <input type="number" v-model="motherMobilePhone" @click="resetVerification('motherMobilePhone')" :disabled="applyStatus != 1"/>
            <div class="verification-tips" v-if="verification['motherMobilePhone']">
              请输入母亲联系方式
            </div>
          </div>
        </div>
        <!-- <div class="input-item">
          <div class="label">职业</div>
          <div class="input-box">
            <input type="text" v-model="motherOccupation" :disabled="applyStatus != 1"/>
          </div>
        </div> -->
        <div class="input-item">
          <div class="label">单位</div>
          <div class="input-box">
            <input type="text" v-model="motherCompany" :disabled="applyStatus != 1"/>
          </div>
        </div>
        <div class="input-item">
          <div class="label">职务</div>
          <div class="input-box">
            <input type="text" v-model="motherPost" :disabled="applyStatus != 1"/>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div v-if="applyStatus != 1" class="apply-done-tip">你已提交申请<br>当前页无法编辑仅供查看</div>
      <div class="save-btn" @click="goPrevStep" v-if="applyTime != 0 || applyStatus != 1">{{applyStatus == 1?'上一步':'上一页'}}</div>
      <div class="save-btn" @click="save" v-if="applyTime != 0 && applyStatus == 1">暂存</div>
      <div class="next-btn" @click="saveAndNext" v-if="applyTime != 0 || applyStatus != 1">{{applyStatus == 1?'下一步':'下一页'}}</div>
      <div class="save-btn" v-if="applyTime == 0 && applyStatus == 1">报名已结束</div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applyTime:this.$store.state.applyTime,
      verification: {
        fatherName: false,
        fatherMobilePhone: false,
        motherName: false,
        motherMobilePhone: false
      },
      fatherName:"",
      fatherMobilePhone:"",
      fatherOccupation:"",
      fatherCompany:"",
      fatherPost:"",
      motherName:"",
      motherMobilePhone:"",
      motherOccupation:"",
      motherCompany:"",
      motherPost:"",
      applyStatus:1,
      infoStatus:0,
      loading: false
    };
  },
  watch:{
    fatherMobilePhone(n,o){
      if(n.length>11){
        this.fatherMobilePhone = o;
      }
    },
    motherMobilePhone(n,o){
      if(n.length>11){
        this.motherMobilePhone = o;
      }
    }
  },
  created() {
    this.getExistApply();
  },
  methods: {
    resetVerification(key) {
      // console.log(key);
      this.verification[key] = false;
    },
    goPrevStep(){
      this.$router.push("/contactinfo");
    },
    saveData(verify = false) {
      let warning = false;
      let params = {};
      params['fatherOccupation'] = this.fatherOccupation;
      params['fatherCompany'] = this.fatherCompany;
      params['fatherPost'] = this.fatherPost;
      params['motherOccupation'] = this.motherOccupation;
      params['motherCompany'] = this.motherCompany;
      params['motherPost'] = this.motherPost;
      
      (this.fatherName == "" || this.fatherName == null)
        ? ((this.verification["fatherName"] = verify?true:false), (warning = true))
        : (params['fatherName'] = this.fatherName);
      (this.fatherMobilePhone == "" || this.fatherMobilePhone == null)
        ? ((this.verification["fatherMobilePhone"] = verify?true:false), (warning = true))
        : (params['fatherMobilePhone'] = this.fatherMobilePhone);
      (this.motherName == "" || this.motherName == null)
        ? ((this.verification["motherName"] = verify?true:false), (warning = true))
        : (params['motherName'] = this.motherName);
      (this.motherMobilePhone == "" || this.motherMobilePhone == null)
        ? ((this.verification["motherMobilePhone"] = verify?true:false), (warning = true))
        : (params['motherMobilePhone'] = this.motherMobilePhone);

      if(verify && this.infoStatus < 4){
        params['infoStatus'] = 4
        this.infoStatus = 4
      }
      if (verify && warning) {
        this.$message.warning("请填写完整");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.fatherMobilePhone) || !/^1[3456789]\d{9}$/.test(this.motherMobilePhone)) {
        this.$message.warning("请输入正确的联系方式");
        return;
      }

      let _that = this;
      _that.loading = true;
      // console.log(params);
      this.$api
          .saveTmpApply(params)
          .then((res) => {
            _that.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              if(verify == true){
                this.$router.push("/highschoolinfo");
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            _that.loading = false;
            console.log(err);
          });
    },
    save(){
      this.saveData();
    },
    saveAndNext(){
      if(this.applyStatus == 1){
        this.saveData(true);
      }else{
        this.$router.push("/highschoolinfo");
      }
    },
    getExistApply(){
      this.loading = true;
      this.$api
          .getExistApply()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.applyStatus = res.data.exixtApply.status;
              this.fatherName = res.data.exixtApply.fatherName;
              this.fatherMobilePhone = res.data.exixtApply.fatherMobilePhone;
              this.fatherOccupation = res.data.exixtApply.fatherOccupation;
              this.fatherCompany = res.data.exixtApply.fatherCompany;
              this.fatherPost = res.data.exixtApply.fatherPost;
              this.motherName = res.data.exixtApply.motherName;
              this.motherMobilePhone = res.data.exixtApply.motherMobilePhone;
              this.motherOccupation = res.data.exixtApply.motherOccupation;
              this.motherCompany = res.data.exixtApply.motherCompany;
              this.motherPost = res.data.exixtApply.motherPost;
              this.infoStatus = res.data.exixtApply.infoStatus?res.data.exixtApply.infoStatus:0;
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  },
};
</script>

<style lang='scss' scoped>
.content-div {
  height: calc(100%);
  overflow-y: scroll;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  margin-top: 100px;
  display: flex;
  .info-box {
    .title {
      background-color: #e8ecee;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      margin-left: 80px;
      padding-left: 18px;
    }
    .input-item {
      display: flex;
      margin: 20px;
      height: 36px;
      font-size: 16px;
      align-items: center;
      position: relative;
      .label {
        font-size: 18px;
        width: 150px;
        text-align: right;
        margin-right: 10px;
      }
      .input-box {
        border: 1px #ccc solid;
        width: 300px;
        height: 100%;
        display: flex;
        align-items: center;
        input {
          outline: none;
          border: 0;
          height: 100%;
          padding: 0 10px;
          width: 100%;
        }
        &.warning {
          border-color: #ff5722;
        }
        .verification-tips {
          position: absolute;
          bottom: -20px;
          font-size: 14px;
          color: #ff5722;
          padding-left: 10px;
        }
      }
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
/deep/ .ant-select {
  width: 100%;
  font-size: 16px;
  .ant-select-selection {
    border: 0;
    box-shadow: none;
  }
}
/deep/ .ant-select-dropdown-menu-item {
  font-size: 16px;
}
/deep/ .ant-calendar-picker {
  width: 100%;
  .ant-calendar-picker-input.ant-input {
    border: 0;
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply-done-tip{
  font-size: 14px;
  color: #999;
  margin-right: 20px;
}
</style>