<template>
  <div class="body-box">
    <div class="left-box">
      <div class="welcome-text">
        {{ name }}，<br />
        欢迎报考宁波诺丁汉大学！
      </div>
      <div class="block">
        <div class="title">报考进度</div>
        <div class="block-body-box">
          <div class="total-bar">
            <div class="done-bar" :style="progress"></div>
          </div>
          <div class="step">
            <div class="step-item">申请表提交<br><span>{{submit}}</span></div>
            <div class="step-item">材料上传<br><span>{{upload}}</span></div>
            <div class="step-item">初审结果<br><span>{{result}}</span></div>
            <div class="step-item">缴费<br><span>{{payment}}</span></div>
            <div class="step-item">准考证<br><span>{{admission}}</span></div>
            <div class="step-item">成绩查询<br><span>{{score}}</span></div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="title">公告栏</div>
        <div class="block-body-box">
          <div class="article" :style="'padding: 15px 0px 15px 30px; max-height:'+articleH+'px'">
            <div
              class="notice-item"
              v-for="item in notice"
              :key="item.id"
              @click="openDetail('article', item.id)"
            >
              <div class="notice-title">
                <span class="label" v-if="item.isTop == '1'">[置顶]</span>
                <span class="label" v-if="item.isHot == '1'">[热门]</span>
                <span>{{ item.title }}</span>
              </div>
              <span class="notice-date">{{ item.createDatetime.substring(0, 10).replaceAll('-', '.') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="countdown-box">
        <div class="countdown-item" v-if="applyTime != 0">
          <div>距离报名结束<br />还剩</div>
          <div class="day">{{ applyTime }}</div>
          <div>天</div>
        </div>
        <div class="countdown-item" v-if="applyTime == 0">
          <div style="position: relative;margin-top: 50%;">报名已结束</div>
        </div>
        <div class="countdown-item" v-if="testTime != 0">
          <div>距离综合测试<br />还剩</div>
          <div class="day">{{ testTime }}</div>
          <div>天</div>
        </div>
        <div class="countdown-item" v-if="testTime == 0">
          <div style="position: relative;margin-top: 50%;">已过测试时间</div>
        </div>
      </div>
      <div class="msg-box">
        <div class="title">系统通知</div>
        <div class="notice" :style="'padding: 10px 0;max-height:'+noticeH+'px'">
          <div class="item">
            <div class="msg-content">
<!--              <span class="label">[最新]</span>-->
              <span>{{ noticeTitle }}</span>
            </div>
            <div class="msg-date">{{ noticeTime }}</div>
          </div>
        </div>
        <div class="separator"></div>
      </div>
      <div class="wx-qr">
        <img src="../../assets/qr.png" style="height: 100%" />
        <div>
          宁波诺丁汉大学招生办<br />
          微信公众号
        </div>
      </div>
    </div>
    <div class="pop-layer" v-if="articleLayer">
      <div class="box" :style="scrollY?'overflow-x:scroll':''">
        <div class="title">
          <div>公告</div>
          <div>
            <i
              data-v-8b6adaf6=""
              class="iconfont icon-guanbi1"
              @click="closeDetail"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="addtion">
            来源：{{ articleInfo.author }} 发布日期：{{
              articleInfo.publishDate
            }}
            浏览次数：{{ articleInfo.viewer }}
          </div>
          <div class="content-box">
            <div class="content-title">{{ articleInfo.title }}</div>
            <div v-html="articleInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-layer notice-layer" v-if="noticeLayer" @click="closeDetail">
      <div class="box notice-box" :style="scrollY?'overflow-x:scroll':''">
        <div class="title">
          <div>通知</div>
          <div>
            <i
              data-v-8b6adaf6=""
              class="iconfont icon-guanbi1"
              @click="closeDetail"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="addtion">通知时间：{{ noticeInfo.createDatetime }}</div>
          <div class="content-box">
            <div v-html="noticeInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-layer notice-layer" v-if="showNotice" @click="closeNoticeDetail">
      <div class="box notice-box" :style="scrollY?'overflow-x:scroll':''">
        <div class="title">
          <div>通知</div>
          <div>
            <i
              data-v-8b6adaf6=""
              class="iconfont icon-guanbi1"
              @click="closeDetail"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="addtion">通知时间：{{ noticeInfo.createDatetime }}</div>
          <div class="content-box">
            <div class="content-title">{{ noticeInfo.title }}</div>
            <div v-html="noticeInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo:{
      meta:[
          { name: 'referrer',content:'no-referrer' }
      ]
  },
  data() {
    return {
      name: this.$store.state.name,
      notice: [],
      showNotice: false,
      announcement: [],
      articleLayer: false,
      noticeLayer: false,
      articleInfo: {},
      noticeInfo: {},
      applyTime: "-",
      testTime: "-",
      progress: "width: calc(100% * 1 / 12)",
      submit: '',
      upload: '',
      result: '',
      payment: '',
      admission: '',
      score: '',
      scrollY:false,
      articleH: 1000,
      noticeH: 1000,
      loading: false,
      noticeTitle: '',
      noticeTime: ''
    };
  },
  created() {
    if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
      this.$router.push("mindex")
      return
    }
    if(document.body.clientWidth <= 650){
      this.scrollY = true
    }else{
      this.scrollY = false
    }
    this.articleH = document.body.clientHeight - 672 < 180 ? 180 : document.body.clientHeight - 672;
    this.noticeH = document.body.clientHeight - 637 < 215 ? 215 : document.body.clientHeight - 637;
    window.onresize = () => {
      if(document.body.clientWidth <= 650){
        this.scrollY = true
      }else{
        this.scrollY = false
      }
      this.articleH = document.body.clientHeight - 672 < 180 ? 180 : document.body.clientHeight - 672;
      this.noticeH = document.body.clientHeight - 637 < 215 ? 215 : document.body.clientHeight - 637;
    }
    // console.log(navigator.userAgent)
    // if(document.body.clientWidth <= 768){
    //    this.$router.push("mindex");
    //    return
    // }
    this.articleList();
    // this.announcementList();
    this.getContentConfigList();
    this.getApplyStatus();
    this.getExistApply();
    this.getNoticeList(true);
  },
  methods: {
    closeNoticeDetail() {
      this.$api
        .readNotice({
          id: this.noticeInfo.id
        })
      this.showNotice = false
      this.getNoticeList(false);
    },
    getNoticeList(needJudgeAlways) {
      var that = this
      this.$api
        .getNoticeList()
        .then((res) => {
          if (res.data.code == 0) {
            that.noticeList = res.data.page.list;
            if (needJudgeAlways) {
              // 判断是否需要弹框
              var alwaysList = that.noticeList.filter(t => t.isAlways === '1')
              if (alwaysList.length > 0) {
                that.openSysNoticeDetail(alwaysList[0].id)
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExistApply() {
      this.$api
        .getExistApply()
        .then((res) => {
          if (res.data.code == 0) {
            this.name = res.data.exixtApply.realName;
            if(this.name && this.name != ''){
              this.$store.commit("setName", this.name);
            }
          }
        })
        .catch((err) => {
          // _that.loading = false;
          console.log(err);
        });
    },
    articleList() {
      this.$api
        .articleList({
          categoryName: "公告栏",
          pageNum: "0",
          pageSize: "10000",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.notice = res.data.page.list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    announcementList() {
      this.$api
        .noticeList({
          pageNum: "0",
          pageSize: "10000",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.announcement = res.data.page.list;
          } else {
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openSysNoticeDetail(receiverId) {
      this.$api
        .sysNoticeInfo({
          id: receiverId
        })
        .then((res) => {
          this.loading = false;
          // console.log(res);
          if (res.data.code == 0) {
            this.noticeInfo = res.data.sysNoticeInfo;
            this.showNotice = true
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDetail(type, id) {
      this.loading = true;
      this.articleInfo = {};
      if (type == "article") {
        this.articleLayer = true;
        this.$api
          .articleInfo({
            id: id,
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.articleInfo = res.data.articleInfo;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.$api
          .addViewer({
            id: id,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.noticeLayer = true;
        this.$api
          .noticeInfo({
            id: id,
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.noticeInfo = res.data.noticeInfo;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    closeDetail() {
      this.articleLayer = false;
      this.noticeLayer = false;
    },
    getContentConfigList() {
      this.$api
        .contentConfigList({
          categoryName: "内容配置",
          name: "报名结束时间",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            var s1 = res.data.configList[0].content;
            s1 = new Date(s1.replace(/-/g, "/"));
            var s2 = new Date();
            var days = s1.getTime() - s2.getTime();
            if (days < 0) {
              this.applyTime = 0;
            } else {
              this.applyTime = parseInt(days / (1000 * 60 * 60 * 24));
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api
        .contentConfigList({
          categoryName: "内容配置",
          name: "综合测试时间",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            var s1 = res.data.configList[0].content.substring(0,10);
            s1 = s1.replace('年','-');
            s1 = s1.replace('月','-');
            s1 = new Date(s1.replace(/-/g, "/"));
            var s2 = new Date();
            var days = s1.getTime() - s2.getTime();
            if (days < 0) {
              this.testTime = 0;
            } else {
              this.testTime = parseInt(days / (1000 * 60 * 60 * 24));
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getApplyStatus() {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus == 1 || res.data.applyStatus == 3) {
              this.progress = "width: calc(100% * 1 / 12);";
              this.submit = '（未提交）'
              this.noticeTitle = '您尚未提交宁波诺丁汉大学三位一体报名申请，请尽快完成报名'
            } else if (res.data.applyStatus == 2) {
              this.progress = "width: calc(100% * 3 / 12);";
              this.submit = '（已提交）'
              this.upload = '（未上传）'
              this.noticeTitle = '您尚未上传宁波诺丁汉大学三位一体报名材料，请尽快完成上传'
            } else if (res.data.applyStatus == 4) {
              this.progress = "width: calc(100% * 6 / 12);";
              this.submit = '（已提交）'
              this.upload = '（已上传）'
              this.result = '（未公布）'
              this.noticeTitle = '您已成功报名宁波诺丁汉大学三位一体，请等待{time}初审结果'
              this.getAuditTime()
            } else if (res.data.applyStatus == 6) {
              this.progress = "width: calc(100% * 5.2 / 12);";
              this.submit = '（已提交）'
              this.upload = '（已上传）'
              this.result = '（初审未通过）'
              this.noticeTitle = '您可点击左侧菜单栏查询您的初审结果'
            } else if (res.data.applyStatus == 5 ) {
              this.progress = "width: calc(100% * 7.5 / 12);";
              this.submit = '（已提交）'
              this.upload = '（已上传）'
              this.result = '（初审通过）'
              this.payment = '（未缴费）'
              this.noticeTitle = '恭喜您已通过宁波诺丁汉大学三位一体初审，请及时点击左侧菜单栏完成缴费'
            } else if (res.data.applyStatus == 8) {
              this.progress = "width: calc(100% * 7.5 / 12);";
              this.submit = '（已提交）'
              this.upload = '（已上传）'
              this.result = '（初审通过）'
              this.payment = '（未缴费）'
              this.noticeTitle = '恭喜您已通过宁波诺丁汉大学三位一体初审，请及时点击左侧菜单栏完成缴费'
            } else if (res.data.applyStatus == 7) {
              this.progress = "width: calc(100% * 7.5 / 12);";
              this.submit = '（已提交）'
              this.upload = '（已上传）'
              this.result = '（初审通过）'
              this.payment = '（已缴费）'
              this.noticeTitle = '您已成功缴费，请及时点击左侧菜单栏打印准考证'
              this.getAdmissionLimits()
            } else if (res.data.applyStatus == 9) {
              this.progress = "width: calc(100% * 10 / 12);";
              this.submit = '（已提交）'
              this.upload = '（已上传）'
              this.result = '（初审通过）'
              this.payment = '（已缴费）'
              this.admission = '（可打印）'
              this.noticeTitle = '您已成功缴费，请及时点击左侧菜单栏打印准考证'
            } else if (
              res.data.applyStatus >= 10
            ) {
              this.progress = "width: calc(100% * 11.5 / 12);";
              this.submit = '（已提交）'
              this.upload = '（已上传）'
              this.result = '（初审通过）'
              this.payment = '（已缴费）'
              this.admission = '（可打印）'
              this.score = '（可查询）'
              this.noticeTitle = '欢迎报考宁波诺丁汉大学'
            }
          } else {
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAuditTime(){
      this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'初审时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(0,1) == '0'){
                  timeStr = res.data.configList[item].content.substring(1,3);
                }else{
                  timeStr = res.data.configList[item].content.substring(0,3);
                }
                if(res.data.configList[item].content.substring(3,4) == '0'){
                  timeStr += res.data.configList[item].content.substring(4);
                }else{
                  timeStr += res.data.configList[item].content.substring(3);
                }
                this.noticeTitle = this.noticeTitle.replace('{time}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getAdmissionLimits() {
      this.$api
        .getProgressList({
          code: "code_7",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            return
          }
          this.progress = "width: calc(100% * 9.5 / 12);";
          this.admission = '（可打印）';
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.body-box {
  height: 100%;
  overflow-y: scroll;
  min-width: 1080px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.pop-layer {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  padding: 15px;
  &.notice-layer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    &.notice-box {
      width: 800px;
      padding-bottom: 50px;
      height: 100%;
    }
    .title {
      display: flex;
      height: 60px;
      border-bottom: 1px #ddd solid;
      line-height: 60px;
      font-size: 18px;
      padding: 0 20px;
      justify-content: space-between;
      min-width: 600px;
      i {
        font-size: 18px;
        cursor: pointer;
        &:hover {
          color: #f44336;
          font-weight: bold;
        }
      }
    }
    .content {
      padding: 50px;
      overflow-y: scroll;
      height: calc(100% - 60px);
      display: block;
      min-width: 600px;
      .content-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .addtion {
        color: #858585;
        text-align: right;
        margin-bottom: 50px;
      }
      .content-box {
        display: block;
        width: auto;
        min-width: auto;
        /deep/ img{
          max-width: 100%;
          height: auto;
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }
      /*竖向滚动条*/
      &::-webkit-scrollbar-thumb:vertical {
        height: 5px;
        background-color: #ddd;
        -webkit-border-radius: 0px;
      }
    }
  }
}
.left-box {
  float: left;
  width: calc(100% - 310px);
  .welcome-text {
    background-color: white;
    padding: 30px;
    font-size: 26px;
    font-weight: bold;
    color: #18194f;
  }
  .block {
    background-color: white;
    margin-top: 20px;
    padding-bottom: 30px;
    .title {
      padding: 30px 30px 20px;
      border-bottom: 1px #ddd solid;
      font-size: 18px;
      font-weight: bold;
    }
    .article{
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }
      /*竖向滚动条*/
      &::-webkit-scrollbar-thumb:vertical {
        height: 5px;
        background-color: #ddd;
        -webkit-border-radius: 0px;
      }
    }
    .total-bar {
      height: 10px;
      background-color: #dddddd;
      margin: 50px 30px 20px;
      .done-bar {
        height: 100%;
        background-color: #de1b5c;
        width: calc(100% * 1 / 12);
      }
    }
    .step {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      justify-content: space-around;
      text-align: center;
      span{
        font-weight: normal;
      }
    }
    .notice-item {
      font-size: 16px;
      text-decoration: underline;
      overflow: hidden;
      width: 50%;
      float: left;
      padding-right: 30px;
      height: 48px;
      display: flex;
      margin-bottom: 5px;
      .notice-title {
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 90px);
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        float: left;
      }
      .notice-date {
        float: right;
        width: 85px;
        color: #999;
        margin-left: 15px;
      }
      span {
        cursor: pointer;
        &:hover {
          color: #03a9f4;
        }
      }
      .label {
        color: #de1b5c;
        font-weight: bold;
        margin-right: 5px;
      }
      .msg-date {
        color: #999;
        font-size: 14px;
        text-align: right;
        margin-top: 10px;
      }
    }
  }
}
.right-box {
  float: right;
  width: 290px;
  .countdown-box {
    display: flex;
    width: 290px;
    justify-content: space-between;
    .countdown-item {
      background-color: #005597;
      width: 140px;
      padding: 20px;
      height: 172px;
      div {
        font-size: 16px;
        color: white;
        text-align: center;
      }
      .day {
        color: #ffc107;
        font-size: 40px;
        font-weight: bold;
      }
    }
  }
  .msg-box {
    background-color: white;
    margin-top: 20px;
    .title {
      padding: 20px;
      border-bottom: 1px #ddd solid;
      font-size: 18px;
      font-weight: bold;
    }
    .notice{
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }
      /*竖向滚动条*/
      &::-webkit-scrollbar-thumb:vertical {
        height: 5px;
        background-color: #ddd;
        -webkit-border-radius: 0px;
      }
    }
    .item {
      margin: 5px 20px;
      font-size: 16px;
      .msg-content {
        text-decoration: underline;
        //cursor: pointer;
        &:hover {
          //color: #03a9f4;
        }
        .label {
          color: #de1b5c;
          font-weight: bold;
          margin-right: 5px;
        }
      }
      .msg-date {
        color: #999;
        font-size: 14px;
        text-align: right;
      }
    }
    .separator {
      border-bottom: 1px #ddd dashed;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .wx-qr {
    margin-top: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    font-size: 18px;
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
// @media screen and (max-width: 1100px) {
//   .left-box{
//     width: calc(100% - 260px);
//   }
//   .right-box {
//     width: 240px;
//     .countdown-box {
//       width: 240px;
//       .countdown-item {
//         width: 115px;
//         padding: 15px;
//         div {
//           font-size: 14px;
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 1000px) {
//   .header .title{
//     font-size: 18px;
//   }
//   .left-box{
//     width: 100%;
//   }
//   .right-box{
//     width: 100%;
//     margin-top: 20px;
//     .countdown-box{
//       width: 100%;
//       .countdown-item{
//         width: calc(50% - 10px);
//       }
//     }
//   }
// }
// @media screen and (max-width: 1000px) {
//   .body-box{
//     height: unset;
//     overflow-y: unset;
//     .left-box{
//       .welcome-text{
//         font-size: 20px;
//       }
//       .block .step{
//         font-size: 12px;
//       }
//     }
//   }
// }
</style>
