<template>
  <div>
    <div class="header">
      <img src="../../assets/whitetext.png" style="height: 100%" />
    </div>
    <div class="content" ref="getheight" :style="'height:calc(100vh - 170px - '+hh+'px)'">
      <div class="content-box" :style="'transform: scale('+scale+')'">
        <div class="title">
          创建新账号 Create a New Account<i
            class="iconfont icon-guanbi1"
            @click="login"
          ></i>
        </div>
        <div class="left-box">
          <div class="input-box">
            <input
              type="text"
              placeholder="请输入考生姓名"
              v-model="realName"
            />
          </div>
          <div class="input-box">
            <input
              type="text"
              placeholder="请输入身份证号码"
              v-model="cardNo"
            />
          </div>
          <div class="input-box">
            <input
              type="password"
              placeholder="请设置密码"
              v-model="password"
            />
          </div>
          <div class="input-box">
            <input
              type="password"
              placeholder="请确认密码"
              v-model="password2"
            />
          </div>
          <div class="input-box">
            <input
              type="number"
              placeholder="手机号码（用于账号验证）"
              v-model="mobilePhone"
            />
          </div>
          <div class="input-box">
            <input type="text" placeholder="请输入验证码" v-model="code" />
            <div
              class="sms-code"
              @click="sendVerificationCode"
              :class="[sended ? 'ban' : '']"
            >
              {{ sended ? seconds + "s 后重新发送" : "点击获取验证码" }}
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="tip-title">密码设置规则：</div>
          <li>至少含8个字符</li>
          <li>必须含英文大小写字符和数字</li>
          <div class="reg-btn" @click="register">
            确认注册 Register <i class="iconfont icon-you"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div>招生热线：0574-88180182</div>
        <div>学校地址：浙江省宁波市泰康东路199号</div>
        <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
        </div>
        <div>招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
            >Gaokao@nottingham.edu.cn</a
          >
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../../assets/qr.png" style="height: 100%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
      scale:1,
      realName:'',
      cardNo: "",
      password: "",
      password2: "",
      mobilePhone: "",
      code: "",
      sended: false,
      timer: "",
      seconds: 60,
      height: `${document.documentElement.clientHeight}`,
      hh:0,
      loading: false
    };
  },
  watch: {
    seconds() {
      if (this.seconds <= 0) {
        clearInterval(this.timer);
        this.sended = false;
      }
    },
    mobilePhone(n,o){
      if(n.length>11){
        this.mobilePhone = o;
      }
    }
  },
  mounted() {
    this.getSystemStatus();
    setTimeout(() => {
      this.hh = this.$refs.getheight.offsetHeight - (this.height - 170)
      var ratio = (this.$refs.getheight.offsetHeight - this.hh) /470;
      this.scale = ratio < 1 ?ratio:1
    }, 50);
    window.onresize = () => {
      var ratio = this.$refs.getheight.offsetHeight/470;
      this.scale = ratio < 1 ?ratio:1
    }
  },
  methods: {
    encrypt (word, keyStr) {
      keyStr = 'wwwkernespringxy'
      var key = CryptoJS.enc.Utf8.parse(keyStr)
      var srcs = CryptoJS.enc.Utf8.parse(word)
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
      return encrypted.toString()
    },
    getSystemStatus() {
      this.$api
        .getProgressList({
          code: "code_10",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.$router.push("mSystemClose");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    login() {
      this.$router.push("mlogin");
    },
    sendVerificationCode() {
      if (this.sended) {
        return;
      }
      if (this.mobilePhone) {
        if (!/^1[3456789]\d{9}$/.test(this.mobilePhone)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        this.sended = true;
        this.seconds = 60;
        this.timer = setInterval(() => {
          this.seconds--;
        }, 1000);
        this.$api
          .sendVerificationCode({
            mobile: this.mobilePhone,
            type:1
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success("短信验证码已发送，请注意查收");
            } else {
              clearInterval(this.timer);
              this.sended = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("手机号不能为空");
      }
    },
    register() {
      if (
        this.realName &&
        this.mobilePhone &&
        this.cardNo &&
        this.code &&
        this.password &&
        this.password2
      ) {
        // 身份证验证
        if (
          !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            this.cardNo
          )
        ) {
          this.$message.warning("请输入正确身份证号");
          return;
        } else if (this.cardNo.indexOf("x") > -1) {
          this.$message.warning("请输入大写的X字母");
          return;
        }
        // 手机验证
        if (!/^1[3456789]\d{9}$/.test(this.mobilePhone)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        // 密码验证
        if (this.password.length < 8) {
          this.$message.warning("密码至少含8个字符");
          return;
        }
        if (
          // !/^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{8,}$/.test(this.password)
          !/[a-zA-Z]/.test(this.password) || !/[0-9]/.test(this.password)
        ) {
          this.$message.warning("必须含英文大小写字符和数字");
          return;
        }
        if (this.password != this.password2) {
          this.$message.warning("两次密码输入不一致！");
          return;
        }
        this.loading = true;
        this.$api
          .register({
            realName: this.realName,
            cardNo: this.encrypt(this.cardNo),
            code: this.code,
            mobile: this.mobilePhone,
            password: this.encrypt(this.password),
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success("注册成功！先登录");
              this.$router.push("/mlogin");
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("请填写完整！");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 70px;
  background-color: #18194f;
  padding-bottom: 10px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 170px);
  .content-box {
    width: 90%;
    border: 1px #009bbd solid;
    padding-bottom: 20px;
    .title {
        color: white;
        background-color: #009bbd;
        height: 45px;
        font-size: 16px;
        line-height: 45px;
        padding: 0 10px 0 20px;
        font-weight: bold;
      .icon-guanbi1 {
        float: right;
        font-size: 16px;
        cursor: pointer;
        font-weight: bold;
        &:hover {
          color: #005597;
          font-weight: bold;
        }
      }
    }
    .left-box {
      .input-box {
        border: 0;
        margin: 10px 20px;
        font-size: 14px;
        height: 35px;
        display: flex;
        align-items: center;
        input {
          border: 0;
          height: 100%;
          width: 100%;
          outline: none;
          border-bottom: 1px #009bbd solid;
          border-radius: 0;
          &:hover {
            border-bottom: 2px #009bbd solid;
          }
          &::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #bbb;
          }

          &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #bbb;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #bbb;
          }
        }
        .sms-code {
            color: white;
            background-color: #005597;
            width: 220px;
            text-align: center;
            height: 35px;
            line-height: 31px;
            border: 2px #005597 solid;
            font-size: 12px;
            transition: all 0.5s;
            margin-left: 20px;
            cursor: pointer;
          &:hover {
            background-color: unset;
            color: #005597;
            border: 2px #005597 solid;
            font-weight: bold;
          }
          &.ban {
            color: #999;
            background-color: #ddd;
            border: 0;
            border: 1px #999 solid;
            cursor: not-allowed;
          }
        }
      }
    }
    .right-box {
      float: left;
      padding: 5px 20px 0 20px;
      width: 100%;
      .tip-title {
        font-size: 16px;
        color: #009bbd;
      }
      li {
        font-size: 14px;
        line-height: 18px;
      }
      .reg-btn {
        text-align: center;
        height: 45px;
        background-color: #de1b5c;
        margin-top: 10px;
        line-height: 41px;
        color: white;
        border: 2px #de1b5c solid;
        font-size: 16px;
        transition: all 0.5s;
        cursor: pointer;
        &:hover {
          background-color: unset;
          color: #de1b5c;
          border: 2px#de1b5c solid;
          font-weight: bold;
        }
      }
    }
  }
}
.footer {
    height: 100px;
    background-color: #18194f;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    align-items: center;
    padding: 10px;
  .text-div {
    font-size: 10px;
    .school-name {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: white;
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      height: 70%;
    }
    .img-text {
      font-size: 10px;
      text-align: center;
      line-height: 12px;
      margin-top: 3px;
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>