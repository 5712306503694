import Vue from "vue";
import VueRouter from "vue-router";
import Userlogin from "../views/User/Login.vue";
import Userregister from "../views/User/Register.vue";
import Layout from "../views/Layout/Layout.vue";
import Home from "../views/Home/Home.vue";
import Applyinfo from "../views/User/Info.vue";
import Queryinfo from "../views/Query/Info.vue";
import Admissioninfo from "../views/Admission/Info.vue";
import Scoreinfo from "../views/Score/Info.vue";
import MLogin from "../views/Mobile/Login.vue";
import Muserregister from "../views/Mobile/Register.vue";
import Mindex from "../views/Mobile/Index.vue";
import Mprogress from "../views/Mobile/Progress.vue";
import Mapply from "../views/Mobile/Apply.vue";
import Mresult from "../views/Mobile/Result.vue";
import Mpay from "../views/Mobile/Pay.vue";
import Madmission from "../views/Mobile/Admission.vue";
import Mscore from "../views/Mobile/Score.vue";
import Sysclose from "../views/SystemClose.vue";
import mSysclose from "../views/Mobile/SystemClose.vue";
// import Nopage from "../views/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    meta: { title:'首页' },
    component: Layout,
    children:[
      {
        path: "/",
        meta: {title:'首页'},
        component: Home
      },
      {
        path: "/index",
        meta: {title:'首页'},
        component: Home
      },
      {
        path: "userinfo",
        meta: {title:'考生信息'},
        component: Applyinfo
      },
      {
        path: "contactinfo",
        meta: {title:'联系方式'},
        component: Applyinfo
      },
      {
        path: "parentsinfo",
        meta: {title:'父母信息'},
        component: Applyinfo
      },
      {
        path: "highschoolinfo",
        meta: {title:'高中信息'},
        component: Applyinfo
      },
      {
        path: "highschoolscore",
        meta: {title:'高中成绩'},
        component: Applyinfo
      },
      {
        path: "intentionmajor",
        meta: {title:'报考意向'},
        component: Applyinfo
      },
      {
        path: "infoconfirmation",
        meta: {title:'信息确认'},
        component: Applyinfo
      },
      {
        path: "commitment",
        meta: {title:'考生诚信承诺书'},
        component: Applyinfo
      },
      {
        path: "aplyform",
        meta: {title:'申请表下载/打印'},
        component: Applyinfo
      },
      {
        path: "uploadmaterial",
        meta: {title:'材料上传'},
        component: Applyinfo
      },
      {
        path: "queryresult",
        meta: {title:'结果查询'},
        component: Queryinfo
      },
      {
        path: "payment",
        meta: {title:'缴费通道'},
        component: Queryinfo
      },
      {
        path: "admissiondownload",
        meta: {title:'准考证下载'},
        component: Admissioninfo
      },
      {
        path: "writtenexamination",
        meta: {title:'笔试成绩'},
        component: Scoreinfo
      },
      {
        path: "totalexamination",
        meta: {title:'综合测试成绩'},
        component: Scoreinfo
      }
    ]
  },
  {
    path: "/login",
    meta: { title:'登录' },
    component: Userlogin
  },
  {
    path: "/register",
    meta: { title:'注册' },
    component: Userregister
  },
  {
    path: "/mlogin",
    meta: {title:'登录'},
    component: MLogin
  },
  {
    path: "/mregister",
    meta: { title:'注册' },
    component: Muserregister
  },
  {
    path: "/mindex",
    meta: { title:'首页' },
    component: Mindex
  },
  {
    path: "/mprogress",
    meta: { title:'报考进度' },
    component: Mprogress
  },
  {
    path: "/mapply",
    meta: { title:'申请表' },
    component: Mapply
  },
  {
    path: "/mresult",
    meta: { title:'初审' },
    component: Mresult
  },
  {
    path: "/mpay",
    meta: { title:'缴费' },
    component: Mpay
  },
  {
    path: "/madmission",
    meta: { title:'准考证' },
    component: Madmission
  },
  {
    path: "/mscore",
    meta: { title:'成绩查询' },
    component: Mscore
  },
  {
    path: "/mSystemClose",
    meta: { title:'系统关闭' },
    component: mSysclose
  },
  {
    path: "/systemClose",
    meta: { title:'系统关闭' },
    component: Sysclose
  },
  {
    path: "*",
    meta: { title:'首页' },
    component: Userlogin
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
