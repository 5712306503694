<template>
  <div>
    <div class="header">
      <img src="../../assets/whitetext.png" style="height: 100%" />
    </div>
    <div class="back-btn" @click="goHome">回到首页</div>
    <div class="content" :style="'height:'+(height-170)+'px'">
      <div class="content-fail" v-if="status == 11">
        <div v-html="statusOptions">
        </div>
        <div class="box">
          <div class="table-head">笔试成绩</div>
          <div class="score">{{handleScore}}</div>
        </div>
      </div>
      <div class="content-succ" v-if="status == 10">
        <div v-html="statusOptions">
        </div>
        <div class="box">
          <div class="table-head">
            <div>笔试成绩</div>
            <div>面试等候教室</div>
            <div style="border-right: 0">应到达等候时间</div>
          </div>
          <div class="score">
            <div>{{handleScore}}</div>
            <div>{{faceRoom}}</div>
            <div style="border-right: 0">{{watiTime}}</div>
          </div>
        </div>
      </div>
      <div class="content-total" v-if="status == 12">
        <div v-html="statusOptions">
        </div>
        <div class="box">
          <div class="table-head">
            <div>笔试成绩</div>
            <div>面试成绩</div>
            <div>综合测试成绩</div>
            <div style="border-right: 0">排名</div>
          </div>
          <div class="score">
            <div>{{handleScore}}</div>
            <div>{{faceScore}}</div>
            <div>{{allScore}}</div>
            <div style="border-right: 0">{{rank}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div>招生热线：0574-88180182</div>
        <div>学校地址：浙江省宁波市泰康东路199号</div>
        <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
        </div>
        <div>招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
            >Gaokao@nottingham.edu.cn</a
          >
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../../assets/qr.png" style="height: 100%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status:0,
      statusOptions:"",
      faceRoom:"",
      watiTime:"",
      handleScore:"",
      faceScore:"",
      allScore:"",
      rank:"",
      height: `${document.documentElement.clientHeight}`,
      loading:false
    };
  },
  created(){
    this.getTotalStatus()
  },
  methods: {
    goHome(){
      this.$router.push("mindex");
    },
    getTotalStatus() {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus < 12) {
              this.getHandleScore();
            } else {
              this.getMultipleScore();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHandleScore(){
      this.loading = true;
      this.$api
          .getHandleScore()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              if(res.data.handleStatus == 2){
                this.status = 10;
                this.handleScore = res.data.handleScore+'分';
                this.statusOptions = res.data.statusOptions;
                this.faceRoom = res.data.faceRoom;
                this.watiTime = res.data.watiTime;
                this.isHideScore();
              }else if(res.data.handleStatus == 1){
                this.status = 11;
                this.statusOptions = res.data.statusOptions;
                this.handleScore = res.data.handleScore+'分';
              }
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getMultipleScore(){
      this.loading = true;
      this.$api
          .getMultipleScore()
          .then((res) => {
            this.loading = false;
            console.log(res);
            if (res.data.code == 0) {
              this.status = 12;
              this.handleScore = res.data.handleScore+'分';
              this.faceScore = res.data.faceScore+'分';
              this.allScore = res.data.allScore+'分';
              this.rank = '第'+res.data.rank+'名';
              this.statusOptions = res.data.statusOptions;
              this.isHideScore();
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    isHideScore() {
      this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "是否关闭显示笔试成绩",
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              if (res.data.configList[0].content === "T") {
                this.getHideScoreTips();
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getHideScoreTips() {
      this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "笔试成绩显示说明",
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.handleScore = res.data.configList[0].content;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }
};
</script>

<style lang='scss' scoped>
.header {
  height: 70px;
  background-color: #18194f;
  padding-bottom: 10px;
}
.back-btn{
  width: 85px;
  height: 35px;
  background-color: #009bbd;
  position: absolute;
  left: 0;
  line-height: 35px;
  color: white;
  text-align: center;
  z-index: 1;
  top: 85px;
}
.content {
  position: relative;
  height: calc(100vh - 170px);
  overflow-y: scroll;
  background-color: #fff;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  .content-fail {
    font-size: 16px;
    text-align: center;
    line-height: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px #009bbd solid;
    padding: 10px 5px;
    height: 200px;
    width: 100%;
    .box {
      width: 200px;
      margin-top: 20px;
      border: 1px #009bbd solid;
      .table-head {
        background-color: #009bbd;
        color: white;
      }
    }
  }
  .content-succ {
    font-size: 16px;
    text-align: center;
    line-height: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px #009bbd solid;
    padding: 20px 15px;
    height: 200px;
    width: 100%;
    justify-content: center;
    .box {
      width: 320px;
      margin-top: 20px;
      border: 1px #009bbd solid;
      font-size: 14px;
      .table-head {
        background-color: #009bbd;
        color: white;
        display: flex;
        text-align: center;
        div {
          width: 33.3%;
          border-right: 1px #fff solid;
        }
      }
      .score {
        display: flex;
        text-align: center;
        div {
          width: 33.3%;
          border-right: 1px #009bbd solid;
        }
      }
    }
  }
  .content-total {
    font-size: 16px;
    text-align: center;
    line-height: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px #009bbd solid;
    padding: 20px 15px;
    height: 200px;
    width: 100%;
    justify-content: center;
    .box {
      width: 320px;
      margin-top: 20px;
      border: 1px #009bbd solid;
      font-size: 14px;
      .table-head {
        background-color: #009bbd;
        color: white;
        display: flex;
        text-align: center;
        div {
          width: 25%;
          border-right: 1px #fff solid;
          font-size: 12px;
        }
      }
      .score {
        display: flex;
        text-align: center;
        div {
          width: 33.3%;
          border-right: 1px #009bbd solid;
        }
      }
    }
  }
}
.footer {
    height: 100px;
    background-color: #18194f;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    align-items: center;
    padding: 10px;
  .text-div {
    font-size: 10px;
    .school-name {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: white;
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      height: 70%;
    }
    .img-text {
      font-size: 10px;
      text-align: center;
      line-height: 12px;
      margin-top: 3px;
    }
  }
}
.pop-layer{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .action-box{
      width: 250px;
      background-color: white;
      border-radius: 3px;
      text-align: center;
      div:nth-child(1){
        height: 60px;
        line-height: 60px;
        border-bottom: 1px #ddd solid;
      }
      div:nth-child(2){
        height: 50px;
        line-height: 50px;
        border-bottom: 1px #ddd solid;
        color: #F44336;
        font-size: 15px;
      }
      div:nth-child(3){
        height: 50px;
        line-height: 50px;
        color: #999;
      }
    }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
