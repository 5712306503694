<template>
  <div class="body-box">
    <div class="header">
      <div class="step-name" @click="changeTab('/queryresult')">初审结果查询</div>
      <div class="tab-box" v-if="tab === '/queryresult'">
        <div :class="[path === '/queryresult' ? 'active' : '']" @click="changeTab('/queryresult')">结果查询</div>
      </div>
      <div class="step-name" @click="changeTab('/payment')">报考费缴纳</div>
      <div class="tab-box" v-if="tab === '/payment'">
        <div :class="[path === '/payment' ? 'active' : '']" @click="changeTab('/payment')">缴费通道</div>
      </div>
    </div>
    <Queryresult v-if="path == '/queryresult'"></Queryresult>
    <Payment v-if="path == '/payment'"></Payment>
  </div>
</template>

<script>
import Queryresult from './Queryresult.vue';
import Payment from './Payment.vue';

export default {
  data() {
    return {
      path:"",
      tab:""
    };
  },
  created() {
    if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
      this.$router.push("mindex")
      return
    }
    // if(document.body.clientWidth <= 768){
    //    this.$router.push("mindex");
    //    return
    // }
    this.path = this.$route.path;
    switch(this.path){
      case '/queryresult':
        this.tab = '/queryresult';
        break;
      case '/payment':
        this.tab = '/payment';
        break;
      case '/intentionmajor':
        break;
      case '/aplyform':
        break;
    }
    console.log(this.path, this.tab);
  },
  methods: {
    changeTab(path) {
      if (this.path !== path) {
        // this.path = path;
        // this.$router.push(path);
      }
    }
  },
  components: {
    Queryresult,
    Payment
  }
};
</script>

<style lang="scss" scoped>
.body-box {
  min-height: 100%;
  background-color: white;
  padding: 40px 30px;
  height: 100%;
  min-width: 1080px;
  .header {
    display: flex;
    // border-bottom: 3px #ccc solid;
    border-bottom: 3px #c5cdd2 solid;
    font-weight: bold;
    width: 100%;
    height: 36px;
    position: relative;
    .step-name {
      font-size: 22px;
      padding: 0 10px;
      color: #18194f;
      cursor: pointer;
    }
    .tab-box {
      display: flex;
      font-size: 18px;
      height: 28px;
      margin-top: 5px;
      div {
        padding: 0 10px;
        // background-color: #e8e8e8;
        background-color: #e8ecee;
        margin: 0 10px;
        line-height: 30px;
        cursor: pointer;
        &.active {
          background-color: #de1b5c;
          color: white;
          cursor: default;
        }
      }
    }
    .tips-text {
      position: absolute;
      top: 50px;
      font-size: 16px;
      left: 10px;
    }
  }
  
  // .select-fade-enter-active {
  //   animation: fadein-down-in 0.3s;
  //   transform-origin: top;
  // }
  // .select-fade-leave-active {
  //   animation: fadein-down-in 0.3s reverse;
  //   transform-origin: top;
  // }
  // @keyframes fadein-down-in {
  //   0% {
  //     transform: scaleY(0.9);
  //     opacity: 0;
  //   }
  //   100% {
  //     transform: scaleY(1);
  //     opacity: 1;
  //   }
  // }
}
</style>
