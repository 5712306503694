<template>
  <div>
    <div class="header">
      <img src="../../assets/whitetext.png" style="height: 100%" />
    </div>
    <div class="back-btn" @click="goHome">回到首页</div>
    <div class="content" ref="getheight" :style="'height:'+(height-180)+'px;'+(screenHeightSamll?'align-items:normal':'')">
      <div class="progress-box">
        <div class="item">
          <div :class="[issubmit?'active':'']">1</div>
          <div>申请提交<span>{{submit}}</span></div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div :class="[isupload == true ?'active':'']">2</div>
          <div>材料上传<span>{{upload}}</span></div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div :class="[isresult?'active':'']">3</div>
          <div>初审结果<span>{{result}}</span></div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div :class="[ispayment?'active':'']">4</div>
          <div>缴费状态<span>{{payment}}</span></div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div :class="[isaddmission?'active':'']">5</div>
          <div>准考证<span>{{addmission}}</span></div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div :class="[isscore?'active':'']">6</div>
          <div>成绩查询<span>{{score}}</span></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div>招生热线：0574-88180182</div>
        <div>学校地址：浙江省宁波市泰康东路199号</div>
        <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
        </div>
        <div>招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
            >Gaokao@nottingham.edu.cn</a
          >
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../../assets/qr.png" style="height: 100%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submit: '',
      issubmit: false,
      upload: '',
      isupload: false,
      result: '',
      isresult: false,
      payment: '',
      ispayment: false,
      addmission: '',
      isaddmission: false,
      score: '',
      isscore: false,
      screenHeightSamll: false,
      height: `${document.documentElement.clientHeight}`,
      loading: false
    };
  },
  created(){
    this.getApplyStatus();
    setTimeout(() => {
      if(this.$refs.getheight.offsetHeight - 180 < 275){
        this.screenHeightSamll = true;
      }
      console.log(this.screenHeightSamll)
    }, 50);
  },
  methods: {
    goHome(){
      this.$router.push("mindex");
    },
    getApplyStatus() {
      this.loading = true;
      this.$api
        .getApplyStatus()
        .then((res) => {
          this.loading = false;
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus == 1 || res.data.applyStatus == 3) {
              this.submit = '（未提交）'
              this.issubmit = true
            } else if (res.data.applyStatus == 2) {
              this.submit = '（已提交）'
              this.upload = '（未上传）'
              this.isupload = true
            } else if (res.data.applyStatus == 4) {
              this.submit = '（已提交）'
              this.upload = '（已上传）'
              this.result = '（未公布）'
              this.isresult = true
            } else if (res.data.applyStatus == 5 || res.data.applyStatus == 6) {
              this.submit = '（已完成）'
              this.upload = '（已完成）'
              if(res.data.applyStatus == 5){
                this.result = '（初审通过）'
                this.payment = '（未缴费）'
                this.ispayment = true
              }else{
                this.result = '（初审未通过）'
                this.isresult = true
              }
            } else if (res.data.applyStatus == 8) {
              this.submit = '（已完成）'
              this.upload = '（已完成）'
              this.result = '（初审通过）'
              this.payment = '（待缴费）'
              this.ispayment = true
            } else if (res.data.applyStatus == 7) {
              this.submit = '（已完成）'
              this.upload = '（已完成）'
              this.result = '（初审通过）'
              this.payment = '（已缴费）'
              this.ispayment = true
              this.getAdmissionLimits()
            } else if (res.data.applyStatus == 9) {
              this.submit = '（已完成）'
              this.upload = '（已完成）'
              this.result = '（初审通过）'
              this.payment = '（已缴费）'
              this.addmission = '（可打印）'
              this.isaddmission = true
            } else if (
              res.data.applyStatus >= 10
            ) {
              this.submit = '（已完成）'
              this.upload = '（已完成）'
              this.result = '（初审通过）'
              this.payment = '（已缴费）'
              this.addmission = '（可打印）'
              this.score = '（可查询）'
              this.isscore = true
            }
          } else {
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAdmissionLimits() {
      this.$api
        .getProgressList({
          code: "code_7",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            return
          }
          this.ispayment = false
          this.addmission = '（可打印）'
          this.isaddmission = true
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang='scss' scoped>
.header {
  height: 70px;
  background-color: #18194f;
  padding-bottom: 10px;
}
.back-btn{
  width: 85px;
  height: 35px;
  background-color: #009bbd;
  position: absolute;
  left: 0;
  line-height: 35px;
  color: white;
  text-align: center;
  z-index: 1;
  top: 85px;
}
.content {
  position: relative;
  height: calc(100vh - 180px);
  overflow-y: scroll;
  background-color: #fff;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 10px;
  .progress-box{
    .item{
      display: flex;
      width: 265px;
      align-items: center;
      div:nth-child(1){
        background-color: #999;
        width: 60px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        color: white;
        font-weight: bold;
        margin-right: 50px;
        &.active{
          background-color: #de1b5c;
        }
      }
    }
    .line{
      border-right: 3px #ddd dashed;
      height: 25px;
      width: 32px;
    }
  }

}
.footer {
    height: 100px;
    background-color: #18194f;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    align-items: center;
    padding: 10px;
  .text-div {
    font-size: 10px;
    .school-name {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: white;
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      height: 70%;
    }
    .img-text {
      font-size: 10px;
      text-align: center;
      line-height: 12px;
      margin-top: 3px;
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>