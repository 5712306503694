<template>
  <div class="outer-div" :style="overflow" @click="reset" @mouseenter="reset">
    <div class="print-btn" @click.stop="printExamWord">准考证打印</div>
    <div class="content">
      <div class="page" ref="print" id="printConss">
        <div class="logo">
          <img src="../../assets/bluetext.png" width="200" />
        </div>
        <div class="title">
          {{title}}
        </div>
        <div class="user-info">
          <div class="text">
            <div><span v-html="name"></span><span>{{applyInfo.realName}}</span></div>
            <div><span v-html="pinyin"></span><span>{{applyInfo.namePinyin}}</span></div>
            <div><span>身份证号 </span><span>{{applyInfo.cardNo}}</span></div>
            <div><span>准考证号</span><span>{{applyInfo.examNo}}</span></div>
            <div><span>笔试时间</span><span>{{applyInfo.handleDatetime}}</span></div>
            <div><span>笔试地点</span><span>{{applyInfo.handlePosition}}</span></div>
          </div>
          <div class="pic">
            <img :src="applyInfo.imgUrl" alt="" />
          </div>
        </div>
        <div class="notice">
          <div class="title-text">
            <img src="../../assets/ksxz-img.png" class="bg-img" />.
            <!-- <img src="../../assets/ksxz.png" class="text-img" />1 -->
          </div>
          <div class="content-box">
            <div
              class="item"
              style="border-left: 1px #18194f solid; width: 55%"
            >
              <div v-html="knowLeft"></div>
            </div>
            <div class="item" style="line-height: 22px">
              <div v-html="knowRight"></div>
            </div>
          </div>
          <div class="qr">
            <img src="../../assets/qr.png" alt="" />
            <div>
              关注宁波诺丁汉大学招生办微信公众号<br />
              掌握更多最新招生资讯
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import print from "print-js";
export default {
  data() {
    return {
      name: "姓&#8195;&#8195;名",
      pinyin: "拼&#8195;&#8195;音",
      overflow: "",
      applyInfo:{},
      knowLeft:"",
      knowRight:"",
      title:"",
      loading: false
    };
  },
  mounted() {
    if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
      this.$router.push("mindex")
      return
    }
    // if(document.body.clientWidth <= 768){
    //    this.$router.push("mindex");
    //    return
    // }
    window.addEventListener("scroll", this.reset);
    this.getExamCardInfo();
  },
  methods: {
    print() {
      this.overflow = 'overflow:visible';
      // setTimeout(() => {
      // this.$print(this.$refs.print);
      // }, 300);
      print({
        printable: "printConss",
        type: "html",
        // style: '@page {} .title{ font-size:24px }',
        // scanStyles:false,
        targetStyles: ["*"],
        // css:'../../assets/admissionPrint.css'
      });
    },
    printExamWord() {
      this.$api
        .printExamWord()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            window.open(res.data.filePath, "_blank");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reset() {
      this.overflow = "overflow:scroll";
      console.log(111);
    },
    getExamCardInfo(){
      this.loading = true;
      this.$api
          .getExamCardInfo()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.applyInfo = res.data.applyInfo;
              this.knowLeft = res.data.knowLeft;
              this.knowRight = res.data.knowRight;
              this.title = res.data.title;
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getContentConfigList(name){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:name
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                this.content = res.data.configList[item].content;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  },
};
</script>

<style lang='scss' scoped>
@page {
  margin: 0;
}
.outer-div {
  width: 100%;
  height: calc(100% - 20px);
  overflow-y: scroll;
  min-width: 1080px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.print-btn {
  color: #009bbd;
  border: 1px #009bbd solid;
  height: 40px;
  line-height: 41px;
  width: 150px;
  margin-left: auto;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
  line-height: 40px;
  &:hover {
    background-color: #009bbd;
    color: #015597;
    border: 1px #009bbd solid;
    color: white;
  }
}
.content {
  font-size: 24px;
  text-align: center;
  line-height: 2;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  .page {
    position: relative;
    width: 800px;
    // padding-right: 60px;
    // margin-left: auto;
    // margin-right: auto;
    // margin-top: -70px;
    .logo {
      display: flex;
      img {
        width: 200px;
        position: absolute;
        left: 0;
      }
    }
    .title {
      margin-top: 100px;
      font-size: 16px;
      font-weight: bold;
      transform: scale(1.5);
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    .user-info {
      display: flex;
      justify-content: space-between;
      .text {
        text-align: left;
        line-height: 27px;
        margin-top: 30px;
        transform: scale(1.125);
        transform-origin: left;
        span:nth-child(1) {
          font-size: 18px;
          width: 100px;
          display: inline-block;
        }
        span:nth-child(2) {
          font-size: 18px;
          margin-left: 20px;
        }
      }
      .pic {
        img {
          width: 130px;
          height: 180px;
          margin-top: 30px;
        }
      }
    }
    .notice {
      .title-text {
        margin-top: 20px;
        position: relative;
        height: 45px;
        line-height: 45px;
        .bg-img {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
        }
        .text-img {
          height: 45%;
          position: relative;
          z-index: 1;
        }
      }
      .content-box {
        display: flex;
        /deep/ p{
          margin-bottom: 0 !important;
        }
        /deep/ a{
          word-wrap: break-word;
          display:block;
        }
        /deep/ table{
          border: 1px solid;
          tr{
            border: 1px solid;
            td{
              border: 1px solid;
              padding-left: 10px;
            }
          }
        }
        .item {
          font-size: 16px;
          text-align: left;
          width: 50%;
          line-height: 20px;
          padding: 8px;
          border-right: 1px #18194f solid;
          border-bottom: 1px #18194f solid;
          .label {
            font-weight: bold;
            display: block;
            text-decoration: underline;
          }
          .strong {
            font-weight: bold;
          }
        }
      }
    }
    .qr {
      margin-top: 20px;
      div {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>