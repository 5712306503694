<template>
  <div style="min-width:1366px">
    <div class="header">
      <div style="display: flex;align-items: center;height: 100%;">
	      <img src="../../assets/whitetext.png" style="height: 100%;padding-bottom: 19px;" />
	      <div class="title">
		      宁波诺丁汉大学三位一体综合评价招生报名系统<br />
		      Tri-Unity Scheme Test Applicant Portal
	      </div>
      </div>
	    <div style="display: flex;align-items: center;">
	      <div class="noticeclass">
		      <div class="c-ml-30 sf-wrap" @click="(e) => e.preventDefault()">
			      <a-popover title="">
				      <template #content>
					      <a-tabs centered>
						      <a-tab-pane key="1" :tab="'通知(' + unReadMsgNumb + ')'">
							      <div v-if="noticeList" class="notice-wrap">
								      <div v-for="(item, index) in noticeList" :key="index" class="c-flex c-flex-align-center c-flex-just-between c-mb-10" @click="openDetail(item.id, item.noticeId)">
									      <a :style="`color: ` + (item.isRead === '0' ? '#000000' : '#a0a0a1')"  class="notice-title primary-color"><span :style="`background-color: ` + (item.isRead === '0' ? '#FF0000' : '#a0a0a1') + ';margin-right:5px'" class="dot"></span> {{ transTitle(item.title) }}</a>
									      <a style="width: 60px" class="notice-action">去查看</a>
								      </div>
							      </div>
						      </a-tab-pane>
					      </a-tabs>
				      </template>
				      <div style="display: flex;">
					      <a-badge :count="unReadMsgNumb" dot>
						      <!--                <svg focusable="false" class="" data-icon="bell" width="2em" height="2em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">-->
						      <!--                  <path d="M816 768h-24V428c0-141.1-104.3-257.7-240-277.1V112c0-22.1-17.9-40-40-40s-40 17.9-40 40v38.9c-135.7 19.4-240 136-240 277.1v340h-24c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 26.5-21.5 48-48 48zM304 768V428c0-55.6 21.6-107.8 60.9-147.1S456.4 220 512 220c55.6 0 107.8 21.6 147.1 60.9S720 372.4 720 428v340H304z"></path>-->
						      <!--                </svg>-->
						      <div style="display:flex;align-items: center;font-size: 20px;margin-right: 12px;">
							      <svg role="img" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" aria-labelledby="envelopeAltIconTitle" stroke="#ffffff" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#2329D6"> <title id="envelopeAltIconTitle">Envelope</title> <rect width="20" height="14" x="2" y="5"/> <path stroke-linecap="round" d="M2 5l10 9 10-9"/> </svg>
							      <span style="margin-left: 6px;">站内信</span>
						      </div>
					      </a-badge>
				      </div>
			      </a-popover>
		      </div>
	      </div>
	      <div
		      class="username"
		      @mouseenter="showUserMenu"
		      @mouseleave="hideUserMenu"
	      >
		      <i class="iconfont icon-touxiang1"></i>
		      {{ name }}
		      <i class="iconfont icon-down"></i>
		      <transition name="usermenu-fade">
			      <div class="user-menu" v-if="userMenu">
				      <div @click="openReset">
					      <i class="iconfont icon-xiugaimima-"></i>修改密码
				      </div>
				      <div @click="logout">
					      <i class="iconfont icon-tuichudenglu"></i>退出登录
				      </div>
			      </div>
		      </transition>
	      </div>
		    <div class="mobile-menu">
			    <i class="iconfont icon-menu"></i>
		    </div>
      </div>
    </div>
    <div class="body">
      <div class="left-menu">
        <div
          class="menu-item"
          @click="selectMenu('/')"
          :class="[selectMenuPath === '/' ? 'active' : '']"
        >
          <i class="iconfont icon-shouye"></i>
          <span>首 页</span>
        </div>
        <div
          class="menu-item"
          :style="showApplyMenu ? 'height: 260px' : 'height: 60px;'"
          @click="changeApplyMenu"
          :class="[isApplyMenuActive ? 'active' : '']"
        >
          <i class="iconfont icon-shenqingbiao"></i>
          <span>申 请 表</span>
          <i
            class="iconfont icon-down"
            :style="showApplyMenu ? 'transform: rotate(180deg);' : ''"
          ></i>
          <transition name="submenu-fade">
            <div class="submenu" v-if="showApplyMenu" @click.stop>
              <div
                class="submenu-item"
                @click="selectMenu('/userinfo')"
                :class="[
                  selectMenuPath === '/userinfo' ||
                  selectMenuPath === '/contactinfo' ||
                  selectMenuPath === '/parentsinfo' ||
                  selectMenuPath === '/highschoolinfo' ||
                  selectMenuPath === '/highschoolscore'
                    ? 'active'
                    : '',
                ]"
              >
                基本信息
              </div>
              <div
                class="submenu-item"
                @click="selectMenu('/intentionmajor')"
                :class="[selectMenuPath === '/intentionmajor' ? 'active' : '']"
              >
                报考意向
              </div>
              <div
                class="submenu-item"
                @click="selectMenu('/infoconfirmation')"
                :class="[
                  selectMenuPath === '/infoconfirmation' ||
                  selectMenuPath === '/commitment' ||
                  selectMenuPath === '/aplyform'
                    ? 'active'
                    : '',
                ]"
              >
                申请表提交
              </div>
              <div
                class="submenu-item"
                @click="selectMenu('/uploadmaterial')"
                :class="[selectMenuPath === '/uploadmaterial' ? 'active' : '']"
              >
                佐证材料上传
              </div>
            </div>
          </transition>
        </div>
        <div
          class="menu-item"
          @click="selectMenu('/queryresult')"
          :class="[selectMenuPath === '/queryresult' ? 'active' : '']"
        >
          <i class="iconfont icon-yunli-chushen"></i>
          <span>初审结果查询</span>
        </div>
        <div
          class="menu-item"
          @click="selectMenu('/payment')"
          :class="[selectMenuPath === '/payment' ? 'active' : '']"
        >
          <i class="iconfont icon-180logo_weixinzhifu"></i>
          <span>缴 费</span>
        </div>
        <div
          class="menu-item"
          @click="selectMenu('/admissiondownload')"
          :class="[selectMenuPath === '/admissiondownload' ? 'active' : '']"
        >
          <i class="iconfont icon-zhengjian"></i>
          <span>准 考 证</span>
        </div>
        <div
          class="menu-item"
          :style="showScoreMenu ? 'height: 160px' : 'height: 60px;'"
          @click="changeScoreMenu"
          :class="[isScoreMenuActive ? 'active' : '']"
        >
          <i class="iconfont icon-chaxun"></i>
          <span>成绩查询</span>
          <i
            class="iconfont icon-down"
            :style="showScoreMenu ? 'transform: rotate(180deg);' : ''"
          ></i>
          <transition name="submenu-fade">
            <div class="submenu" v-if="showScoreMenu" @click.stop>
              <div
                class="submenu-item"
                @click="selectMenu('/writtenexamination')"
                :class="[
                  selectMenuPath === '/writtenexamination' ? 'active' : '',
                ]"
              >
                笔试成绩
              </div>
              <div
                class="submenu-item"
                @click="selectMenu('/totalexamination')"
                :class="[
                  selectMenuPath === '/totalexamination' ? 'active' : '',
                ]"
              >
                综合测试成绩
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
    <div class="reset-pwd-layer" v-if="showResetLayer">
      <div class="reset-pwd-box">
        <div class="title">修改密码</div>
        <div class="input-box">
          <i class="iconfont icon-shenfenzheng"></i>
          <input type="text" placeholder="请输入身份证号" v-model="cardNo" />
        </div>
        <div class="input-box">
          <i class="iconfont icon-shoujihao"></i>
          <input type="text" placeholder="请输入手机号" v-model="mobile" />
        </div>
        <div class="input-box">
          <i class="iconfont icon-yanzhengma"></i>
          <input type="text" placeholder="请输入验证码" v-model="code" />
          <div
            class="mobile-code"
            @click="sendVerificationCode"
            :class="[sended ? 'ban' : '']"
          >
            {{ sended ? seconds + "s 后重新发送" : "发送验证码" }}
          </div>
        </div>
        <div class="input-box">
          <i class="iconfont icon-mima"></i>
          <input
            type="password"
            placeholder="请输入新密码"
            v-model="password"
          />
        </div>
        <div class="input-box">
          <i class="iconfont icon-mima"></i>
          <input
            type="password"
            placeholder="再次输入新密码"
            v-model="password2"
          />
        </div>
        <div class="btn-box">
          <div class="forget-password" @click="closeReset">
            <span>取消</span>
          </div>
          <div class="login" @click="resetPwd"><span>确定</span></div>
        </div>
      </div>
    </div>
    <div class="pop-layer notice-layer" v-if="showNotice" @click="closeDetail">
      <div class="box notice-box" :style="scrollY?'overflow-x:scroll':''">
        <div class="title">
          <div>通知</div>
          <div>
            <i
                data-v-8b6adaf6=""
                class="iconfont icon-guanbi1"
                @click="closeDetail"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="addtion">通知时间：{{ noticeInfo.createDatetime }}</div>
          <div class="content-box">
            <div class="content-title">{{ noticeInfo.title }}</div>
            <div v-html="noticeInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
// import { BellOutlined } from '@ant-design/icons-vue';
export default {
  // components: {
  //   BellOutlined
  // },
  data() {
    return {
      unReadMsgNumb: 0,
      scrollY:false,
      noticeInfo: {},
      showNotice: false,
      noticeList: [],
      name: this.$store.state.name,
      selectMenuPath: "/",
      showApplyMenu: this.$store.state.showApplyMenu,
      isApplyMenuActive: false,
      showScoreMenu: this.$store.state.showScoreMenu,
      isScoreMenuActive: false,
      userMenu: false,
      applyStatus: 1,
      processStatus: 0,
      cardNo: "",
      mobile: "",
      code: "",
      password: "",
      password2: "",
      showResetLayer: false,
      sended: false,
      timer: "",
      seconds: 60,
      // applyLimitsStatus: 0,
      // uploadLimitsStatus: 0,
      // payLimitsStatus: 0,
      // admissionLimitsStatus: 0,
      // scoreLimitsStatus: 0,
      progress:{},
      loading: false
    };
  },
  created() {
    if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
      this.$router.push("mindex")
      return
    }
    if(document.body.clientWidth <= 650){
      this.scrollY = true
    }else{
      this.scrollY = false
    }
    this.articleH = document.body.clientHeight - 672 < 180 ? 180 : document.body.clientHeight - 672;
    this.noticeH = document.body.clientHeight - 637 < 215 ? 215 : document.body.clientHeight - 637;
    window.onresize = () => {
      if(document.body.clientWidth <= 650){
        this.scrollY = true
      }else{
        this.scrollY = false
      }
      this.articleH = document.body.clientHeight - 672 < 180 ? 180 : document.body.clientHeight - 672;
      this.noticeH = document.body.clientHeight - 637 < 215 ? 215 : document.body.clientHeight - 637;
    }
    // this.getApplyLimits();
    // this.getUploadLimits();
    // this.getPayLimits();
    // this.getAdmissionLimits();
    // this.getScoreLimits();
    this.getNoticeList(false);
    this.getProgressMap();
    this.getApplyTime();
    // this.getExistApply();
    this.selectMenuPath = this.$route.path;
    if (
      this.selectMenuPath == "/userinfo" ||
      this.selectMenuPath == "/contactinfo" ||
      this.selectMenuPath == "/parentsinfo" ||
      this.selectMenuPath == "/highschoolinfo" ||
      this.selectMenuPath == "/highschoolscore" ||
      this.selectMenuPath == "/intentionmajor" ||
      this.selectMenuPath == "/infoconfirmation" ||
      this.selectMenuPath == "/commitment" ||
      this.selectMenuPath == "/aplyform" ||
      this.selectMenuPath == "/uploadmaterial"
    ) {
      this.isApplyMenuActive = true;
      this.showApplyMenu = true;
      this.$store.commit("setShowApplyMenu", this.showApplyMenu);
    } else if (
      this.selectMenuPath == "/writtenexamination" ||
      this.selectMenuPath == "/totalexamination"
    ) {
      this.isScoreMenuActive = true;
      this.showScoreMenu = true;
      this.$store.commit("setShowScoreMenu", this.showScoreMenu);
    }
  },
  watch: {
    seconds() {
      if (this.seconds <= 0) {
        clearInterval(this.timer);
        this.sended = false;
      }
    },
    "$store.state.name": function () {
      this.name = this.$store.state.name;
    },
    mobile(n,o){
      if(n.length>11){
        this.mobile = o;
      }
    }
  },
  methods: {
    transTitle(title) {
      if (title && title.length > 20) {
        return title.substring(0,17) + '...'
      }
      return title
    },
    closeDetail() {
      this.$api
          .readNotice({
            id: this.noticeInfo.id
          })
      this.showNotice = false
      this.getNoticeList(false);
    },
    openDetail(receiverId) {
      this.$api
          .sysNoticeInfo({
            id: receiverId
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.noticeInfo = res.data.sysNoticeInfo;
              this.showNotice = true
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getNoticeList(needJudgeAlways) {
      var that = this
      this.$api
          .getNoticeList()
          .then((res) => {
            if (res.data.code == 0) {
              that.noticeList = res.data.page.list;
              if (that.noticeList) {
                that.unReadMsgNumb = that.noticeList.filter(t => t.isRead === '0').length
              } else {
                that.unReadMsgNumb = 0
              }
              if (needJudgeAlways) {
                // 判断是否需要弹框
                var alwaysList = that.noticeList.filter(t => t.isAlways === '1')
                if (alwaysList.length > 0) {
                  that.openDetail(alwaysList[0].id)
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getExistApply() {
      this.$api
        .getExistApply()
        .then((res) => {
          if (res.data.code == 0) {
            this.name = res.data.exixtApply.realName;
            this.$store.commit("setName", this.name);
          }
        })
        .catch((err) => {
          // _that.loading = false;
          console.log(err);
        });
    },
    getProgressMap() {
      this.$api
        .getProgressMap()
        .then((res) => {
          // console.log(res);
          if(res.data.result.code_10 == 2){
            this.$router.push("systemClose");
            return;
          }
          this.progress = res.data.result
          // console.log(this.progress);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // getApplyLimits() {
    //   this.$api
    //     .getProgressList({
    //       code: "code_3",
    //     })
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data.code == 409) {
    //         this.applyLimitsStatus = 409;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // getUploadLimits() {
    //   this.$api
    //     .getProgressList({
    //       code: "code_5",
    //     })
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data.code == 409) {
    //         this.uploadLimitsStatus = 409;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // getPayLimits() {
    //   this.$api
    //     .getProgressList({
    //       code: "code_6",
    //     })
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data.code == 409) {
    //         this.payLimitsStatus = 409;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // getAdmissionLimits() {
    //   this.$api
    //     .getProgressList({
    //       code: "code_7",
    //     })
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data.code == 409) {
    //         this.admissionLimitsStatus = 409;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // getScoreLimits() {
    //   this.$api
    //     .getProgressList({
    //       code: "code_8",
    //     })
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data.code == 409) {
    //         this.scoreLimitsStatus = 409;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    selectMenu(path) {
      if (this.selectMenuPath !== path) {
        if (
          (path == "/userinfo" ||
            path == "/intentionmajor" ||
            path == "/infoconfirmation" ||
            path == "/uploadmaterial") &&
          this.progress.code_3 == 2
        ) {
          this.$message.warning("现已暂停申请，请等待开启！");
          return;
        }
        if (path == "/uploadmaterial" && this.progress.code_5 == 2) {
          this.$message.warning("现已暂停上传佐证材料，请等待开启！");
          return;
        }
        if (path == "/payment" && this.progress.code_6 == 2) {
          this.$message.warning("现已暂停缴费，请等待开启！");
          return;
        }
        if (path == "/admissiondownload" && this.progress.code_7 == 2) {
          this.$message.warning("现已暂停下载准考证，请等待开启！");
          return;
        }
        if (
          (path == "/writtenexamination" || path == "/totalexamination") &&
          this.progress.code_8 == 2
        ) {
          this.$message.warning("现已暂停成绩查询，请等待开启！");
          return;
        }
        if (path == "/uploadmaterial") {
          this.getApplyStatus(path);
          return;
        }
        if (path == "/intentionmajor" || path == "/infoconfirmation") {
          this.getProcessStatus(path);
          return;
        }
        if (path == "/queryresult") {
          this.getQueryresultStatus(path);
          return;
        }
        if (path == "/payment") {
          this.getPaymentStatus(path);
          return;
        }
        if (path == "/admissiondownload") {
          this.getAdmissionStatus(path);
          return;
        }
        if (path == "/writtenexamination") {
          this.getWriteStatus(path);
          return;
        }
        if (path == "/totalexamination") {
          this.getTotalStatus(path);
          return;
        }
        this.selectMenuPath = path;
        this.$router.push(path);
      }
    },
    changeApplyMenu() {
      this.showApplyMenu = !this.showApplyMenu;
      // console.log("this: ", this.showApplyMenu);
      this.$store.commit("setShowApplyMenu", this.showApplyMenu);
      // console.log("store: ", this.$store.state.showApplyMenu);
    },
    changeScoreMenu() {
      this.showScoreMenu = !this.showScoreMenu;
      // console.log("this: ", this.showScoreMenu);
      this.$store.commit("setShowScoreMenu", this.showScoreMenu);
      // console.log("store: ", this.$store.state.showScoreMenu);
    },
    logout() {
      this.$store.commit("delUser");
      this.$router.push("login");
    },
    showUserMenu() {
      this.userMenu = true;
    },
    hideUserMenu() {
      this.userMenu = false;
    },
    getApplyStatus(path) {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus < 3 && res.data.applyStatus != 2) {
              this.$message.warning("请先提交申请！");
            } else {
              this.selectMenuPath = path;
              this.$router.push(path);
            }
          } else {
            this.$message.warning("请先提交申请！");
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProcessStatus(path) {
      this.$api
        .getInfoStatus()
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            if (path == "/intentionmajor" && res.data.infoStatus < 6) {
              this.$message.warning("请先完成基本信息的填写！");
            } else if (
              path == "/infoconfirmation" &&
              res.data.infoStatus < 7
            ) {
              this.$message.warning("请先完成基本信息和报考意向的填写！");
            } else {
              this.selectMenuPath = path;
              this.$router.push(path);
            }
          } else {
            if (path == "/intentionmajor") {
              this.$message.warning("请先完成基本信息的填写！");
            } else if (path == "/infoconfirmation") {
              this.$message.warning("请先完成基本信息和报考意向的填写！");
            }
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQueryresultStatus(path) {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus < 4 && res.data.applyStatus != 2) {
              this.$message.warning("请先提交申请！");
            } else {
              // 检查材料是否齐全
              this.$api
                .getMaterialStatus()
                .then((res) => {
                  // console.log(res);
                  if (res.data.code == 0) {
                    if (res.data.materialStatus == 1) {
                      this.selectMenuPath = path;
                      this.$router.push(path);
                    } else {
                      this.$message.warning("请先完成佐证材料的上传");
                    }
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            this.$message.warning("请先提交申请！");
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPaymentStatus(path) {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus < 4 && res.data.applyStatus != 2) {
              this.$message.warning("请先提交申请！");
            } else if (res.data.applyStatus < 7 && res.data.applyStatus != 5) {
              this.$message.warning("请先通过初审！");
            } else {
              this.selectMenuPath = path;
              this.$router.push(path);
            }
          } else {
            this.$message.warning("请先提交申请！");
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAdmissionStatus(path) {
      this.$api
        .getExamNoStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.selectMenuPath = path;
            this.$router.push(path);
          } else {
            this.$message.warning(res.data.msg);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getWriteStatus(path) {
      this.$api
        .getHandleScoreStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.selectMenuPath = path;
            this.$router.push(path);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTotalStatus(path) {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            if (res.data.applyStatus < 12) {
              this.$message.warning("无数据！");
            } else {
              this.selectMenuPath = path;
              this.$router.push(path);
            }
          } else {
            this.$message.warning("无数据！");
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendVerificationCode() {
      if (this.sended) {
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message.warning("请输入正确手机号");
        return;
      }
      if (this.mobile) {
        this.sended = true;
        this.seconds = 60;
        this.timer = setInterval(() => {
          this.seconds--;
        }, 1000);
        this.$api
          .sendVerificationCode({
            mobile: this.mobile,
            type:2
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message.success("短信验证码已发送，请注意查收");
            } else {
              clearInterval(this.timer);
              this.sended = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("手机号不能为空");
      }
    },
    resetPwd() {
      if (this.mobile && this.code && this.password && this.cardNo) {
        // 身份证验证
        if (
          !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            this.cardNo
          )
        ) {
          this.$message.warning("请输入正确身份证号");
          return;
        } else if (this.cardNo.indexOf("x") > -1) {
          this.$message.warning("请输入大写的X字母");
          return;
        }
        // 手机验证
        if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        // 密码验证
        if (this.password.length < 8) {
          this.$message.warning("密码至少含8个字符");
          return;
        }
        if (
          !/^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{8,}$/.test(this.password)
        ) {
          this.$message.warning("密码必须含英文字符和数字");
          return;
        }
        if (this.password != this.password2) {
          this.$message.warning("两次密码输入不一致");
          return;
        }
        this.loading = true;
        this.$api
          .forgetPassword({
            cardNo: this.cardNo,
            code: this.code,
            mobile: this.mobile,
            password: this.password,
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              this.showResetLayer = false
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("输入不能为空");
      }
    },
    closeReset() {
      this.showResetLayer = false;
    },
    openReset() {
      this.showResetLayer = true;
    },
    getApplyTime() {
      this.$api
        .contentConfigList({
          categoryName: "内容配置",
          name: "报名结束时间",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            var s1 = res.data.configList[0].content;
            s1 = new Date(s1.replace(/-/g, "/"));
            var s2 = new Date();
            var days = s1.getTime() - s2.getTime();
            if (days < 0) {
              this.$store.commit("setApplyTime", 0);
            } else {
              this.$store.commit("setApplyTime", 1);
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.dot {
  height: 8px;
  width: 8px;
  background-color: #ff0000;
  border-radius: 50%;
  margin: auto;
  display: inline-block;
}
.header {
  height: 104px;
  background-color: #18194f;
  //position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
  .title {
    font-size: 24px;
    display: inline-block;
    color: white;
    //position: absolute;
    line-height: 30px;
    margin: 22px;
    min-width: 510px;
    margin-left: 70px;
  }
  .noticeclass {
      //position: absolute;
      // float: right;
      //right: 0;
      color: white;
      //top: 0;
      //line-height: 104px;
      //margin-right: 160px;
      font-size: 20px;
      display: flex;
      cursor: pointer;
      i:nth-child(1) {
        font-size: 36px;
        margin-right: 10px;
      }
      i:nth-child(2) {
        font-size: 22px;
        margin-left: 10px;
      }
      @keyframes usermenu-fade-in {
        0% {
          transform: scaleY(0.8) translateY(-5px);
          opacity: 0;
        }
        100% {
          transform: scaleY(1) translateY(0px);
          opacity: 1;
        }
      }
  }
  .username {
    //position: absolute;
    // float: right;
    //right: 0;
    color: white;
	  display: flex;
	  align-items: center;
    //top: 0;
    //line-height: 104px;
    margin-left: 10px;
    font-size: 20px;
    //display: flex;
    cursor: pointer;
    i:nth-child(1) {
      font-size: 36px;
      margin-right: 10px;
    }
    i:nth-child(2) {
      font-size: 22px;
      margin-left: 10px;
    }
    .user-menu {
      color: #333;
      font-size: 18px;
      background-color: white;
      box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      line-height: 50px;
      position: absolute;
      // float: right;
      margin-left: -160px;
      // margin-top: 90px;
      top: 90px;
      width: 160px;
      right: 0px;
      z-index: 1;
      overflow: hidden;
      transform-origin: top;
      div {
        display: flex;
        justify-content: center;
        i {
          font-size: 24px;
        }
        &:hover {
          background-color: #addde8;
        }
      }
    }
    .usermenu-fade-enter-active {
      animation: usermenu-fade-in 0.3s;
    }
    .usermenu-fade-leave-active {
      animation: usermenu-fade-in 0.3s reverse;
    }
    @keyframes usermenu-fade-in {
      0% {
        transform: scaleY(0.8) translateY(-5px);
        opacity: 0;
      }
      100% {
        transform: scaleY(1) translateY(0px);
        opacity: 1;
      }
    }
  }
}
.body {
  display: flex;
  .left-menu {
    width: 260px;
    // background-color: #ddd;
    background-color: #e8ecee;
    height: calc(100vh - 104px);
    position: relative;
    overflow-y: scroll;
    // border-right: 1px #ddd solid;
    border-right: 1px #e8ecee solid;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    /*竖向滚动条*/
    &::-webkit-scrollbar-thumb:vertical {
      height: 5px;
      background-color: #ddd;
      -webkit-border-radius: 0px;
    }
    .menu-item {
      height: 60px;
      // border-top: 1px #ddd dashed;
      font-size: 20px;
      padding-left: 30px;
      line-height: 60px;
      // background-color: #eee;
      background-color: white;
      transition: 0.5s all ease-in-out;
      cursor: pointer;
      i {
        font-size: 22px;
        margin-right: 10px;
      }
      &.active {
        background-color: #009bbd;
        color: white;
        cursor: default;
        i {
          font-weight: bold;
        }
        &:hover {
          background-color: #009bbd;
        }
      }
      &:hover {
        // background-color: #009bbd;
        background-color: #addde8;
        // color: white;
      }
      .icon-down {
        float: right;
        margin-right: 30px;
      }
      .submenu {
        // background-color: #dddddd;
        background-color: #e1ebf1;
        position: absolute;
        width: 100%;
        left: 0;
        color: #333;
        transform-origin: top;
        div {
          font-size: 18px;
          height: 50px;
          line-height: 50px;
          padding-left: 62px;
          cursor: pointer;
          &:hover {
            // background-color: #00bcd4;
            background-color: #addde8;
            // color: white;
          }
          &.active {
            background-color: #00bcd4;
            color: white;
            &:hover {
              background-color: #00bcd4;
            }
          }
        }
      }
      .submenu-fade-enter-active {
        animation: submenu-fade-in 0.5s;
      }
      .submenu-fade-leave-active {
        animation: submenu-fade-in 0.5s reverse;
      }
      @keyframes submenu-fade-in {
        0% {
          transform: scaleY(0);
        }
        100% {
          transform: scaleY(1);
        }
      }
    }
  }
  .content {
    // background-color: #f5f5f5;
    // background-color: #f3f6f8;
    background-color: #f1f5f7;
    width: calc(100% - 260px);
    padding: 20px;
    max-height: calc(100vh - 104px);
  }
}
.reset-pwd-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  .reset-pwd-box {
    border-radius: 5px;
    width: 550px;
    background-color: white;
    position: relative;
    .title {
      font-size: 20px;
      margin: 20px 30px;
    }
    .input-box {
      border: 1px #009bbd solid;
      margin: 30px;
      height: 50px;
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      position: relative;
      &:hover {
        border: 2px #009bbd solid;
        padding: 0 9px;
      }
      i {
        font-size: 26px;
        color: #999;
      }
      input {
        border: 0;
        height: 100%;
        width: 100%;
        margin-left: 10px;
        outline: none;
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #bbb;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #bbb;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #bbb;
        }
      }
    }
    .mobile-code {
      color: white;
      background-color: #009bbd;
      border: 2px #009bbd solid;
      width: 150px;
      position: absolute;
      top: -2px;
      right: -2px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: transparent;
        color: #015597;
        border: 2px #015597 solid;
        font-weight: bold;
      }
      &.ban {
        color: #999;
        background-color: #ddd;
        border: 0;
        border: 1px #999 solid;
        cursor: not-allowed;
      }
    }
    .btn-box {
      display: flex;
      margin: 30px;
      justify-content: space-between;
      div {
        height: 50px;
        border: 1px #009bbd solid;
        text-align: center;
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        transition: all 0.5s;
      }
      span:nth-child(1) {
        font-size: 16px;
        display: block;
        margin-bottom: -5px;
      }
      .login {
        color: white;
        background-color: #009bbd;
        border: 2px #009bbd solid;
        &:hover {
          background-color: transparent;
          color: #015597;
          border: 2px #015597 solid;
          font-weight: bold;
        }
      }
      .forget-password {
        border: 2px #009bbd solid;
        font-weight: bold;
        color: #009bbd;
        &:hover {
          background-color: #de1b5c;
          color: white;
          border: 2px #de1b5c solid;
        }
      }
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
// @media screen and (max-width: 1000px) {
//   .header .title {
//     font-size: 18px;
//   }
// }
// @media screen and (max-width: 750px) {
//   .header {
//     height: unset;
//     img {
//       height: 50px !important;
//     }
//     .title {
//       font-size: 12px;
//       line-height: 16px;
//       margin: 12px 12px;
//     }
//     .username{
//       display: none;
//     }
//     .mobile-menu{
//       position: absolute;
//       color: white;
//       right: 18px;
//       top: 12px;
//       i{
//         font-size: 24px;
//       }
//     }
//   }
//   .body {
//     height: calc(100vh - 69px);
//     .left-menu {
//       position: fixed;
//       display: none;
//     }
//     .content {
//       width: 100%;
//       max-height: unset;
//       overflow-y: scroll;
//     }
//   }
// }
// @media screen and (max-width: 414px) {
//   .header{
//     img{
//     height: 40px !important;

//     }
//     .title{
//       width: 195px;
//     margin: 8px;
//     }
//   }
// }

.pop-layer {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  padding: 15px;
  &.notice-layer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    &.notice-box {
      width: 1000px;
      padding-bottom: 50px;
    }
    .title {
      display: flex;
      height: 60px;
      border-bottom: 1px #ddd solid;
      line-height: 60px;
      font-size: 18px;
      padding: 0 20px;
      justify-content: space-between;
      min-width: 600px;
      i {
        font-size: 18px;
        cursor: pointer;
        &:hover {
          color: #f44336;
          font-weight: bold;
        }
      }
    }
    .content {
      padding: 50px;
      overflow-y: scroll;
      height: calc(100% - 60px);
      display: block;
      min-width: 600px;
      .content-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .addtion {
        color: #858585;
        text-align: right;
        margin-bottom: 50px;
      }
      .content-box {
        display: block;
        width: auto;
        min-width: auto;
        /deep/ img{
          max-width: 100%;
          height: auto;
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }
      /*竖向滚动条*/
      &::-webkit-scrollbar-thumb:vertical {
        height: 5px;
        background-color: #ddd;
        -webkit-border-radius: 0px;
      }
    }
  }
}
.c-mb-10 {
	margin-bottom: 10px;
}
.notice-wrap {
  padding: 0 12px;
  .notice-title {
    //margin-top: 10px;
    width: 290px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.primary-color {
      color: #000000;
    }
  }
  .notice-action {
    margin-left: 20px;
    margin-right: 10px;
    color: #009bbd
  }
}
.c-flex {
  display: flex;
}
.c-flex {
  display: flex;
}

.c-flex-direction-column {
  flex-direction: column;
}

.c-flex-align-baseline {
  align-items: baseline;
}
.c-flex-align-center {
  align-items: center;
}

.c-flex-just-center {
  justify-content: center;
}
.c-flex-just-end {
  justify-content: flex-end;
}
.c-flex-just-between {
  justify-content: space-between;
}
.c-flex-just-around {
  justify-content: space-around;
}
/deep/ .ant-tabs-nav .ant-tabs-tab-active {
  color: #009bbd;
}
/deep/ .ant-tabs-ink-bar {
  background-color: #009bbd;
}
</style>
<style lang="scss">
.ant-popover-inner-content {
	padding-top: 0;
}
</style>
