import Vue from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'ant-design-vue/dist/antd.css';
import Print from './plugs/Print'
import api from './api/api.js';
import Meta from 'vue-meta'
// import Vconsole from 'vconsole'

// Vue.prototype.$vConsole = new Vconsole();
Vue.prototype.$api = api;

Vue.use(Print)
Vue.config.productionTip = false;

Vue.use(Antd);

Vue.use(Meta)

if (!Array.prototype.find) {
  Array.prototype.find = function(callback) {
    return callback && (this.filter(callback) || [])[0];
  };
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
