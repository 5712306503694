<template>
  <div style="overflow:hidden;display: flex;flex-direction: column;align-items: center;">
    <div class="header">
      <img src="../../assets/whitetext.png" style="height: 100%" />
    </div>
    <div class="back-btn" @click="goHome">回到首页</div>
    <div class="content" :style="'height:'+(height-170)+'px'">
      <div class="page" ref="print" id="printConss" :style="'transform:scale('+scale+')'">
        <div class="logo">
          <img src="../../assets/bluetext.png" width="200" />
        </div>
        <div class="title">
          {{title}}
        </div>
        <div class="user-info">
          <div class="text">
            <div><span v-html="name"></span><span>{{applyInfo.realName}}</span></div>
            <div><span v-html="pinyin"></span><span>{{applyInfo.namePinyin}}</span></div>
            <div><span>身份证号 </span><span>{{applyInfo.cardNo}}</span></div>
            <div><span>准考证号</span><span>{{applyInfo.examNo}}</span></div>
            <div><span>笔试时间</span><span>{{applyInfo.handleDatetime}}</span></div>
            <div><span>笔试地点</span><span>{{applyInfo.handlePosition}}</span></div>
          </div>
          <div class="pic">
            <img :src="applyInfo.imgUrl" alt="" />
          </div>
        </div>
        <div class="notice">
          <div class="title-text">
            <img src="../../assets/ksxz-img.png" class="bg-img" />.
            <!-- <img src="../../assets/ksxz.png" class="text-img" />1 -->
          </div>
          <div class="content-box">
            <div
              class="item"
              style="border-left: 1px #18194f solid; width: 55%"
            >
              <div v-html="knowLeft"></div>
            </div>
            <div class="item" style="line-height: 22px">
              <div v-html="knowRight"></div>
            </div>
          </div>
          <div class="qr">
            <img src="../../assets/qr.png" alt="" />
            <div>
              关注宁波诺丁汉大学招生办微信公众号<br />
              掌握更多最新招生资讯
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div>招生热线：0574-88180182</div>
        <div>学校地址：浙江省宁波市泰康东路199号</div>
        <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
        </div>
        <div>招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
            >Gaokao@nottingham.edu.cn</a
          >
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../../assets/qr.png" style="height: 100%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scale: 1,
      name: "姓&#8195;&#8195;名",
      pinyin: "拼&#8195;&#8195;音",
      overflow: "",
      applyInfo:{},
      knowLeft:"",
      knowRight:"",
      title:"",
      height: `${document.documentElement.clientHeight}`,
      loading:false
    };
  },
  created(){
    this.scale = ((document.body.clientWidth - 20) / 780) < 1?((document.body.clientWidth - 20) / 780):1;
    // this.marginTop = this.scale < 1 ? 'margin-top:'+390 * (this.scale - 1)+'px':'';
    window.onresize = () => {
      this.scale = ((document.body.clientWidth - 20) / 780) < 1?((document.body.clientWidth - 20) / 780):1;
      // this.marginTop = this.scale < 1 ? 'margin-top:'+390 * (this.scale - 1)+'px':'';
    }
    // this.scale = (document.body.clientWidth - 20) / 780
    this.getExamCardInfo();
  },
  methods: {
    goHome(){
      this.$router.push("mindex");
    },
    getExamCardInfo(){
      this.loading = true;
      this.$api
          .getExamCardInfo()
          .then((res) => {
            // console.log(res);
            this.loading = false;
            if (res.data.code == 0) {
              this.applyInfo = res.data.applyInfo;
              this.knowLeft = res.data.knowLeft;
              this.knowRight = res.data.knowRight;
              this.title = res.data.title;
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getContentConfigList(name){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:name
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                this.content = res.data.configList[item].content;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }
};
</script>

<style lang='scss' scoped>
.header {
  height: 70px;
  width: 100%;
  background-color: #18194f;
  padding-bottom: 10px;
}
.back-btn{
  width: 85px;
  height: 35px;
  background-color: #009bbd;
  position: absolute;
  left: 0;
  line-height: 35px;
  color: white;
  text-align: center;
  z-index: 1;
  top: 85px;
}
.content {
  position: relative;
  height: calc(100vh - 170px);
  overflow-y: scroll;
  background-color: #fff;
  padding: 10px;
  display: flex;
  // align-items: center;
  width: 800px;
  overflow-x: hidden;
  padding-top: 60px;
  .page {
    // position: relative;
    width: 800px;
    transform-origin: top;
    // margin-top: -330px;
    // margin-bottom: -500px;
    .logo {
      display: flex;
      img {
        width: 200px;
        position: absolute;
        left: 0;
      }
    }
    .title {
      margin-top: 100px;
      font-size: 16px;
      font-weight: bold;
      transform: scale(1.5);
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    .user-info {
      display: flex;
      justify-content: space-between;
      .text {
        text-align: left;
        line-height: 27px;
        margin-top: 30px;
        transform: scale(1.125);
        transform-origin: left;
        span:nth-child(1) {
          font-size: 18px;
          width: 100px;
          display: inline-block;
        }
        span:nth-child(2) {
          font-size: 18px;
          margin-left: 20px;
        }
      }
      .pic {
        img {
          width: 130px;
          height: 180px;
          margin-top: 30px;
        }
      }
    }
    .notice {
      .title-text {
        margin-top: 20px;
        position: relative;
        height: 45px;
        line-height: 45px;
        .bg-img {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
        }
        .text-img {
          height: 45%;
          position: relative;
          z-index: 1;
        }
      }
      .content-box {
        display: flex;
        /deep/ p{
          margin-bottom: 0 !important;
        }
        /deep/ table{
          border: 1px solid;
          tr{
            border: 1px solid;
            td{
              border: 1px solid;
              padding-left: 10px;
            }
          }
        }
        .item {
          font-size: 16px;
          text-align: left;
          width: 50%;
          line-height: 20px;
          padding: 8px;
          border-right: 1px #18194f solid;
          border-bottom: 1px #18194f solid;
          .label {
            font-weight: bold;
            display: block;
            text-decoration: underline;
          }
          .strong {
            font-weight: bold;
          }
        }
      }
    }
    .qr {
      margin-top: 20px;
      text-align: center;
      div {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
  }
}
.footer {
    height: 100px;
    background-color: #18194f;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    align-items: center;
    padding: 10px;
  .text-div {
    font-size: 10px;
    .school-name {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: white;
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      height: 70%;
    }
    .img-text {
      font-size: 10px;
      text-align: center;
      line-height: 12px;
      margin-top: 3px;
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>