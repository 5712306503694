<template>
  <div class="content-div">
    <div class="content">
      <div class="left-box">
        <div class="title">选考科目<span v-if="xuankaoTip">请勾选三项选考科目</span></div>
        <div class="item">
          <a-checkbox-group
            v-model="examinationCheckedList"
            :options="examinationOptions"
            @change="onChange"
            :disabled="applyStatus != 1"
          />
        </div>
        <div class="title">选考科目成绩<span v-if="xuankaoScoreTip">请填写选考科目成绩</span></div>
        <div class="item">
          <div class="input-item" v-for="item in xuankao" :key="item.id">
            {{item.item}}
            <input type="number" v-model="item.value" :disabled="applyStatus != 1 || item.disable == 'true'"/>
          </div>
        </div>
        <div class="title">学业水平测试成绩<span v-if="xueyeTip">请选择学业水平测试成绩</span></div>
        <div class="item score-item">
          <div class="input-item" v-for="item in xueye" :key="item.id">
            {{item.item}}
            <a-select placeholder="" v-model="item.value" :disabled="applyStatus != 1">
              <a-select-option value="A"> A </a-select-option>
              <a-select-option value="B"> B </a-select-option>
              <a-select-option value="C"> C </a-select-option>
              <a-select-option value="D"> D </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="title">综合素质评价<span v-if="zongheTip">请选择综合素质评价</span></div>
        <div class="item quality-item">
          <div class="input-item" v-for="item in zonghe" :key="item.id">
            {{item.item}}
            <a-select placeholder="" v-model="item.value" :disabled="applyStatus != 1">
              <a-select-option value="A"> A </a-select-option>
              <a-select-option value="B"> B </a-select-option>
              <a-select-option value="C"> C </a-select-option>
              <a-select-option value="D"> D </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div v-if="applyStatus != 1" class="apply-done-tip">你已提交申请<br>当前页无法编辑仅供查看</div>
      <div class="save-btn" @click="goPrevStep" v-if="applyTime != 0 || applyStatus != 1">{{applyStatus == 1?'上一步':'上一页'}}</div>
      <div class="save-btn" @click="save" v-if="applyTime != 0 && applyStatus == 1">暂存</div>
      <div class="next-btn" @click="saveAndNext" v-if="applyTime != 0 || applyStatus != 1">{{applyStatus == 1?'下一步':'下一页'}}</div>
      <div class="save-btn" v-if="applyTime == 0 && applyStatus == 1">报名已结束</div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import "babel-polyfill";

export default {
  data() {
    return {
      applyTime:this.$store.state.applyTime,
      examinationOptions: [
        {
          label:"政治",
          value:"政治",
          disabled:false
        },
        {
          label:"历史",
          value:"历史",
          disabled:false
        },
        {
          label:"地理",
          value:"地理",
          disabled:false
        },
        {
          label:"物理",
          value:"物理",
          disabled:false
        },
        {
          label:"化学",
          value:"化学",
          disabled:false
        },
        {
          label:"生物",
          value:"生物",
          disabled:false
        },
        {
          label:"技术",
          value:"技术",
          disabled:false
        }
      ],
      xuankao:[
        {
          'id':4,
          'scoreType':1,
          'item':"政治",
          'value':"",
          'disable':'true'
        },
        {
          'id':6,
          'scoreType':1,
          'item':"历史",
          'value':"",
          'disable':'true'
        },
        {
          'id':5,
          'scoreType':1,
          'item':"地理",
          'value':"",
          'disable':'true'
        },
        {
          'id':1,
          'scoreType':1,
          'item':"物理",
          'value':"",
          'disable':'true'
        },
        {
          'id':2,
          'scoreType':1,
          'item':"化学",
          'value':"",
          'disable':'true'
        },
        {
          'id':3,
          'scoreType':1,
          'item':"生物",
          'value':"",
          'disable':'true'
        },
        {
          'id':7,
          'scoreType':1,
          'item':"技术",
          'value':"",
          'disable':'true'
        }
      ],
      xueye:[
        {
          'id':1,
          'scoreType':2,
          'item':"语文",
          'value':""
        },
        {
          'id':2,
          'scoreType':2,
          'item':"数学",
          'value':""
        },
        {
          'id':3,
          'scoreType':2,
          'item':"英语",
          'value':""
        },
        {
          'id':4,
          'scoreType':2,
          'item':"物理",
          'value':""
        },
        {
          'id':5,
          'scoreType':2,
          'item':"化学",
          'value':""
        },
        {
          'id':6,
          'scoreType':2,
          'item':"生物",
          'value':""
        },
        {
          'id':7,
          'scoreType':2,
          'item':"政治",
          'value':""
        },
        {
          'id':8,
          'scoreType':2,
          'item':"历史",
          'value':""
        },
        {
          'id':9,
          'scoreType':2,
          'item':"地理",
          'value':""
        },
        {
          'id':10,
          'scoreType':2,
          'item':"技术",
          'value':""
        }
      ],
      zonghe:[
        {
          'id':1,
          'scoreType':3,
          'item':"品德表现",
          'value':""
        },
        {
          'id':2,
          'scoreType':3,
          'item':"运动健康",
          'value':""
        },
        {
          'id':3,
          'scoreType':3,
          'item':"艺术素养",
          'value':""
        },
        {
          'id':4,
          'scoreType':3,
          'item':"创新实践",
          'value':""
        }
      ],
      examinationCheckedList: [],
      xuankaoTip:false,
      xuankaoScoreTip:false,
      xueyeTip:false,
      zongheTip:false,
      applyStatus:1,
      infoStatus:0,
      loading:false
    };
  },
  watch: {
    'xuankao.0.value'(n, o){
      Number(n) > 100 ? this.xuankao[0].value = o:'';
    },
    'xuankao.1.value'(n, o){
      Number(n) > 100 ? this.xuankao[1].value = o:'';
    },
    'xuankao.2.value'(n, o){
      Number(n) > 100 ? this.xuankao[2].value = o:'';
    },
    'xuankao.3.value'(n, o){
      Number(n) > 100 ? this.xuankao[3].value = o:'';
    },
    'xuankao.4.value'(n, o){
      Number(n) > 100 ? this.xuankao[4].value = o:'';
    },
    'xuankao.5.value'(n, o){
      Number(n) > 100 ? this.xuankao[5].value = o:'';
    },
    'xuankao.6.value'(n, o){
      Number(n) > 100 ? this.xuankao[6].value = o:'';
    },
  },
  created(){
    this.getExistSchoolScores();
    this.getApplyStatus();
    this.getInfoStatus();
  },
  methods: {
    onChange() {
      var map = new Map();
      this.examinationCheckedList.forEach(element => {
        map.set(element,element);
      });
      // 设置多选框禁用状态
      if(this.examinationCheckedList.length >= 3){
          // this.$message.warning('最多选择3项');
          this.examinationOptions.forEach(element => {
            if(!map.get(element.value)){
              element.disabled = true;
            }
          });
      }else{
          this.examinationOptions.forEach(element => {
              element.disabled = false;
          });
      }
      // 设置成绩输入框禁用状态
      this.xuankao.forEach(element => {
        if(map.get(element.item)){
          element.disable = 'fasle';
        }else{
          element.disable = 'true';
          element.value = '';
        }
      });
    },
    goPrevStep(){
      this.$router.push("/highschoolinfo");
    },
    saveData(verify) {
      let warning = false;
      var allScore = [];
      this.xuankaoTip = false;
      this.xuankaoScoreTip = false;
      this.xueyeTip = false;
      this.zongheTip = false;
      for(let item in this.zonghe){
        var SchoolScoreDto = {};
        SchoolScoreDto.examResult = this.zonghe[item]['value'];
        SchoolScoreDto.examSubject = this.zonghe[item]['item'];
        SchoolScoreDto.scoreType = this.zonghe[item]['scoreType'];
        allScore.push(SchoolScoreDto);
        if(verify && (this.zonghe[item]['value'] == null || this.zonghe[item]['value'] == '')){
          warning = true;
          this.zongheTip = true;
        }
      }
      let courseScore = [];
      var map = new Map();
      this.examinationCheckedList.forEach(element => {
        map.set(element,element);
      });
      if(verify && this.examinationCheckedList.length < 3){
        this.xuankaoTip = true;
      }
      for(let item in this.xuankao){
        if(map.get(this.xuankao[item]['item'])){
          var courseScore1 = {};
          courseScore1.examResult = this.xuankao[item]['value'];
          courseScore1.examSubject = this.xuankao[item]['item'];
          courseScore1.scoreType = this.xuankao[item]['scoreType'];
          courseScore.push(courseScore1);
          if(this.xuankao[item]['value'] == null || this.xuankao[item]['value'] == ''){
            warning = true;
            this.xuankaoScoreTip = true;
          }
        }
      }
      let studyScore = []
      for(let item in this.xueye){
        var studyScore2 = {};
        studyScore2.examResult = this.xueye[item]['value'];
        studyScore2.examSubject = this.xueye[item]['item'];
        studyScore2.scoreType = this.xueye[item]['scoreType'];
        studyScore.push(studyScore2);
        if(verify &&(this.xueye[item]['value'] == null || this.xueye[item]['value'] == '')){
          warning = true;
          this.xueyeTip = true;
        }
      }
      if(verify && this.infoStatus < 6){
        var params = {}
        params['infoStatus'] = 6
        this.infoStatus = 6
        this.$api
          .saveTmpApply(params)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (verify && warning) {
        this.$message.warning("请填写完整");
        return;
      }
      // console.log(this.examinationCheckedList);
      // console.log(courseScore);
      // debugger;
      let _that = this;
      _that.loading = true;
      this.$api
          .saveTmpScholScore({
            "allScore":allScore,
            "courseScore":courseScore,
            "studyScore":studyScore,
          })
          .then((res) => {
            _that.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              if(verify == true){
                this.$router.push("/intentionmajor");
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            _that.loading = false;
            console.log(err);
          });
    },
    save(){
      this.saveData();
    },
    saveAndNext(){
      if(this.applyStatus == 1){
        this.saveData(true);
      }else{
        this.$router.push("/intentionmajor");
      }
    },
    getExistSchoolScores(){
      this.loading = true;
      this.$api
          .getExistSchoolScores()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              var map = new Map();
              res.data.schoolScores.courseScore.forEach(element => {
                map.set(element.scoreType + element.examSubject,element.examResult);
              });
              this.xuankao.forEach(element => {
                element.value = map.get('1'+element.item);
                if(map.get('1'+element.item)){
                  this.examinationCheckedList.push(element.item);
                }
              });
              if(res.data.schoolScores.courseScore.length >= 3){
                this.examinationOptions.forEach(element => {
                  if(!map.get('1'+element.label)){
                    element.disabled = true;
                  }
                });
                this.xuankao.forEach(element => {
                  if(map.get('1'+element.item)){
                    element.disable = 'fasle';
                  }
                });
              }
              res.data.schoolScores.studyScore.forEach(element => {
                map.set(element.scoreType + element.examSubject,element.examResult);
              });
              this.xueye.forEach(element => {
                element.value = map.get('2'+element.item);
              });
              res.data.schoolScores.allScore.forEach(element => {
                map.set(element.scoreType + element.examSubject,element.examResult);
              });
              this.zonghe.forEach(element => {
                element.value = map.get('3'+element.item);
              });
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getApplyStatus(){
      this.$api
          .getApplyStatus()
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.applyStatus = res.data.applyStatus;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getInfoStatus(){
      this.$api
          .getInfoStatus()
          .then((res) => {
            console.log(res.data.infoStatus);
            this.infoStatus = res.data.infoStatus
          })
          .catch((err) => {
            console.log(err);
          });
    }
  },
};
</script>

<style lang='scss' scoped>
.content-div {
  height: calc(100%);
  overflow-y: scroll;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content-div {
  height: calc(100%);
  overflow-y: scroll;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  margin-top: 100px;
  display: flex;
  .left-box {
    width: 100%;
    max-width: 1200px;
    .title {
      background-color: #e8ecee;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      padding-left: 18px;
      span{
        font-size: 14px;
        color: #ff5722;
        font-weight: normal;
        margin-left: 10px;
      }
    }
    .item {
      display: flex;
      height: 90px;
      align-items: center;
      flex-wrap: wrap;
      .input-item {
        font-size: 18px;
        width: calc(100% / 7.01);
        min-width: 115px;
        input {
          outline: none;
          width: calc(100% - 60px);
          border: 1px solid #ccc;
          padding-left: 10px;
        }
      }
      &.score-item {
        .input-item {
          width: calc(100% / 10);
        }
      }
      &.quality-item {
        .input-item {
          width: calc(100% / 4);
          .ant-select {
            width: calc(100% - 100px);
          }
        }
      }
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
/deep/ .ant-checkbox-group {
  display: flex;
  justify-content: space-around;
  width: 100%;
  .ant-checkbox-group-item {
    margin-right: 30px;
  }
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    &::after {
      top: 48%;
      width: 7px;
      height: 14px;
    }
  }
  .ant-checkbox-wrapper {
    font-size: 18px;
    color: #333;
  }
}
/deep/ .ant-select {
  width: calc(100% - 60px);
  font-size: 16px;
  .ant-select-selection {
    box-shadow: none;
    border-radius: 0px;
    border-color: #ccc;
  }
}
/deep/ .ant-select-dropdown-menu-item {
  font-size: 16px;
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply-done-tip{
  font-size: 14px;
  color: #999;
  margin-right: 20px;
}
</style>