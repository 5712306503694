<template>
  <div class="content-div">
    <div class="content" ref="print">
      <div class="left-box">
        <div class="input-item">
          <div class="label">姓名</div>
          <div class="input-box">{{applyInfo.realName}}</div>
        </div>
        <div class="input-item">
          <div class="label">拼音</div>
          <div class="input-box">{{applyInfo.namePinyin}}</div>
          <a-tooltip placement="right">
            <template slot="title">
              <span>
                例如：<br />
                吕一 Lyu Yi<br />
                王小二 Wang Xiaoer
              </span>
            </template>
            <i class="iconfont icon-wenhao"></i>
          </a-tooltip>
        </div>
        <div class="input-item">
          <div class="label">性别</div>
          <div class="input-box">{{applyInfo.sex}}</div>
        </div>
        <div class="input-item">
          <div class="label">出生年月日</div>
          <div class="input-box">{{applyInfo.birthDate.substring(0, 10)}}</div>
        </div>
        <div class="input-item">
          <div class="label">民族</div>
          <div class="input-box">{{applyInfo.nation}}</div>
        </div>
        <div class="input-item">
          <div class="label">政治面貌</div>
          <div class="input-box">{{applyInfo.politicsFace}}</div>
        </div>
        <div class="input-item">
          <div class="label">考生类型</div>
          <div class="input-box">{{applyInfo.candidateType}}</div>
        </div>
        <div class="input-item">
          <div class="label">外语</div>
          <div class="input-box">{{applyInfo.foreigLanguages}}</div>
        </div>
      </div>
      <div class="right-box">
        <div class="img-box">
          <img
            :src="applyInfo.imgUrl"
            width="200"
            height="280"
            style="border: 1px #ccc solid"
          />
        </div>
      </div>
    </div>
    <div class="contact-box">
      <div class="title">联系方式</div>
      <div class="input-item">
        <div class="label">电子邮箱</div>
        <div class="input-box">{{applyInfo.email}}</div>
        <a-tooltip placement="right">
          <template slot="title">
            <span> 请正确填写电子邮箱 用于找回密码 </span>
          </template>
          <i class="iconfont icon-wenhao"></i>
        </a-tooltip>
      </div>
      <div class="input-item">
        <div class="label">手机号码</div>
        <div class="input-box">{{applyInfo.mobilePhone}}</div>
      </div>
      <div class="input-item">
        <div class="label">通讯地址</div>
        <div class="input-box addr-input">{{applyInfo.province}}</div>
        <div class="input-box addr-input">{{applyInfo.city}}</div>
        <div class="input-box addr-input">{{applyInfo.area}}</div>
      </div>
      <div class="input-item">
        <div class="label">详细地址</div>
        <div class="input-box">
          {{applyInfo.address}}
        </div>
        <a-tooltip placement="right">
          <template slot="title">
            <span>
              从街道开始填写，例如：<br />
              首南街道泰康东路199号宁波诺丁汉大学行政楼1508
            </span>
          </template>
          <i class="iconfont icon-wenhao"></i>
        </a-tooltip>
      </div>
      <div class="input-item">
        <div class="label">邮政编码</div>
        <div class="input-box">{{applyInfo.postCode}}</div>
      </div>
    </div>
    <div class="parents-box">
      <div class="info-box">
        <div class="title">父亲信息</div>
        <div class="input-item">
          <div class="label">*父亲姓名</div>
          <div class="input-box">{{applyInfo.fatherName}}</div>
        </div>
        <div class="input-item">
          <div class="label">*联系方式</div>
          <div class="input-box">{{applyInfo.fatherMobilePhone}}</div>
        </div>
        <div class="input-item">
          <div class="label">单位</div>
          <div class="input-box">{{applyInfo.fatherCompany}}</div>
        </div>
        <div class="input-item">
          <div class="label">职务</div>
          <div class="input-box">{{applyInfo.fatherPost}}</div>
        </div>
      </div>
      <div class="info-box">
        <div class="title">母亲信息</div>
        <div class="input-item">
          <div class="label">*母亲姓名</div>
          <div class="input-box">{{applyInfo.motherName}}</div>
        </div>
        <div class="input-item">
          <div class="label">*联系方式</div>
          <div class="input-box">{{applyInfo.motherMobilePhone}}</div>
        </div>
        <div class="input-item">
          <div class="label">单位</div>
          <div class="input-box">{{applyInfo.motherCompany}}</div>
        </div>
        <div class="input-item">
          <div class="label">职务</div>
          <div class="input-box">{{applyInfo.motherPost}}</div>
        </div>
      </div>
    </div>
    <div class="highschool-box">
      <div class="title">高中信息</div>
      <div class="input-item">
        <div class="label">所在高中</div>
        <div class="input-box">{{applyInfo.highSchool}}</div>
      </div>
      <div class="input-item">
        <div class="label">中学地址</div>
        <div class="input-box addr-input">{{applyInfo.schoolProvince}}</div>
        <div class="input-box addr-input">{{applyInfo.schoolCity}}</div>
        <div class="input-box addr-input">{{applyInfo.schoolArea}}</div>
      </div>
      <div class="input-item">
        <div class="label"></div>
        <div class="input-box">
          {{applyInfo.schoolAddress}}
        </div>
        <a-tooltip placement="right">
          <template slot="title">
            <span>
              从街道开始填写，例如：<br />
              首南街道泰康东路199号宁波诺丁汉大学行政楼1508
            </span>
          </template>
          <i class="iconfont icon-wenhao"></i>
        </a-tooltip>
      </div>
      <div class="input-item">
        <div class="label">中学邮编</div>
        <div class="input-box">{{applyInfo.schoolPostCode}}</div>
      </div>
      <div class="input-item">
        <div class="label">中学联系人</div>
        <div class="input-box">{{applyInfo.schoolContactName}}</div>
      </div>
      <div class="input-item">
        <div class="label">中学电话</div>
        <div class="input-box">{{applyInfo.schoolContactPhone}}</div>
      </div>
    </div>
    <div class="score-box">
      <div class="title">选考科目</div>
      <div class="item">
        <a-checkbox-group
          :options="examinationOptions"
          :value="examinationCheckedList"
        />
      </div>
      <div class="title">选考科目成绩</div>
      <div class="item">
        <div class="input-item" v-for="item in xuankao" :key="item.id">
          {{item.item}}
          <div
            class="input"
            v-bind:class="[item.disable == 'true'?'ban':'']"
          >
            {{item.value}}
          </div>
        </div>
      </div>
      <div class="title">学业水平测试成绩</div>
      <div class="item score-item">
        <div class="input-item" v-for="item in xueye" :key="item.id">
          {{item.item}}
          <div class="input">{{item.value}}</div>
        </div>
      </div>
      <div class="title">综合素质评价</div>
      <div class="item quality-item">
        <div class="input-item" v-for="item in zonghe" :key="item.id">
          {{item.item}}
          <div class="input">{{item.value}}</div>
        </div>
      </div>
      <div class="title">报考意向</div>
      <div class="item quality-item">
        <div class="input-item" style="width: calc(100% / 3)">
          意向专业一
          <div class="input" style="width: calc(100% - 110px)">{{applyInfo.intentionMajor1}}</div>
        </div>
        <div class="input-item" style="width: calc(100% / 3)">
          意向专业二
          <div class="input" style="width: calc(100% - 110px)">{{applyInfo.intentionMajor2}}</div>
        </div>
        <div class="input-item" style="width: calc(100% / 3)">
          意向专业三
          <div class="input" style="width: calc(100% - 110px)">{{applyInfo.intentionMajor3}}</div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div v-if="applyStatus != 1" class="apply-done-tip">你已提交申请<br>当前页仅供查看</div>
      <div class="next-btn" @click="submit" v-if="applyStatus == 1 && applyTime != 0">提交</div>
      <div class="next-btn" @click="nextStep" v-if="applyStatus != 1">下一页</div>
      <div class="save-btn" v-if="applyTime == 0 && applyStatus == 1">报名已结束</div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applyTime:this.$store.state.applyTime,
      examinationOptions: [
        {
          label:"政治",
          value:"政治",
          disabled:false
        },
        {
          label:"历史",
          value:"历史",
          disabled:false
        },
        {
          label:"地理",
          value:"地理",
          disabled:false
        },
        {
          label:"物理",
          value:"物理",
          disabled:false
        },
        {
          label:"化学",
          value:"化学",
          disabled:false
        },
        {
          label:"生物",
          value:"生物",
          disabled:false
        },
        {
          label:"技术",
          value:"技术",
          disabled:false
        }
      ],
      xuankao:[
        {
          'id':4,
          'scoreType':1,
          'item':"政治",
          'value':"",
          'disable':'true'
        },
        {
          'id':6,
          'scoreType':1,
          'item':"历史",
          'value':"",
          'disable':'true'
        },
        {
          'id':5,
          'scoreType':1,
          'item':"地理",
          'value':"",
          'disable':'true'
        },
        {
          'id':1,
          'scoreType':1,
          'item':"物理",
          'value':"",
          'disable':'true'
        },
        {
          'id':2,
          'scoreType':1,
          'item':"化学",
          'value':"",
          'disable':'true'
        },
        {
          'id':3,
          'scoreType':1,
          'item':"生物",
          'value':"",
          'disable':'true'
        },
        {
          'id':7,
          'scoreType':1,
          'item':"技术",
          'value':"",
          'disable':'true'
        }
      ],
      xueye:[
        {
          'id':1,
          'scoreType':2,
          'item':"语文",
          'value':""
        },
        {
          'id':2,
          'scoreType':2,
          'item':"数学",
          'value':""
        },
        {
          'id':3,
          'scoreType':2,
          'item':"英语",
          'value':""
        },
        {
          'id':4,
          'scoreType':2,
          'item':"物理",
          'value':""
        },
        {
          'id':5,
          'scoreType':2,
          'item':"化学",
          'value':""
        },
        {
          'id':6,
          'scoreType':2,
          'item':"生物",
          'value':""
        },
        {
          'id':7,
          'scoreType':2,
          'item':"政治",
          'value':""
        },
        {
          'id':8,
          'scoreType':2,
          'item':"历史",
          'value':""
        },
        {
          'id':9,
          'scoreType':2,
          'item':"地理",
          'value':""
        },
        {
          'id':10,
          'scoreType':2,
          'item':"技术",
          'value':""
        }
      ],
      zonghe:[
        {
          'id':1,
          'scoreType':3,
          'item':"品德表现",
          'value':""
        },
        {
          'id':2,
          'scoreType':3,
          'item':"运动健康",
          'value':""
        },
        {
          'id':3,
          'scoreType':3,
          'item':"艺术素养",
          'value':""
        },
        {
          'id':4,
          'scoreType':3,
          'item':"创新实践",
          'value':""
        }
      ],
      examinationCheckedList: [],
      applyInfo:{
        realName:"",
        namePinyin:"",
        sex:"",
        birthDate:"",
        nation:"",
        politicsFace:"",
        candidateType:"",
        foreigLanguages:"",
        email:"",
        mobilePhone:"",
        province:"",
        city:"",
        area:"",
        address:"",
        PostCode:"",
        fatherName:"",
        fatherMobilePhone:"",
        fatherOccuption:"",
        fatherCompany:"",
        fatherPost:"",
        motherName:"",
        motherMobilePhone:"",
        motherOccuption:"",
        motherCompany:"",
        motherPost:"",
        highSchool:"",
        schoolProvince:"",
        schoolCity:"",
        schoolArea:"",
        schoolAddress:"",
        schoolPostCode:"",
        schoolContactName:"",
        schoolContactPhone:"",
        imgUrl:"",
        intentionMajor1:"",
        intentionMajor2:"",
        intentionMajor3:""
      },
      applyStatus:1,
      infoStatus:0,
      loading:false
    };
  },
  created(){
    this.getExistApply();
    this.getExistSchoolScores();
  },
  methods: {
    submit() {
      if(this.applyInfo.foreigLanguages !== '英语'){
        this.$message.warning('您的外语为“非英语”，不符合提交要求');
        return
      }
      if(this.zonghe[0]['value'] == 'C' || this.zonghe[1]['value'] == 'C' || this.zonghe[2]['value'] == 'C' || this.zonghe[3]['value'] == 'C'){
        this.$message.warning('综合素质评价必须为B(含)以上');
        return
      }
      this.loading = true
      this.$api
          .commitApply()
          .then((res) => {
            this.loading = false;
            console.log(res);
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              this.$router.push("/commitment");
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    nextStep(){
      this.$router.push("/commitment");
    },
    getExistApply(){
      this.loading = true;
      this.$api
          .getExistApply()
          .then((res) => {
            this.loading = false;
            // _that.loading = false;
            console.log(res);
            if (res.data.code == 0) {
              Object.assign(this.applyInfo,res.data.exixtApply)
              this.applyStatus = res.data.exixtApply.status;
              this.infoStatus = res.data.exixtApply.infoStatus?res.data.exixtApply.infoStatus:0;
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            // _that.loading = false;
            console.log(err);
          });
    },
    getExistSchoolScores(){
      this.$api
          .getExistSchoolScores()
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              var map = new Map();
              res.data.schoolScores.courseScore.forEach(element => {
                map.set(element.scoreType + element.examSubject,element.examResult);
              });
              this.xuankao.forEach(element => {
                element.value = map.get('1'+element.item);
                if(map.get('1'+element.item)){
                  this.examinationCheckedList.push(element.item);
                }
              });
              if(res.data.schoolScores.courseScore.length >= 3){
                this.examinationOptions.forEach(element => {
                  if(!map.get('1'+element.label)){
                    element.disabled = true;
                  }
                });
                this.xuankao.forEach(element => {
                  if(map.get('1'+element.item)){
                    element.disable = 'fasle';
                  }
                });
              }
              res.data.schoolScores.studyScore.forEach(element => {
                map.set(element.scoreType + element.examSubject,element.examResult);
              });
              this.xueye.forEach(element => {
                element.value = map.get('2'+element.item);
              });
              res.data.schoolScores.allScore.forEach(element => {
                map.set(element.scoreType + element.examSubject,element.examResult);
              });
              this.zonghe.forEach(element => {
                element.value = map.get('3'+element.item);
              });
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  },
};
</script>

<style lang='scss' scoped>
.content-div {
  height: calc(100%);
  overflow-y: scroll;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  margin-top: 100px;
  display: flex;
  .left-box {
    .input-item {
      display: flex;
      margin: 20px;
      height: 36px;
      font-size: 16px;
      align-items: center;
      position: relative;
      .label {
        font-size: 18px;
        width: 150px;
        text-align: right;
        margin-right: 10px;
      }
      .input-box {
        border: 1px #ccc solid;
        width: 300px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
      }
      .icon-wenhao {
        font-size: 22px;
        color: #858585;
        margin-left: 10px;
        cursor: help;
      }
    }
  }
  .right-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 150px;
    .img-box {
      // background-color: #e8e8e8;
      background-color: #e8ecee;
      height: 280px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      color: #6e6e84;
      cursor: pointer;
      i {
        font-size: 50px;
        // color: white;
        color: #c8cfd4;
      }
    }
    .img-tips {
      font-size: 16px;
      line-height: 25px;
      margin-top: 15px;
    }
  }
  .contact-box {
    .title {
      background-color: #e8ecee;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      margin-left: 80px;
      padding-left: 18px;
      width: 920px;
    }
    .input-item {
      display: flex;
      margin: 20px;
      height: 36px;
      font-size: 16px;
      align-items: center;
      position: relative;
      .label {
        font-size: 18px;
        width: 150px;
        text-align: right;
        margin-right: 10px;
      }
      .input-box {
        border: 1px #ccc solid;
        width: 500px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        &.addr-input {
          width: 160px;
          margin-right: 10px;
        }
      }
      .icon-wenhao {
        font-size: 22px;
        color: #858585;
        margin-left: 10px;
        cursor: help;
      }
    }
  }
  .parents-box {
    margin-top: 20px;
    display: flex;
    .info-box {
      .title {
        background-color: #e8ecee;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        margin-left: 80px;
        padding-left: 18px;
      }
      .input-item {
        display: flex;
        margin: 20px;
        height: 36px;
        font-size: 16px;
        align-items: center;
        position: relative;
        .label {
          font-size: 18px;
          width: 150px;
          text-align: right;
          margin-right: 10px;
        }
        .input-box {
          border: 1px #ccc solid;
          width: 300px;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 10px;
        }
      }
    }
  }
  .highschool-box {
    .title {
      background-color: #e8ecee;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      margin-left: 80px;
      padding-left: 18px;
      width: 920px;
    }
    .input-item {
      display: flex;
      margin: 20px;
      height: 36px;
      font-size: 16px;
      align-items: center;
      position: relative;
      .label {
        font-size: 18px;
        width: 150px;
        text-align: right;
        margin-right: 10px;
      }
      .input-box {
        border: 1px #ccc solid;
        width: 500px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        &.addr-input {
          width: 160px;
          margin-right: 10px;
        }
      }
      .icon-wenhao {
        font-size: 22px;
        color: #858585;
        margin-left: 10px;
        cursor: help;
      }
    }
  }
  .score-box {
    width: 100%;
    max-width: 1200px;
    .title {
      background-color: #e8ecee;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      padding-left: 18px;
    }
    .item {
      display: flex;
      height: 90px;
      align-items: center;
      flex-wrap: wrap;
      .input-item {
        font-size: 18px;
        width: calc(100% / 7.01);
        min-width: 115px;
        .input {
          width: calc(100% - 60px);
          border: 1px solid #ccc;
          padding-left: 10px;
          display: inline-block;
          height: 30px;
          &.ban{
            color: whitesmoke;
            background-color: whitesmoke;
            border-color: #d9d9d9;
          }
        }
      }
      &.score-item {
        .input-item {
          width: calc(100% / 10);
        }
      }
      &.quality-item {
        .input-item {
          width: calc(100% / 4);
          .input {
            width: calc(100% - 100px);
          }
        }
      }
    }
    /deep/ .ant-checkbox-group {
      display: flex;
      justify-content: space-around;
      width: 100%;
      .ant-checkbox-group-item {
        margin-right: 30px;
      }
      .ant-checkbox-inner {
        width: 22px;
        height: 22px;
        &::after {
          top: 48%;
          width: 7px;
          height: 14px;
        }
      }
      .ant-checkbox-wrapper {
        font-size: 18px;
        color: #333;
      }
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
/deep/ .ant-select {
  width: 100%;
  font-size: 16px;
  .ant-select-selection {
    border: 0;
    box-shadow: none;
  }
}
/deep/ .ant-select-dropdown-menu-item {
  font-size: 16px;
}
/deep/ .ant-calendar-picker {
  width: 100%;
  .ant-calendar-picker-input.ant-input {
    border: 0;
  }
}
.apply-done-tip{
  font-size: 14px;
  color: #999;
  margin-right: 20px;
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
