<template>
  <div class="content-div">
    <div class="content" v-if="status == 4">
      <div class="box succ">
        <div class="unpass-title" v-if="unpass">初审结果</div>
        <div v-html="content"></div>
      </div>
      <div class="box succ reason" v-if="unpass">
        <div class="unpass-title">初审未通过原因</div>
        <div>{{auditReason}}</div>
      </div>
    </div>
    <div class="content" v-if="status == 8">
      <div class="box">
        <div v-html="content"></div>
      </div>
      <div class="btn" @click="goPay">缴费通道</div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status:0,
      content:'',
      payLimitsStatus: 1,
      loading: false,
      unpass: false,
      auditReason:null
    };
  },
  created(){
    this.getFirstAuditInfo();
    this.getPayLimits();
  },
  methods: {
    getPayLimits() {
      this.$api
        .getProgressList({
          code: "code_6",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.payLimitsStatus = 409;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFirstAuditInfo(){
      this.loading = true;
      this.$api
          .getFirstAuditInfo()
          .then((res) => {
            // console.log(res);
            this.loading = false;
            if (res.data.code == 0) {
              if(res.data.status <= 4){
                this.status = 4;
                this.getContentConfigList('佐证材料上传成功');
              }else if(res.data.status == 6){
                this.status = 4;
                this.unpass = true;
                this.auditReason = res.data.auditReason
                this.getContentConfigList('初审未通过');
              }else if(res.data.status == 5 || res.data.status == 8){
                this.status = 8;
                this.getContentConfigList('初审通过');
              }else{
                this.status = 4;
                this.getContentConfigList('已缴费');
              }
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getContentConfigList(name){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:name
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                this.content = res.data.configList[item].content;
              }
              if(name == '佐证材料上传成功'){
                this.getAuditTime();
              }
              if(name == '初审通过'){
                this.getPayTime();
              }
              if(name == '已缴费'){
                this.getTestTime();
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getAuditTime(){
        this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'初审时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(0,1) == '0'){
                  timeStr = res.data.configList[item].content.substring(1,3);
                }else{
                  timeStr = res.data.configList[item].content.substring(0,3);
                }
                if(res.data.configList[item].content.substring(3,4) == '0'){
                  timeStr += res.data.configList[item].content.substring(4);
                }else{
                  timeStr += res.data.configList[item].content.substring(3);
                }
                this.content = this.content.replace('{time}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getPayTime(){
        this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'缴费时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(5,6) == '0'){
                    timeStr = res.data.configList[item].content.substring(6,8);
                  }else{
                    timeStr = res.data.configList[item].content.substring(5,8);
                  }
                  if(res.data.configList[item].content.substring(8,9) == '0'){
                    timeStr += res.data.configList[item].content.substring(9,12);
                  }else{
                    timeStr += res.data.configList[item].content.substring(8,12);
                  }
                  if(res.data.configList[item].content.substring(17,18) == '0'){
                    timeStr += res.data.configList[item].content.substring(18,20);
                  }else{
                    timeStr += res.data.configList[item].content.substring(17,20);
                  }
                  if(res.data.configList[item].content.substring(20,21) == '0'){
                    timeStr += res.data.configList[item].content.substring(21);
                  }else{
                    timeStr += res.data.configList[item].content.substring(20);
                  }
                this.content = this.content.replace('{time}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTestTime(){
        this.$api
          .contentConfigList({
            categoryName:'时间配置',
            name:'准考证打印时间'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0 ) {
              for(let item in res.data.configList){
                var timeStr = '';
                if(res.data.configList[item].content.substring(5,6) == '0'){
                  timeStr = res.data.configList[item].content.substring(6,8);
                }else{
                  timeStr = res.data.configList[item].content.substring(5,8);
                }
                if(res.data.configList[item].content.substring(8,9) == '0'){
                  timeStr += res.data.configList[item].content.substring(9);
                }else{
                  timeStr += res.data.configList[item].content.substring(8);
                }
                this.content = this.content.replace('{time1}',timeStr);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "综合测试时间",
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              var timeStr = '';
              if(res.data.configList[0].content.substring(5,6) == '0'){
                timeStr = res.data.configList[0].content.substring(6,8);
              }else{
                timeStr = res.data.configList[0].content.substring(5,8);
              }
              if(res.data.configList[0].content.substring(8,9) == '0'){
                timeStr += res.data.configList[0].content.substring(9,12);
              }else{
                timeStr += res.data.configList[0].content.substring(8,12);
              }
              if(res.data.configList[0].content.substring(12,13) == '0'){
                timeStr += res.data.configList[0].content.substring(13);
              }else{
                timeStr += res.data.configList[0].content.substring(12);
              }
              this.content = this.content.replace('{time2}',timeStr);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    goPay(){
      if(this.payLimitsStatus == 409){
        this.$message.warning("现已暂停缴费，请等待开启！");
        return
      }
      this.$router.push("/payment");
    }
  },
};
</script>

<style lang='scss' scoped>
.content-div{
  height: 100%;
  overflow-y:scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  font-size: 24px;
  // font-weight: bold;
  // color: #009bbd;
  text-align: center;
  line-height: 2;
  margin-top: 50px;
  .box {
    height: 260px;
    // border: 1px #009bbd solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .succ{
    border: 1px #009bbd solid;
    color: #009bbd;
    font-weight: bold;
    position: relative;
    .unpass-title {
      width: 150px;
      background-color: #009bbc;
      color: white;
      position: absolute;
      top: -24px;
      left: calc(50% - 75px);
    }
  }
  .reason {
    margin-top: 60px;
    .unpass-title {
      color: #18194f;
      background-color: white;
      border: 2px solid #009bbc;
      width: 200px;
      left: calc(50% - 100px);
    }
  }
  .btn {
    color: white;
    font-size: 16px;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>