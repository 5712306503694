<template>
  <div class="body">
    <div class="content">
      <div class="apply-form-box" id="printConss">
        <div class="logo">
          <img src="../../assets/bluetext.png" width="200" />
        </div>
        <div class="title">
          {{titleZh}}<br />
          <span>{{titleEn}}</span>
        </div>
        <div class="apply-num">报名号：{{applyInfo.applyNo}}</div>
        <div class="apply-form">
          <div class="item-name"><img src="../../assets/bkyx-img.png" class="bg-img" />.</div>
          <div class="item">
            <div class="col-6 br bold">专业一</div>
            <div class="col-6 br">{{ applyInfo.intentionMajor1 }}</div>
            <div class="col-6 br bold">专业二</div>
            <div class="col-6 br">{{ applyInfo.intentionMajor2 }}</div>
            <div class="col-6 br bold">专业三</div>
            <div class="col-6">{{ applyInfo.intentionMajor3 }}</div>
          </div>
          <div class="item-name"><img src="../../assets/grxx-img.png" class="bg-img" />.</div>
          <div class="info">
            <div class="text-info">
              <div class="item bb">
                <div class="col-4 br bold">姓名</div>
                <div class="col-4 br" style="width: calc(100% / 4 + 40px);">{{ applyInfo.realName }}</div>
                <div class="col-4 br bold">拼音</div>
                <div class="col-4 br">{{ applyInfo.namePinyin }}</div>
              </div>
              <div class="item bb">
                <div class="col-4 br bold">性别</div>
                <div class="col-4 br" style="width: calc(100% / 4 + 40px);">{{ applyInfo.sex }}</div>
                <div class="col-4 br bold">出生年月</div>
                <div class="col-4 br">
                  {{ applyInfo.birthDate.substring(0, 10) }}
                </div>
              </div>
              <div class="item bb">
                <div class="col-4 br bold">民族</div>
                <div class="col-4 br" style="width: calc(100% / 4 + 40px);">{{ applyInfo.nation }}</div>
                <div class="col-4 br bold">政治面貌</div>
                <div class="col-4 br">{{ applyInfo.politicsFace }}</div>
              </div>
              <div class="item bb">
                <div class="col-4 br bold">考生类型</div>
                <div class="col-4 br" style="width: calc(100% / 4 + 40px);">{{ applyInfo.candidateType }}</div>
                <div class="col-4 br bold">外语</div>
                <div class="col-4 br">{{ applyInfo.foreigLanguages }}</div>
              </div>
              <div class="item bb">
                <div class="col-4 br bold">身份证号码</div>
                <div class="col-4 br" style="width: calc(100% / 4 + 40px);">{{ applyInfo.cardNo }}</div>
                <div class="col-4 br bold">手机号码</div>
                <div class="col-4 br">{{ applyInfo.mobilePhone }}</div>
              </div>
              <div class="item">
                <div class="col-4 br bold">通讯地址</div>
                <div class="col-addr br" style="width: calc(75% + 40px);">{{ applyInfo.city == applyInfo.province?applyInfo.city+applyInfo.area+applyInfo.address:applyInfo.province+applyInfo.city+applyInfo.area+applyInfo.address }}</div>
              </div>
            </div>
            <div class="pic-info">
              <img :src="applyInfo.imgUrl" alt="" />
            </div>
          </div>
          <div class="item-name"><img src="../../assets/gzxx-img.png" class="bg-img" />.</div>
          <div class="item bb">
            <div class="col-4 br bold">就读高中</div>
            <div class="col-4 br">{{ applyInfo.highSchool }}</div>
            <div class="col-4 br bold">中学地址</div>
            <div class="col-4">{{ applyInfo.schoolProvince == applyInfo.schoolCity ?applyInfo.schoolCity+applyInfo.schoolArea+applyInfo.schoolAddress:applyInfo.schoolProvince+applyInfo.schoolCity+applyInfo.schoolArea+applyInfo.schoolAddress }}</div>
          </div>
          <div class="item">
            <div class="col-4 br bold">中学联系人</div>
            <div class="col-4 br">{{ applyInfo.schoolContactName }}</div>
            <div class="col-4 br bold">中学联系电话</div>
            <div class="col-4">{{ applyInfo.schoolContactPhone }}</div>
          </div>
          <div class="item-name"><img src="../../assets/xkcj-img.png" class="bg-img" />.</div>
          <div class="info">
            <div class="text-info">
              <div class="item bb">
                <div class="col-8 br bold">科目</div>
                <div class="col-8 br bold">政治</div>
                <div class="col-8 br bold">历史</div>
                <div class="col-8 br bold">地理</div>
                <div class="col-8 br bold">物理</div>
                <div class="col-8 br bold">化学</div>
                <div class="col-8 br bold">生物</div>
                <div class="col-8 br bold">技术</div>
              </div>
              <div class="item">
                <div class="col-8 br bold">成绩</div>
                <div class="col-8 br">{{xuankao[0]['value']}}</div>
                <div class="col-8 br">{{xuankao[1]['value']}}</div>
                <div class="col-8 br">{{xuankao[2]['value']}}</div>
                <div class="col-8 br">{{xuankao[3]['value']}}</div>
                <div class="col-8 br">{{xuankao[4]['value']}}</div>
                <div class="col-8 br">{{xuankao[5]['value']}}</div>
                <div class="col-8 br">{{xuankao[6]['value']}}</div>
              </div>
              <!-- <div class="item-name">学业水平测试成绩</div> -->
              <div class="item-name"><img src="../../assets/xycj-img2.png" class="bg-img" />.</div>
              <div class="item bb">
                <div class="col-11 br bold">科目</div>
                <div class="col-11 br bold">语文</div>
                <div class="col-11 br bold">数学</div>
                <div class="col-11 br bold">英语</div>
                <div class="col-11 br bold">政治</div>
                <div class="col-11 br bold">历史</div>
                <div class="col-11 br bold">地理</div>
                <div class="col-11 br bold">物理</div>
                <div class="col-11 br bold">化学</div>
                <div class="col-11 br bold">生物</div>
                <div class="col-11 br bold">技术</div>
              </div>
              <div class="item">
                <div class="col-11 br bold">成绩</div>
                <div class="col-11 br">{{xueye[0]['value']}}</div>
                <div class="col-11 br">{{xueye[1]['value']}}</div>
                <div class="col-11 br">{{xueye[2]['value']}}</div>
                <div class="col-11 br">{{xueye[3]['value']}}</div>
                <div class="col-11 br">{{xueye[4]['value']}}</div>
                <div class="col-11 br">{{xueye[5]['value']}}</div>
                <div class="col-11 br">{{xueye[6]['value']}}</div>
                <div class="col-11 br">{{xueye[7]['value']}}</div>
                <div class="col-11 br">{{xueye[8]['value']}}</div>
                <div class="col-11 br">{{xueye[9]['value']}}</div>
              </div>
              <!-- <div class="item-name">综合素质评价</div> -->
              <div class="item-name"><img src="../../assets/zhsz-img2.png" class="bg-img" />.</div>
              <div class="item bb">
                <div class="col-5 br bold">类别</div>
                <div class="col-5 br bold">品德表现</div>
                <div class="col-5 br bold">运动健康</div>
                <div class="col-5 br bold">艺术素养</div>
                <div class="col-5 br bold">创新实践</div>
              </div>
              <div class="item">
                <div class="col-5 br bold">终评等第</div>
                <div class="col-5 br">{{zonghe[0]['value']}}</div>
                <div class="col-5 br">{{zonghe[1]['value']}}</div>
                <div class="col-5 br">{{zonghe[2]['value']}}</div>
                <div class="col-5 br">{{zonghe[3]['value']}}</div>
              </div>
            </div>
            <div class="pic-info bold">
              请高中核对学业水平测试成绩及综合素质成绩，并加盖所在中学（或教务处）公章
              <div>盖章处</div>
            </div>
          </div>
          <div class="item-name"><img src="../../assets/kscn-img.png" class="bg-img" />.</div>
          <div class="commitment bb bold">
            本人坚决遵守《宁波诺丁汉大学{{year}}年“三位一体”综合评价招生章程》有关报名规定，保证所提供的个人信息是真实、准确、完整的，如因个人信息错误、失真、缺失造成不良后果，责任由本人承担。
          </div>
          <div class="sign">
            <div class="col-4 label br" style="padding:0"><img src="../../assets/ksqz-img.png" class="bg-img" />.</div>
            <div class="col-4 br"></div>
            <div class="col-4 label br" style="padding:0"><img src="../../assets/rq-img.png" class="bg-img" />.</div>
            <div class="col-4"></div>
          </div>
        </div>
        <!-- <div style="height: 50px"></div> -->
      </div>
    </div>
    <div class="btn-box">
      <div v-if="applyStatus == 3" class="apply-done-tip">
        你已申请撤回<br />等待管理员审核
      </div>
      <div class="save-btn" @click="applyBack" v-if="(applyStatus == 2 || applyStatus == 4) && applyTime != 0">
        修改信息
      </div>
      <div class="save-btn" @click="printApplyPdf">申请表打印</div>

      <div class="next-btn" @click="nextStep" v-if="applyStatus == 2 || applyStatus == 4">
        下一步
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import print from "print-js";
export default {
  data() {
    return {
      applyTime:this.$store.state.applyTime,
      applyStatus: 1,
      applyInfo: {
        realName: "",
        namePinyin: "",
        sex: "",
        birthDate: "",
        nation: "",
        politicsFace: "",
        candidateType: "",
        foreigLanguages: "",
        email: "",
        mobilePhone: "",
        province: "",
        city: "",
        area: "",
        address: "",
        PostCode: "",
        fatherName: "",
        fatherMobilePhone: "",
        fatherOccuption: "",
        fatherCompany: "",
        fatherPost: "",
        motherName: "",
        motherMobilePhone: "",
        motherOccuption: "",
        motherCompany: "",
        motherPost: "",
        highSchool: "",
        schoolProvince: "",
        schoolCity: "",
        schoolArea: "",
        schoolAddress: "",
        schoolPostCode: "",
        schoolContactName: "",
        schoolContactPhone: "",
        imgUrl: "",
        intentionMajor1: "",
        intentionMajor2: "",
        intentionMajor3: "",
        cardNo: "",
        applyNo: ""
      },
      xuankao:[
        {
          'id':4,
          'scoreType':1,
          'item':"政治",
          'value':"",
          'disable':'true'
        },
        {
          'id':6,
          'scoreType':1,
          'item':"历史",
          'value':"",
          'disable':'true'
        },
        {
          'id':5,
          'scoreType':1,
          'item':"地理",
          'value':"",
          'disable':'true'
        },
        {
          'id':1,
          'scoreType':1,
          'item':"物理",
          'value':"",
          'disable':'true'
        },
        {
          'id':2,
          'scoreType':1,
          'item':"化学",
          'value':"",
          'disable':'true'
        },
        {
          'id':3,
          'scoreType':1,
          'item':"生物",
          'value':"",
          'disable':'true'
        },
        {
          'id':7,
          'scoreType':1,
          'item':"技术",
          'value':"",
          'disable':'true'
        }
      ],
      xueye:[
        {
          'id':1,
          'scoreType':2,
          'item':"语文",
          'value':""
        },
        {
          'id':2,
          'scoreType':2,
          'item':"数学",
          'value':""
        },
        {
          'id':3,
          'scoreType':2,
          'item':"英语",
          'value':""
        },
        {
          'id':7,
          'scoreType':2,
          'item':"政治",
          'value':""
        },
        {
          'id':8,
          'scoreType':2,
          'item':"历史",
          'value':""
        },
        {
          'id':9,
          'scoreType':2,
          'item':"地理",
          'value':""
        },
        {
          'id':4,
          'scoreType':2,
          'item':"物理",
          'value':""
        },
        {
          'id':5,
          'scoreType':2,
          'item':"化学",
          'value':""
        },
        {
          'id':6,
          'scoreType':2,
          'item':"生物",
          'value':""
        },
        {
          'id':10,
          'scoreType':2,
          'item':"技术",
          'value':""
        }
      ],
      zonghe:[
        {
          'id':1,
          'scoreType':3,
          'item':"品德表现",
          'value':""
        },
        {
          'id':2,
          'scoreType':3,
          'item':"运动健康",
          'value':""
        },
        {
          'id':3,
          'scoreType':3,
          'item':"艺术素养",
          'value':""
        },
        {
          'id':4,
          'scoreType':3,
          'item':"创新实践",
          'value':""
        }
      ],
      titleZh:"",
      titleEn:"",
      backLimitsStatus:0,
      loading: false,
      year: 2022
    };
  },
  created() {
    this.init();
    this.getApplyStatus();
    this.getExistApply();
    this.getExistSchoolScores();
    this.getTitleZh();
    this.getTitleEn();
    this.getBackLimits();
  },
  methods: {
    init () {
      this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "年份",
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.year = res.data.configList[0].content
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getBackLimits(){
      this.$api
        .getProgressList({
          code:'code_4'
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.backLimitsStatus = 409;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    print() {
      this.overflow = 'overflow:visible';
      print({
        printable: "printConss",
        type: "html",
        targetStyles: ["*"],
      });
    },
    printApplyPdf() {
      this.$api
        .printApplyPdf()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            window.open(res.data.filePath, "_blank");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    applyBack() {
      if(this.backLimitsStatus == 409){
        this.$message.warning('现已暂停撤回申请，请等待开启！');
        return;
      }
      this.$api
        .applyBack()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.$router.push("/userinfo");
            // window.open(res.data.filePath, '_blank');
            // this.applyStatus = 3;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nextStep() {
      this.$router.push("/uploadmaterial");
    },
    getApplyStatus() {
      this.$api
        .getApplyStatus()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.applyStatus = res.data.applyStatus;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExistApply() {
      this.loading = true
      this.$api
        .getExistApply()
        .then((res) => {
          this.loading = false
          // console.log(res);
          if (res.data.code == 0) {
            Object.assign(this.applyInfo, res.data.exixtApply);
            this.applyStatus = res.data.exixtApply.status;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExistSchoolScores() {
      this.$api
        .getExistSchoolScores()
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            var map = new Map();
            res.data.schoolScores.courseScore.forEach((element) => {
              map.set(
                element.scoreType + element.examSubject,
                element.examResult
              );
            });
            this.xuankao.forEach((element) => {
              element.value = map.get("1" + element.item);
            });
            res.data.schoolScores.studyScore.forEach((element) => {
              map.set(
                element.scoreType + element.examSubject,
                element.examResult
              );
            });
            this.xueye.forEach((element) => {
              element.value = map.get("2" + element.item);
            });
            res.data.schoolScores.allScore.forEach((element) => {
              map.set(
                element.scoreType + element.examSubject,
                element.examResult
              );
            });
            this.zonghe.forEach((element) => {
              element.value = map.get("3" + element.item);
            });
            // console.log(this.xuankao);
            // console.log(this.xueye);
            // console.log(this.zonghe);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTitleZh(){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:'申请表中文标题'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.titleZh = res.data.configList[0].content;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTitleEn(){
      this.$api
          .contentConfigList({
            categoryName:'内容配置',
            name:'申请表英文标题'
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.titleEn = res.data.configList[0].content;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  },
};
</script>

<style lang='scss' scoped>
.body{
  height: 100%;
  overflow-y:scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.print-btn {
  color: #009bbd;
  border: 1px #009bbd solid;
  height: 40px;
  line-height: 38px;
  width: 150px;
  margin-left: auto;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
  line-height: 40px;
  &:hover {
    background-color: #009bbd;
    color: #015597;
    border: 1px #009bbd solid;
    color: white;
  }
}
.content {
  margin-top: 30px;
  border: 1px #ccc solid;
  padding: 30px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  font-size: 16px;
  .apply-form-box {
    width: 800px;
    .title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 20px;
      margin-top: 50px;
      transform: scale(1.625);
      width: 60%;
      margin-left: 20%;
      span {
        font-size: 16px;
        transform: scale(0.66);
        display: block;
      }
    }
    .apply-num {
      text-align: right;
      margin-top: 20px;
    }
    .apply-form {
      border: 1px #333 solid;
      .item-name {
        background-color: #007ca7;
        color: white;
        font-size: 18px;
        font-weight: bold;
        // padding: 0 10px;
        height: 35px;
        line-height: 35px;
        position: relative;
        img{
          position: absolute;
          top: 0;
        }
      }
      .item {
        display: flex;
        // min-height: 35px;
      }
      .info {
        display: flex;
        .text-info {
          width: calc(100% - 150px);
          .col-addr {
            width: 75%;
            padding-left: 10px;
            display: flex;
            align-items: center;
          }
        }
        .pic-info {
          width: 150px;
          position: relative;
          padding: 10px;
          font-size: 15px;
          display: flex;
          justify-content: center;
          div {
            color: #999;
            position: absolute;
            bottom: 100px;
            width: 100%;
            text-align: center;
          }
          img {
            width: 130px;
            height: 180px;
          }
        }
      }
      .commitment {
        padding: 20px;
        text-indent: 2em;
      }
      .sign {
        height: 50px;
        display: flex;
        div {
          align-items: center;
          display: flex;
          padding-left: 10px;
        }
        .label {
          background-color: #007ca7;
          color: white;
        }
      }
    }
    .br {
      border-right: 1px #333 solid;
    }
    .bold {
      font-weight: bold;
    }
    .bb {
      border-bottom: 1px #333 solid;
    }
    .col-6 {
      width: calc(100% / 6);
      padding-left: 10px;
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .col-4 {
      width: calc(100% / 4);
      padding-left: 10px;
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .col-8 {
      width: calc(100% / 8);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .col-11 {
      width: calc(100% / 11);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .col-5 {
      width: calc(100% / 5);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  .save-btn {
    color: #009bbd;
    border: 1px #009bbd solid;
    height: 40px;
    line-height: 38px;
    width: 150px;
    margin-right: 50px;
    cursor: pointer;
    &:hover {
      background-color: #009bbd;
      color: #015597;
      border: 1px #009bbd solid;
      color: white;
    }
  }
  .next-btn {
    color: white;
    border: 2px #009bbd solid;
    background-color: #009bbd;
    height: 40px;
    line-height: 36px;
    width: 150px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #015597;
      border: 2px #015597 solid;
      font-weight: bold;
    }
  }
}
.apply-done-tip {
  font-size: 14px;
  color: #999;
  margin-right: 20px;
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>