<template>
  <div>
    <div class="header">
      <img src="../../assets/whitetext.png" style="height: 100%" />
      <div>
        宁波诺丁汉大学三位一体综合评价招生报名系统<br />
        Tri-Unity Scheme Test Applicant Portal
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="title">
          创建新账号 Create a New Account<i
            class="iconfont icon-guanbi1"
            @click="login"
          ></i>
        </div>
        <div class="left-box">
          <div class="reg-tips">
            <li>密码设置规则：至少含8个字符且必须含英文大小写字符和数字</li>
            <li>注册的手机号码会用于接收相关短信通知提醒，请使用常用手机号注册</li>
          </div>
          <div class="input-box">
            <input
              type="text"
              placeholder="请输入考生姓名"
              v-model="realName"
            />
          </div>
          <div class="input-box">
            <input
              type="text"
              placeholder="请输入身份证号码"
              v-model="cardNo"
              @blur="checkCardNo(1)"
              @input="checkCardNo(3)"
            />
          </div>
          <div class="input-box">
            <input
              type="text"
              placeholder="请再次输入身份证号码"
              v-model="cardNo2"
              @blur="checkCardNo(2)"
              @input="checkCardNo(3)"
            />
            <span class="cardTips" v-if="showCardTips">两次身份证号输入不一致</span>
          </div>
          <div class="input-box">
            <input
              type="password"
              placeholder="请设置密码"
              v-model="password"
            />
          </div>
          <div class="input-box">
            <input
              type="password"
              placeholder="请确认密码"
              v-model="password2"
            />
          </div>
          <div class="input-box">
            <input
              type="number"
              placeholder="手机号码（用于账号验证）"
              v-model="mobilePhone"
            />
          </div>
          <div class="input-box">
            <input type="text" placeholder="请输入验证码" v-model="code" />
            <div
              class="sms-code"
              @click="sendVerificationCode"
              :class="[sended ? 'ban' : '']"
            >
              {{ sended ? seconds + "s 后重新发送" : "点击获取验证码" }}
            </div>
          </div>
        </div>
        <div class="right-box">
          <span>宁波诺丁汉大学一贯重视对于个人信息和隐私的保护。为更好保障您的个人权益，当您注册、使用或访问三位一体综合评价招生报名系统时，请您通过点击以下链接审慎阅读下述文件的完整版本，您勾选以下方框并点击“同意”的行为即表示您已阅读完毕并同意下述文件的全部内容。我们将严格按照经您同意的条款处理和保护您的个人信息</span>
          <a-checkbox class="check" :checked="check1" @change="check1Fun">我和我的监护人（如您未满18周岁）已仔细阅读并理解和同意<a href="https://www.nottingham.edu.cn/cn/usage/privacy.aspx" target="_blank">《宁波诺丁汉大学隐私政策》</a>和<a @click.stop="show('学生个人信息收集处理知情同意书')">《宁波诺丁汉大学三位一体综合评价招生报名系统个人信息收集处理知情同意书》</a>（“《同意书》”）的所有条款</a-checkbox>
<!--          <a-checkbox class="check ml0" :checked="check2" @change="check2Fun">我和我的监护人（如您未满18周岁）同意学校按照《同意书》所述内容对我的敏感个人信息进行相应处理并将我的个人信息跨境传输，并确认在向宁波诺丁汉大学提供他人个人信息前已符合《同意书》的全部要求</a-checkbox>-->
          <div class="reg-btn" @click="register">
            确认注册 Register <i class="iconfont icon-you"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text-div">
        <div class="school-name">宁波诺丁汉大学</div>
        <div class="addtional-text">
          <div>招生热线：0574-88180182</div>
          <div>学校地址：浙江省宁波市泰康东路199号</div>
        </div>
        <div class="addtional-text">
          <div>学校官网：<a href="http://www.nottingham.edu.cn"
              >www.nottingham.edu.cn</a>
        </div>
          <div>
            招生邮箱：<a href="mailto:Gaokao@nottingham.edu.cn"
              >Gaokao@nottingham.edu.cn</a
            >
          </div>
        </div>
      </div>
      <div class="img-div">
        <div class="img">
          <img src="../../assets/qr.png" style="width: 66%" />
        </div>
        <div class="img-text">宁波诺丁汉大学招生办<br />微信公众号</div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
    <div class="pop-layer" v-if="articleLayer">
      <div class="box" style='overflow-x:scroll'>
        <div class="title">
          <div>{{ articleTitle }}</div>
          <div>
            <i
                data-v-8b6adaf6=""
                class="iconfont icon-guanbi1"
                @click="closeDetail"
            ></i>
          </div>
        </div>
        <div class="content">
          <div class="content-box">
            <div v-html="articleContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
      realName:'',
      cardNo: "",
      cardNo2: "",
      password: "",
      password2: "",
      mobilePhone: "",
      code: "",
      sended: false,
      timer: "",
      seconds: 60,
      loading: false,
      check1: false,
      check2: true,
      articleLayer: false,
      articleTitle: '',
      articleContent: '',
      showCardTips: false
    };
  },
  watch: {
    seconds() {
      if (this.seconds <= 0) {
        clearInterval(this.timer);
        this.sended = false;
      }
    },
    mobilePhone(n,o){
      if(n.length>11){
        this.mobilePhone = o;
      }
    }
  },
  created(){
    if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
      this.$router.push("mindex")
      return
    }
    this.getSystemStatus();
  },
  methods: {
    getSystemStatus() {
      this.$api
        .getProgressList({
          code: "code_10",
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 409) {
            this.$router.push("systemClose");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    login() {
      this.$router.push("login");
    },
    encrypt (word, keyStr) {
      keyStr = 'wwwkernespringxy'
      var key = CryptoJS.enc.Utf8.parse(keyStr)
      var srcs = CryptoJS.enc.Utf8.parse(word)
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
      return encrypted.toString()
    },
    sendVerificationCode() {
      if (this.cardNo === '' || this.cardNo2 === '') {
        this.$message.warning("请输入身份证号")
        return;
      } else if (this.cardNo2 !== this.cardNo) {
        this.showCardTips = true
        this.$message.warning("两次身份证号输入不一致")
        return;
      }

      if (this.sended) {
        return;
      }
      if (this.mobilePhone) {
        if (!/^1[3456789]\d{9}$/.test(this.mobilePhone)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        this.sended = true;
        this.seconds = 60;
        this.timer = setInterval(() => {
          this.seconds--;
        }, 1000);
        this.$api
          .sendVerificationCode({
            mobile: this.mobilePhone,
            type:1
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success("短信验证码已发送，请注意查收");
            } else {
              clearInterval(this.timer);
              this.sended = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("手机号不能为空");
      }
    },
    register() {
      if (!this.check1 || !this.check2) {
        this.$message.warning("请阅读相关政策并点击勾选框");
        return;
      }
      if (
        this.realName &&
        this.mobilePhone &&
        this.cardNo &&
        this.cardNo2 &&
        this.code &&
        this.password &&
        this.password2
      ) {
        // 身份证验证
        if (
          !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            this.cardNo
          )
        ) {
          this.$message.warning("请输入正确身份证号");
          return;
        } else if (this.cardNo.indexOf("x") > -1) {
          this.$message.warning("请输入大写的X字母");
          return;
        }
        if (this.cardNo !== this.cardNo2) {
          this.$message.warning("两次身份证号输入不一致");
          return;
        }
        // 手机验证
        if (!/^1[3456789]\d{9}$/.test(this.mobilePhone)) {
          this.$message.warning("请输入正确手机号");
          return;
        }
        // 密码验证
        if (this.password.length < 8) {
          this.$message.warning("密码至少含8个字符");
          return;
        }
        // 至少含英文和数字
        if (
          // !/^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{8,}$/.test(this.password)
          !/[a-zA-Z]/.test(this.password) || !/[0-9]/.test(this.password)
        ) {
          this.$message.warning("必须含英文大小写字符和数字");
          return;
        }
        if (this.password != this.password2) {
          this.$message.warning("两次密码输入不一致！");
          return;
        }
        this.loading = true;
        this.$api
          .register({
            realName: this.realName,
            cardNo: this.encrypt(this.cardNo),
            code: this.code,
            mobile: this.mobilePhone,
            password: this.encrypt(this.password),
          })
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              this.$message.success("注册成功！先登录");
              this.$router.push("/login");
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("请填写完整！");
      }
    },
    check1Fun() {
      this.check1 = !this.check1
    },
    check2Fun() {
      this.check2 = !this.check2
    },
    show(name) {
      this.$api
          .articleList({
            categoryName: name,
            pageNum: 0,
            pageSize: 10,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.articleContent = res.data.page.list[0].content;
              this.articleTitle = res.data.page.list[0].title;
              this.articleLayer = true;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    closeDetail() {
      this.articleLayer = false;
    },
    checkCardNo(n) {
      if (n === 2 || (n === 1 && this.cardNo2 !== '')) {
        if (this.cardNo !== this.cardNo2) {
          this.showCardTips = true
        }
      } else if (n === 3 && this.cardNo === this.cardNo2) {
        this.showCardTips = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 104px;
  background-color: #18194f;
  padding-bottom: 19px;
  min-width: 1366px;
  div {
    font-size: 24px;
    display: inline-block;
    color: white;
    position: absolute;
    line-height: 30px;
    margin: 22px;
    min-width: 510px;
    margin-left: 70px;
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 201px);
  min-height: 550px;
  .content-box {
    width: 1100px;
    border: 1px #009bbd solid;
    padding-bottom: 50px;
    .title {
      color: white;
      background-color: #009bbd;
      height: 50px;
      font-size: 20px;
      line-height: 50px;
      padding: 0 30px;
      .icon-guanbi1 {
        float: right;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          color: #005597;
          font-weight: bold;
        }
      }
    }
    .left-box {
      float: left;
      width: 550px;
      .reg-tips {
        margin-left: 73px;
        margin-top: 30px;
        margin-right: 30px;
        li {
          font-size: 16px;
          line-height: 30px;
          text-indent: -23px;
          margin-bottom: 10px;
        }
      }
      .input-box {
        border: 0;
        margin: 30px 40px -10px 40px;
        font-size: 18px;
        height: 50px;
        display: flex;
        align-items: center;
        input {
          border: 0;
          height: 100%;
          width: 100%;
          margin-left: 10px;
          outline: none;
          border-bottom: 1px #009bbd solid;
          &:hover {
            border-bottom: 2px #009bbd solid;
          }
          &::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #bbb;
          }

          &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #bbb;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #bbb;
          }
        }
        .cardTips {
          position: absolute;
          font-size: 16px;
          color: #de1a5c;
          margin-top: -50px;
          margin-left: 10px;
        }
        .sms-code {
          font-size: 16px;
          color: white;
          background-color: #005597;
          width: 220px;
          text-align: center;
          height: 45px;
          line-height: 41px;
          border: 2px #005597 solid;
          font-size: 16px;
          transition: all 0.5s;
          margin-left: 20px;
          cursor: pointer;
          &:hover {
            background-color: transparent;
            color: #005597;
            border: 2px #005597 solid;
            font-weight: bold;
          }
          &.ban {
            color: #999;
            background-color: #ddd;
            border: 0;
            border: 1px #999 solid;
            cursor: not-allowed;
          }
        }
      }
    }
    .right-box {
      float: left;
      padding-left: 50px;
      float: left;
      margin-top: 30px;
      width: 500px;
      .tip-title {
        font-size: 18px;
        color: #009bbd;
      }
      span {
        font-size: 16px;
        line-height: 35px;
      }
      .reg-btn {
        text-align: center;
        height: 50px;
        background-color: #de1b5c;
        margin-top: 78px;
        line-height: 46px;
        color: white;
        border: 2px #de1b5c solid;
        font-size: 16px;
        transition: all 0.5s;
        cursor: pointer;
        &:hover {
          background-color: transparent;
          color: #de1b5c;
          border: 2px#de1b5c solid;
          font-weight: bold;
        }
      }
    }
  }
}
.footer {
  height: 97px;
  background-color: #18194f;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 18px;
  align-items: center;
  padding: 0 30px;
  min-width: 1366px;
  .text-div {
    display: flex;
    align-items: center;
    height: 100%;
    .school-name {
      font-size: 24px;
      font-weight: bold;
      border-right: 1px white solid;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 30px;
    }
    .addtional-text {
      padding: 0 30px;
      font-size: 16px;
      line-height: 28px;
      a {
        color: white;
      }
    }
  }
  .img-div {
    height: 100%;
    display: flex;
    .img {
      display: flex;
      align-items: center;
    }
    .img-text {
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 16px;
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.check {
  font-size: 16px;
  color:#333;
  line-height: 35px;
  margin-top: 40px;
  a{
    color: #333;
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }
}
.ml0 {
  margin-left: 0 !important;
}
.pop-layer {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  padding: 15px;
  .box {
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    .title {
      display: flex;
      height: 60px;
      border-bottom: 1px #ddd solid;
      line-height: 60px;
      font-size: 18px;
      padding: 0 20px;
      justify-content: space-between;
      min-width: 600px;
      i {
        font-size: 18px;
        cursor: pointer;
        &:hover {
          color: #f44336;
          font-weight: bold;
        }
      }
    }
    .content {
      padding: 50px;
      overflow-y: scroll;
      height: calc(100% - 60px);
      display: block;
      min-width: 600px;
      .content-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .addtion {
        color: #858585;
        text-align: right;
        margin-bottom: 50px;
      }
      .content-box {
        display: block;
        width: auto;
        min-width: auto;
        border: 0;
        /deep/ img{
          max-width: 100%;
          height: auto;
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }
      /*竖向滚动条*/
      &::-webkit-scrollbar-thumb:vertical {
        height: 5px;
        background-color: #ddd;
        -webkit-border-radius: 0px;
      }
    }
  }
}
@media screen and (max-height: 900px) {
  .content {
    transform: scale(0.88);
  }
}
@media screen and (max-height: 815px) {
  .content {
    transform: scale(0.74);
  }
}
// @media screen and (max-width: 1000px) {
//   .content {
//     height: unset;
//     margin-top: 20px;
//     margin-bottom: 20px;
//     .content-box {
//       width: 450px;
//       .left-box {
//         width: 450px;
//       }
//     }
//   }
//   .footer {
//     position: relative;
//   }
// }
// @media screen and (max-width: 740px) {
//   .header div {
//     font-size: 18px;
//   }
// }
// @media screen and (max-width: 490px) {
//   .header {
//     height: unset;
//     padding-bottom: 10px;
//     img {
//       height: 40px !important;
//     }
//     div {
//       font-size: 12px;
//       line-height: 20px;
//       margin: 5px 10px;
//     }
//   }
//   .content {
//     padding: 20px 0;
//     .content-box {
//       width: 300px;
//       .title {
//         height: 40px;
//         font-size: 14px;
//         line-height: 40px;
//         padding: 0 10px;
//       }
//       .left-box {
//         width: 300px;
//         .input-box {
//           height: 40px;
//           font-size: 14px;
//           margin: 20px 30px;
//           .sms-code {
//             font-size: 12px;
//             height: 40px;
//             line-height: 40px;
//           }
//         }
//       }
//       .right-box {
//         width: 300px;
//         padding-left: 30px;
//         .tip-title {
//           font-size: 16px;
//         }
//         li {
//           font-size: 14px;
//         }
//         .reg-btn {
//           width: 200px;
//           height: 40px;
//           font-size: 14px;
//           line-height: 40px;
//         }
//       }
//     }
//   }
//   .footer {
//     .text1 {
//       font-size: 16px;
//     }
//     .text2 {
//       font-size: 14px;
//     }
//   }
// }
</style>
