<template>
  <div class="content-div">
    <div class="content" v-if="status == 11">
      <div v-html="statusOptions">
      </div>
      <div class="box">
        <div class="header">笔试成绩</div>
        <div class="score">{{handleScore}}</div>
      </div>
    </div>
    <div class="content-succ" v-if="status == 10">
      <div v-html="statusOptions">
      </div>
      <div class="box">
        <div class="header">
          <div>笔试成绩</div>
          <div>面试等候教室</div>
          <div style="border-right: 0">应到达等候时间</div>
        </div>
        <div class="score">
          <div>{{handleScore}}</div>
          <div>{{faceRoom}}</div>
          <div style="border-right: 0">{{watiTime}}</div>
        </div>
      </div>
    </div>
    <div class="loading-layer" v-if="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status:0,
      statusOptions:"",
      faceRoom:"",
      watiTime:"",
      handleScore:"",
      loading:false
    };
  },
  created(){
    this.getHandleScore();
  },
  methods: {
    getHandleScore(){
      this.loading = true;
      this.$api
          .getHandleScore()
          .then((res) => {
            this.loading = false;
            // console.log(res);
            if (res.data.code == 0) {
              if(res.data.handleStatus == 2){
                this.status = 10;
                this.handleScore = res.data.handleScore+'分';
                this.statusOptions = res.data.statusOptions;
                this.faceRoom = res.data.faceRoom;
                this.watiTime = res.data.watiTime;
                this.isHideScore();
              }else if(res.data.handleStatus == 1){
                this.status = 11;
                this.statusOptions = res.data.statusOptions;
                this.handleScore = res.data.handleScore+'分';
              }
            }else{
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    isHideScore() {
      this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "是否关闭显示笔试成绩",
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
                if (res.data.configList[0].content === "T") {
                  this.getHideScoreTips();
                }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getHideScoreTips() {
      this.$api
          .contentConfigList({
            categoryName: "内容配置",
            name: "笔试成绩显示说明",
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
                this.handleScore = res.data.configList[0].content;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }
};
</script>

<style lang='scss' scoped>
.content-div{
  height: 100%;
  overflow-y:scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  /*竖向滚动条*/
  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #ddd;
    -webkit-border-radius: 0px;
  }
}
.content {
  font-size: 24px;
  text-align: center;
  line-height: 2;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box {
    width: 600px;
    margin-top: 20px;
    border: 1px #009bbd solid;
    .header {
      background-color: #009bbd;
      color: white;
    }
    .score {
      height: 160px;
      line-height: 160px;
    }
  }
}
.content-succ {
  font-size: 24px;
  text-align: center;
  line-height: 2;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box {
    width: 1000px;
    margin-top: 20px;
    border: 1px #009bbd solid;
    .header {
      background-color: #009bbd;
      color: white;
      display: flex;
      text-align: center;
      div {
        width: 33.3%;
        border-right: 1px #fff solid;
      }
    }
    .score {
      height: 160px;
      line-height: 160px;
      display: flex;
      text-align: center;
      div {
        width: 33.3%;
        border-right: 1px #009bbd solid;
      }
    }
  }
}
.loading-layer {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>